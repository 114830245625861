import React, { useState, useEffect } from 'react';
import CustomeSlider from './CustomSlider';

export default function FSHMeasure(props) {
  const [firstValue, setFirstValue] = useState(null);
  const [gradientColorScheme, setGradientColorScheme] = useState(`linear-gradient(to right, #FFC300 50%, #4CBB17 50.1% 70%, #FF5733 70.1% 100%)`)

  useEffect(() => {
    setFirstValue(props.result)
    const maxValue = props.parameter?.highvalue > props.result ? props.parameter?.highvalue : props.result
    const highValue = parseFloat(props.parameter?.optimumvalue.split('-')[1])
    const lowValue = props.parameter?.lowvalue
    setGradientColorScheme(`linear-gradient(to right, #FFC300 ${(lowValue / maxValue) * 100}%, #4CBB17 ${((lowValue / maxValue) * 100) + 0.1}% ${(highValue / maxValue) * 100}%, #FF5733 ${((highValue / maxValue) * 100) + 0.1}% 100%)`)
  }, [props]);

  return (
    <div className="progreessBarFlexBox">
      <div className="titleBox">
        <h5 className="subtitle">measured in {props.parameter?.measure}</h5>
      </div>
      <CustomeSlider
        value={firstValue}
        valueLabelDisplay={!firstValue ? 'off' : 'on'}
        thumbbackgroundcolor={!firstValue ? "transparent" : "#fff"}
        thumbborder={!firstValue ? '0px solid currentColor' : '2px solid currentColor'}
        marks={props.marks}
        min={0}
        max={props.parameter?.highvalue > props.result ? props.parameter?.highvalue : props.result}
        slidercolor='#7fe69e'
        gradientColorScheme={gradientColorScheme}
        valueColor={props.valueColor}
      />
    </div>
  );
}
