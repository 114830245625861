import { GET_SHIPPING_ADDRESS_FAILED, GET_SHIPPING_ADDRESS_START, GET_SHIPPING_ADDRESS_SUCCESS } from "../actions/types";

const initialData = {
   data: null,
   error: null,
   loading: false
}

export default function(state = initialData, action) {
    switch(action.type) {
        case GET_SHIPPING_ADDRESS_START: 
            return {
                ...state,
                loading: true
            }
        case GET_SHIPPING_ADDRESS_SUCCESS: 
            return {
                ...state,
                error: null,
                data: action.payload,
                loading: false
            }
        case GET_SHIPPING_ADDRESS_FAILED: 
            return {
                ...state,
                data: null,
                error: action.payload,
                loading: false
            }
        default: 
            return {
                ...state
            }
    }
}