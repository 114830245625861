import React, { Component } from 'react';
import { connect } from 'react-redux';
import "../../assets/css/order-history.scss";
import filePdfSvg from "../../assets/img/file-pdf.svg"
import refreshSvg from "../../assets/img/refresh.svg"
import { NavLink } from 'react-router-dom';

// import { orderLists } from '../../Redux/actions/order/order'
import { getOrderHistory } from '../../Redux/actions/orderHistory/orderHistory';
import BackdropLoader from '../../Services/loader';
import { getAllOrderHistoryQuery } from './OrderHistoryQuery';
import { getAuthToken, getUserInfo } from '../../Services/authService';
import { saveShoppingCartDataQuery } from '../Shopping-Cart/ShoppingCartQuery';
import { addUserShoppingCartData } from '../../Redux/actions/Shopping-Cart/actions';
import { ddmmyyDateFormat, getColonSlotFormatedTime } from '../../Services/dateTimeFormatter';
import InvoiceTemplate from './InvoiceTemplate';
import messagePopup from '../../Services/messagePopupService';


class OrderHistory extends Component {
    constructor(props) {
        super(props);
        this.state = {
            order: [],
            showInvoice:false,
            invoiceData:'',
        }
    }

    componentDidMount() {
        // this.props.orderLists();
        const variables = {
            where: {
                userid: getUserInfo()?.id,
                active: 1
            }
        }
        this.props.getOrderHistory({
            query: getAllOrderHistoryQuery,
            variables
        })
    }

    handleRepeatOrder = (data) => {
        // console.log('data : ',data);
        // console.log('this.props.cartDetails.cartCount : ',this.props.cartDetails.data);
        if (getAuthToken()) {
        if(!this.props.cartDetails.data.map(val => val.id).includes(data.testid)){
            const variables = {
                testid: data.testid,
                quantity: data.quantity,
                userid: getUserInfo().id
            }
            this.props.addUserShoppingCartData({
                query: saveShoppingCartDataQuery,
                variables,
                cuurentcartCount: this.props.cartDetails.cartCount
            })

            }
        else{
            messagePopup('','Item already in cart!','warning')
        }
        }
    }
    handleopen =(val)=>{
        this.setState({
            showInvoice:true,
            invoiceData:val
        })
    }
    
    
    render() {
        return this.state.showInvoice? <InvoiceTemplate data={this.state.invoiceData}/>: (
            <div className="pageWrap" style={{ marginTop: "85px" }}>
                <BackdropLoader open={this.props.orderHistory.loading || this.props.cartDetails.loading} />
                <main className="orderhistory">
                    <div className="breadcumb">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/OrderHistory">Order History</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <section className="orderwrap">
                        <div className="container">
                            <div className="form-group dropdown">
                              </div>
                                <h1>Order History</h1>
                            <div className="orderlist">
                                <div className="orderthread">
                                    <div className="thread text-lg-center">Order No./ Appointment No.</div>
                                    <div className="thread text-lg-center">Date</div>
                                    <div className="thread text-lg-center ">Item</div>
                                    {/* <div className="thread text-lg-center">Quantity</div> */}
                                    <div className="thread text-lg-center">Amount Paid</div>
                                    <div className="thread text-lg-center width20">Test kit receive date and time/ Appointment SlotTime</div>
                                    <div className="thread text-lg-center">Delivery Status / Booking Status</div>
                                    <div className="thread text-lg-center">Nurse Name /Doctor Name</div>
                                    <div className="thread text-lg-center">Invoice</div>
                                    <div className="thread text-lg-center">Action</div>
                                </div>
                                

                                {
                                (this.props.orderHistory.data && this.props.orderHistory.data.length ? this.props.orderHistory.data.map((val, index) => (
                                    <div className="fulllodrist" key={index}>
                                        <div className="mob">
                                            <div className="leftcolum">{val.orderid}</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">{val.orderid}</div>
                                            </div>
                                        </div>
                                        <div className="mob">
                                            <div className="leftcolum">{val.orderdate}</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">{val.orderdate}</div>
                                            </div>
                                        </div>
                                        <div className="mob ">
                                            <div className="leftcolum">{val.testname}</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">
                                                    {val.itemName?val.itemName:
                                                    <h2><NavLink to={`/test_details/${val.testid}`}>{val.testname}</NavLink></h2>
                                                    }
                                                    {/* <p>{val.description}</p> */}
                                                </div>
                                            </div>
                                        </div>
                                        {/* <div className="mob">
                                            <div className="leftcolum">{val.quantity}</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">{val.quantity}</div>
                                            </div>
                                        </div> */}
                                        <div className="mob">
                                            <div className="leftcolum">{val.price}</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">AED{` ${val.price ? val.quantity ? +val.quantity * +val.price : val.price /100 : 0}`}</div>
                                            </div>
                                        </div>
                                        <div className="mob width20">
                                            <div className="leftcolum">Test kit receive date and time</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">{val.slotDate && val.slotStartDate && val.slotEndDate ? ddmmyyDateFormat(val.slotDate) + " " + getColonSlotFormatedTime(val.slotStartDate) + "-" + getColonSlotFormatedTime(val.slotEndDate):' '}</div>
                                            </div>
                                        </div>
                                        <div className="mob">
                                            <div className="leftcolum">Delivery Status</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">
                                                    {val.deliveryStatus}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mob">
                                            <div className="leftcolum">Nurse Name</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">
                                                    {val.nurseName}
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mob">
                                            <div className="leftcolum">Invoice</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">
                                                    <NavLink to={ val.itemName?`/OrderHistory/${val.orderid}/appointmentInvoice`:`/OrderHistory/${val.orderid}/orderInvoice`}  onClick={() => { this.handleopen(val) }}><img className="filepdf" onclick={this.handleopen} src={filePdfSvg} alt="" /></NavLink>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="mob">
                                            <div className="leftcolum">Action</div>
                                            <div className="rightcolum text-lg-center">
                                                <div className="itemdetails">
                                                    {val.itemName?'':
                                                    <NavLink to="#" onClick={() => { this.handleRepeatOrder(val) }}><img className="repeatimg" src={refreshSvg} alt="repeat" />Repeat Order</NavLink>
                                                    }
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                )) : (
                                    <div style={{ textAlign: 'center', paddingTop: '15px' }}>
                                        <span >No Order Found</span>
                                    </div>
                                ))
                                
                                }
                            </div>
                        </div>
                    </section>
                </main>
            </div>
        );
    }
}

const mapStateToProps = state => {
    // console.log('State : ',state);
    return {
        orderHistory: state.orderHistory,
        cartDetails: state.cartDetails,
       
    }
}
const mapDispatchToProps = dispatch => {
    return {
        //    orderLists: (data) => dispatch(orderLists()),
        getOrderHistory: (data) => dispatch(getOrderHistory(data)),
        addUserShoppingCartData: (data) => dispatch(addUserShoppingCartData(data)),
        
    }
}

export default connect(mapStateToProps, mapDispatchToProps)(OrderHistory);