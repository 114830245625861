import { unixTimestampToDate } from '../../../Services/dateTimeFormatter';
import { fetchGraphMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { GET_TESTS_RESULT_FAILED, GET_TESTS_RESULT_START, GET_TESTS_RESULT_SUCCESS } from '../types';

export const getTestsResultStart = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_TESTS_RESULT_START
        })
        const response = await fetchGraphMethod(data.query,data.variables,true); 
        if(response && response.status === 200){
            const allReports = response.data.data.allReports.Reports ? response.data.data.allReports.Reports : [];
            let finalDataArr = [];
            for (const item of allReports) {
               let temp = {};
               temp['reportid'] = item.id;
               temp['resultsdate'] = item.createdon ? unixTimestampToDate(item.createdon) : '';
               temp['sampleid'] = item.sampleid;
               temp['samplekitid'] = item.samplekitid;
               temp['userid'] = item.userid;
               const sampledetails = item.reportsampleidmaprel.Sampledetails[0]
               temp['testdate'] = sampledetails ? sampledetails.createdon ? unixTimestampToDate(sampledetails.createdon) : '' : '';
               temp['testid'] = sampledetails ? sampledetails.testid : '';
               temp['testname'] = '';
               if(sampledetails) {
                  const testDetails =  sampledetails.sampledetailstestidmaprel.Tests[0];
                  temp['testname'] = testDetails.testname;
               }
               finalDataArr = [{...temp},...finalDataArr];
            }
            dispatch({
                type: GET_TESTS_RESULT_SUCCESS,
                payload: finalDataArr
            })
        } else {
            messagePopup('','Get Tests Result Falied','error');
            dispatch({
                type: GET_TESTS_RESULT_FAILED,
                payload: 'Get Tests Result Falied'
            }) 
        }
    } catch (error) {
        messagePopup('','Get Tests Result Falied','error');
        dispatch({
            type: GET_TESTS_RESULT_FAILED,
            payload: 'Get Tests Result Falied'
        })
    }
};
