import { CHANGE_PASSWORD_FAILED, CHANGE_PASSWORD_START, CHANGE_PASSWORD_SUCCESS } from '../../actions/types';

const initialData = {
    loading: false,
    data: null,
    error: null
}

export default function(state = initialData, action) {
    switch(action.type) {
        case CHANGE_PASSWORD_START:
            return {
                ...state,
                loading: true,
            };
        case CHANGE_PASSWORD_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            };
        case CHANGE_PASSWORD_FAILED:
            return {
                ...state,
                error: action.payload,
                data: null,
                loading: false
            };
        default: 
            return {...state};
    }
}