import { Button, FormControl, FormControlLabel, InputLabel, MenuItem, Radio, RadioGroup, Select, TextField } from '@material-ui/core';
import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import "../../assets/css/myaccount.scss";
import '../../assets/css/myaccount.css';
import userprofilepicSvg from "../../assets/img/userprofilepic.svg";
import '../../../node_modules/bootstrap/dist/css/bootstrap.min.css'
import { connect } from 'react-redux';
import { getUserData } from '../../Redux/actions/user/user';
import { getBillingAddressQuery, getPersonalInfoQuery, getShippingAddressQuery, saveBillingAddressQuery, savePersonalInfoQuery, saveShippingAddressQuery } from './MyAccountQuery';
import { getAuthToken, getUserInfo } from '../../Services/authService';
import { getBillingAddress, getShippingAddress, updateUserAccount, uploadProfileImageFailed, uploadProfileImageStart } from '../../Redux/actions/myAccount/myAccount';
import BackdropLoader from '../../Services/loader';
import { API_URL, emailRegex, mobileNumRegex, nameRegex } from '../../Config';
import messagePopup from '../../Services/messagePopupService';
import PhoneInput, { isValidPhoneNumber } from 'react-phone-number-input'

class MyAccount extends Component {
  constructor(props) {
    super(props);
    this.state = {
      firstname: '',
      lastname: '',
      email: '',
      phonenumber: '',
      streetaddress: '',
      buildingdetails: '',
      city: '',
      area: '',
      landmark: '',
      shippingAddressId: 0,
      sameasshipping: '0',
      billingstreetaddress: '',
      billingbuildingdetails: '',
      billingcity: '',
      billingarea: '',
      billinglandmark: '',
      billingAddressId: 0,
      fieldErrors: {},
      filePath: '',
      file: null,
      profileimagename: ''
    }
  }
  componentDidMount = () => {
    if (getAuthToken()) {
      const variables = {
        obj: {
          id: getUserInfo().id
        }
      }
      this.props.getUserData({
        query: getPersonalInfoQuery,
        variables
      });
      this.props.getShippingAddress({
        query: getShippingAddressQuery,
        variables: {
          obj: {
            userid: getUserInfo().id,
            acrive: 1
          }
        }
      })
      this.props.getBillingAddress({
        query: getBillingAddressQuery,
        variables: {
          obj: {
            userid: getUserInfo().id,
            acrive: 1
          }
        }
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    if ((prevProps !== this.props) && ((prevProps.user.data !== this.props.user.data) || (prevProps.shippingAddress.data !== this.props.shippingAddress.data) || (prevProps.billingAddress.data !== this.props.billingAddress.data))) {
      this.setState({
        filePath: this.props.user.data ? this.props.user.data.profileimagename ? `${API_URL}/api/Containers/images/download/${this.props.user.data.profileimagename}` : '' : '',
        profileimagename: this.props.user.data ? this.props.user.data.profileimagename : '',
        firstname: this.props.user.data ? this.props.user.data.firstname : '',
        lastname: this.props.user.data ? this.props.user.data.lastname : '',
        email: this.props.user.data ? this.props.user.data.email : '',
        phonenumber: this.props.user.data ? this.props.user.data.phonenumber : '',
        streetaddress: this.props.shippingAddress.data ? this.props.shippingAddress.data.streetaddress : '',
        buildingdetails: this.props.shippingAddress.data ? this.props.shippingAddress.data.buildingdetails : '',
        city: this.props.shippingAddress.data ? this.props.shippingAddress.data.city : '',
        area: this.props.shippingAddress.data ? this.props.shippingAddress.data.area : '',
        landmark: this.props.shippingAddress.data ? this.props.shippingAddress.data.landmark : '',
        shippingAddressId: this.props.shippingAddress.data ? this.props.shippingAddress.data.id : 0,
        billingstreetaddress: this.props.billingAddress.data ? this.props.billingAddress.data.streetaddress : '',
        billingbuildingdetails: this.props.billingAddress.data ? this.props.billingAddress.data.buildingdetails : '',
        billingcity: this.props.billingAddress.data ? this.props.billingAddress.data.city : '',
        billingarea: this.props.billingAddress.data ? this.props.billingAddress.data.area : '',
        billinglandmark: this.props.billingAddress.data ? this.props.billingAddress.data.landmark : '',
        billingAddressId: this.props.billingAddress.data ? this.props.billingAddress.data.id : 0,
        sameasshipping: this.props.billingAddress.data ? `${this.props.billingAddress.data.sameasshipping}` : '0',
      })
    }
  }

  handleInputChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    this.validateInputErrors(name, value)
  }

  handleSelectChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value })
  }

  handleRadioChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    if (value === '0') {
      this.setState({
        billingstreetaddress: '',
        billingbuildingdetails: '',
        billingcity: '',
        billingarea: '',
        billinglandmark: '',
      })
    }
  }

  handleSubmit = (evt) => {
    if (this.state.streetaddress.length>20) {
      messagePopup('','Street address exceeds word limit','warning')
    }
    else if(this.state.buildingdetails.length>20) {
      messagePopup('','Building details exceeds word limit','warning')
    }
    else if(this.state.city.length>20){
      messagePopup('','City field exceeds word limit','warning')
    }
    else if(this.state.area.length>20){
      messagePopup('','Area field exceeds word limit','warning')
    }
    else if(this.state.landmark.length>20){
      messagePopup('','Landmark field exceeds word limit','warning')
    }
    else if(this.state.billingarea.length>20){
      messagePopup('','Billing area field exceeds word limit','warning')
    }
    else if(this.state.billingstreetaddress.length>20){
      messagePopup('','Street address exceeds word limit','warning')
    }
    else if(this.state.billingbuildingdetails.length>20){
      messagePopup('','Building details exceeds word limit','warning')
    }
    else if(this.state.billinglandmark.length>20){
      messagePopup('','Landmark field exceeds word limit','warning')
    }
    else if(this.state.billingcity.length>20){
      messagePopup('','City field exceeds word limit','warning')
    }
    else{
    const tempMsg = this.formValidation();
    if (!tempMsg) {
      if (this.state.file) {
        this.props.uploadProfileImageStart();
        let formData = new FormData();
        formData.append('file', this.state.file);
        fetch(`${API_URL}/api/Containers/images/upload`, {
          method: 'POST',
          mode: 'cors',
          body: formData,
          headers: {
            access_token: getAuthToken(),
          },
        })
          .then((resp) => resp.json())
          .then((response) => {
            if (response) {
              const profileimagename = response.result.files.file[0] ? response.result.files.file[0].name : '';
              this.props.updateUserAccount({
                query: {
                  personalInfo: savePersonalInfoQuery,
                  shippingAddress: saveShippingAddressQuery,
                  billingAddress: saveBillingAddressQuery
                },
                data: {
                  personalInfo: {
                    id: getUserInfo().id,
                    email: this.state.email,
                    firstname: this.state.firstname,
                    lastname: this.state.lastname,
                    phonenumber: this.state.phonenumber,
                    profileimagename
                  },
                  shippingAddress: {
                    area: this.state.area,
                    buildingdetails: this.state.buildingdetails,
                    city: this.state.city,
                    landmark: this.state.landmark,
                    streetaddress: this.state.streetaddress,
                    userid: getUserInfo().id,
                    id: this.state.shippingAddressId
                  },
                  billingAddress: {
                    area: this.state.billingarea,
                    buildingdetails: this.state.billingbuildingdetails,
                    city: this.state.billingcity,
                    landmark: this.state.billinglandmark,
                    streetaddress: this.state.billingstreetaddress,
                    userid: getUserInfo().id,
                    id: this.state.billingAddressId,
                    sameasshipping: +this.state.sameasshipping
                  }
                }
              })
            }
          }).catch(err => {
            this.props.uploadProfileImageFailed()
          })
      } else {
        this.props.updateUserAccount({
          query: {
            personalInfo: savePersonalInfoQuery,
            shippingAddress: saveShippingAddressQuery,
            billingAddress: saveBillingAddressQuery
          },
          data: {
            personalInfo: {
              id: getUserInfo().id,
              email: this.state.email,
              firstname: this.state.firstname,
              lastname: this.state.lastname,
              phonenumber: this.state.phonenumber,
              profileimagename: this.state.profileimagename
            },
            shippingAddress: {
              area: this.state.area,
              buildingdetails: this.state.buildingdetails,
              city: this.state.city,
              landmark: this.state.landmark,
              streetaddress: this.state.streetaddress,
              userid: getUserInfo().id,
              id: this.state.shippingAddressId
            },
            billingAddress: {
              area: this.state.billingarea,
              buildingdetails: this.state.billingbuildingdetails,
              city: this.state.billingcity,
              landmark: this.state.billinglandmark,
              streetaddress: this.state.billingstreetaddress,
              userid: getUserInfo().id,
              id: this.state.billingAddressId,
              sameasshipping: +this.state.sameasshipping
            }
          }
        })
      }

    } else {
      messagePopup('', tempMsg, 'error')
    }
  }
  }

  formValidation = () => {
    let fieldErrors = {};
    fieldErrors['firstname'] = this.state.firstname ? nameRegex.test(this.state.firstname) ? this.state.firstname.length > 40 ? 'First Name must be between 1 to 40 Characters' : '' : "Please Enter a valid First Name" : 'First Name is required';
    fieldErrors['lastname'] = this.state.lastname ? nameRegex.test(this.state.lastname) ? this.state.lastname.length > 40 ? 'Last Name must be between 1 to 40 Characters' : '' : "Please Enter a valid Last Name" : 'Last Name is required';
    // fieldErrors['email'] = this.state.email ? emailRegex.test(this.state.email) ? "" : "Please Enter a valid Email" : 'Email is required';
    fieldErrors['phonenumber'] = this.state.phonenumber && isValidPhoneNumber(this.state.phonenumber) ? "" : 'Phone Number is required';
    this.setState({ fieldErrors })
    return fieldErrors['firstname']
      ? fieldErrors['firstname']
      : fieldErrors['lastname']
        ? fieldErrors['lastname']
        //  : fieldErrors['email'] 
        //  ? fieldErrors['email'] 
        : fieldErrors['phonenumber']
          ? fieldErrors['phonenumber']
          : '';
  }

  validateInputErrors = (name, value) => {
    let fieldErrors = { ...this.state.fieldErrors };
    if (name === 'firstname') {
      fieldErrors['firstname'] = value ? nameRegex.test(value) ? value.length > 40 ? 'First Name must be between 1 to 40 Characters' : '' : "Please Enter a valid First Name" : 'First Name is required';
    }
    if (name === 'lastname') {
      fieldErrors['lastname'] = value ? nameRegex.test(value) ? value.length > 40 ? 'Last Name must be between 1 to 40 Characters' : '' : "Please Enter a valid Last Name" : 'Last Name is required';
    }
    if (name === 'phonenumber') {
      fieldErrors['phonenumber'] = value ? !isNaN(value) ? (mobileNumRegex.test(value) ? "" : "Mobile Number should be contain 10 digit") : 'Please enter number only' : 'Phone Number is required';
    }
    if (name === 'buildingdetails') {
      fieldErrors['buildingdetails'] = value ? value.length<20 ? '' : "Building Details should not exceeds 20 characters" : '';
    }
    if (name === 'streetaddress'){
      fieldErrors['streetaddress'] = value ? value.length<20 ? '' : 'Street address should not exceeds 20 character' :  '';
    }
    if (name === 'city'){
      fieldErrors['city'] = value ? value.length<20 ? '' : 'City should not exceeds 20 character' :  '';
    }
    if (name === 'area'){
      fieldErrors['area'] = value ? value.length<20 ? '' : 'Area should not exceeds 20 character' :  '';
    }
    if (name === 'landmark'){
      fieldErrors['landmark'] = value ? value.length<20 ? '' : 'Landmark should not exceeds 20 character' :  '';
    }
    if (name === 'billingstreetaddress'){
      fieldErrors['billingstreetaddress'] = value ? value.length<20 ? '' : 'Billing Street Address should not exceeds 20 character' :  '';
    }
    if (name === 'billingbuildingdetails'){
      fieldErrors['billingbuildingdetails'] = value ? value.length<20 ? '' : 'Billing Building details should not exceeds 20 character' :  '';
    }
    if (name === 'billingcity'){
      fieldErrors['billingcity'] = value ? value.length<20 ? '' : 'Billing City should not exceeds 20 character' :  '';
    }
    if (name === 'billingarea'){
      fieldErrors['billingarea'] = value ? value.length<20 ? '' : 'Billing Area should not exceeds 20 character' :  '';
    }
    if (name === 'billinglandmark'){
      fieldErrors['billinglandmark'] = value ? value.length<20 ? '' : 'Billing landmark should not exceeds 20 character' :  '';
    }

    
    this.setState({ fieldErrors })
  }

  handleFileOnChange = (e) => {
    const {
      target: { value },
    } = e;
    const file = e.target.files[0]
    var path = (window.URL || window.webkitURL).createObjectURL(file);
    let filext = file.name.split('.');
    if (filext[1] == 'jpg' || filext[1] == 'png' || filext[1] == 'jpeg') {

      if (file.size < 1024000) {
        const fileArr = value.split('.');
        fileArr[0] = new Date().getTime();

        const newFile = new File([file], fileArr.join('.'), {
          type: file.type,
          lastModified: file.lastModified,
        });
        this.setState({ filePath: path, file: newFile })
      }
      else {
        alert("File size should be less than 1mb")
      }

    }
    else {
      alert("Please upload the file in .png , .jpg, .jpeg format")
    }
  }

  render() {
    return (
      <div className="pageWrap" style={{ marginTop: "85px" }}>
        <main className="myaccountpage">
          {/* <!-- breadcumb starts here -->	 */}
          <div className="breadcumb">
            <div className="container">
              <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/MyAccount">My Account</NavLink></li>
              </ul>
            </div>
          </div>
          <BackdropLoader open={this.props.user.loading || this.props.myAccount.loading || this.props.shippingAddress.loading || this.props.billingAddress.loading} />
          {/* <!-- myaccount code starts here -->	 */}
          <section className="myaccount">
            <div className="container">
              <h1>My Account</h1>
              <div className="accountwrap">
                <div className="userdetails">
                  <div className="userprofilepic">
                    <div className="userprofilebox">
                      {this.state.filePath ? (
                        <img src={this.state.filePath} alt="userprofilepic" />
                      ) : (
                        <img src={userprofilepicSvg} alt="userprofilepic" />
                      )}

                    </div>
                    <div className="picload">
                      <input
                        accept="image/*"
                        id="userprofilepic"
                        type="file"
                        style={{ display: 'none' }}
                        onChange={this.handleFileOnChange}
                      />
                      <label htmlFor="userprofilepic">
                        <Button variant="contained" className="btnpic" component="span">
                          Upload
                        </Button>
                      </label>
                    </div>
                  </div>
                  <div className="form-row">
                    <div className="form-group col-md-8 col-sm-12">
                      <TextField
                        id="email"
                        label="Email Address"
                        placeholder="admin@gmail.com"
                        variant="outlined"
                        className="text-field"
                        name="email"
                        value={this.state.email}
                        onChange={this.handleInputChange}
                        disabled
                        required
                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                          <PhoneInput
                            international
                            placeholder='Phone no.'
                            countryCallingCodeEditable={true}
                            defaultCountry="AE"
                            value={this.state.phonenumber}
                            onChange={(e) => {
                              this.setState({
                                phonenumber: e
                              })
                            }}
                            error={(this.state.phonenumber && isValidPhoneNumber(this.state.phonenumber)) ? undefined : 'Invalid phone number'}
                          />
                          {!this.state.fieldErrors['phonenumber'] ? '' : <p style={{ color: 'red' }}>please enter a valid phone number</p>}
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <TextField
                        id="fname"
                        label="First Name"
                        variant="outlined"
                        className="text-field"
                        name="firstname"
                        value={this.state.firstname}
                        onChange={this.handleInputChange}
                        required
                        error={this.state.fieldErrors['firstname'] ? true : false}
                        helperText={this.state.fieldErrors['firstname'] ? this.state.fieldErrors['firstname'] : ''}
                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <TextField
                        id="lname"
                        label="Last Name"
                        variant="outlined"
                        className="text-field"
                        name="lastname"
                        value={this.state.lastname}
                        onChange={this.handleInputChange}
                        required
                        error={this.state.fieldErrors['lastname'] ? true : false}
                        helperText={this.state.fieldErrors['lastname'] ? this.state.fieldErrors['lastname'] : ''}
                      />
                    </div>
                  </div>
                </div>
                {/* <!-- userdetails end here --> */}

                {/* <!-- shipping code shart here --> */}
                <div className="shipping">
                  <h2>Shipping Address</h2>
                  <div className="form-row">
                    <div className="form-group col-md-4 col-sm-12">
                      <TextField
                        id="steet"
                        label="Street Address"
                        variant="outlined"
                        className="text-field"
                        name="streetaddress"
                        value={this.state.streetaddress}
                        onChange={(e) => {
                          this.handleInputChange(e)
                        }}
                        error={this.state.fieldErrors['streetaddress'] ? true : false}
                        helperText={this.state.fieldErrors['streetaddress'] ? this.state.fieldErrors['streetaddress'] : ''}

                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <TextField
                        id="buildingdetails"
                        label="Building name/number/apartment"
                        variant="outlined"
                        className="text-field"
                        name="buildingdetails"
                        value={this.state.buildingdetails}
                        onChange={(e) => {
                          this.handleInputChange(e)
                        }}
                        error={this.state.fieldErrors['buildingdetails'] ? true : false}
                        helperText={this.state.fieldErrors['buildingdetails'] ? this.state.fieldErrors['buildingdetails'] : ''}

                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <TextField
                        id="city"
                        label="City"
                        variant="outlined"
                        className="text-field"
                        name="city"
                        value={this.state.city}
                        onChange={(e) => {
                          this.handleInputChange(e)
                        }}
                        error={this.state.fieldErrors['city'] ? true : false}
                        helperText={this.state.fieldErrors['city'] ? this.state.fieldErrors['city'] : ''}

                      />
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <TextField
                        id="area"
                        label="area"
                        variant="outlined"
                        className="text-field"
                        name="area"
                        value={this.state.area}
                        onChange={(e) => {
                          this.handleInputChange(e)
                        }}
                        error={this.state.fieldErrors['area'] ? true : false}
                        helperText={this.state.fieldErrors['area'] ? this.state.fieldErrors['area'] : ''}

                      />
                      {/* <FormControl variant="outlined" className="select-dropdown">
                                                <InputLabel id="area-label">Area</InputLabel>
                                                <Select
                                                    labelId="area-label"
                                                    id="area"
                                                    name="area"
                                                    value={this.state.area}
                                                    onChange={this.handleSelectChange}
                                                    label="Area"
                                                >
                                                    <MenuItem value="Punjab">Punjab</MenuItem>
                                                    <MenuItem value="1">One</MenuItem>
                                                    <MenuItem value="2">Two</MenuItem>
                                                    <MenuItem value="3">Three</MenuItem>
                                                </Select>
                                            </FormControl> */}
                    </div>
                    <div className="form-group col-md-4 col-sm-12">
                      <TextField
                        id="landmark"
                        label="Nearest Landmark"
                        variant="outlined"
                        className="text-field"
                        name="landmark"
                        value={this.state.landmark}
                        onChange={(e) => {
                          this.handleInputChange(e)
                        }}
                        error={this.state.fieldErrors['landmark'] ? true : false}
                        helperText={this.state.fieldErrors['landmark'] ? this.state.fieldErrors['landmark'] : ''}

                      />
                    </div>
                  </div>
                </div>
                {/* <!-- shipping code closed --> */}
                <div className="billing">
                  <h2>Billing Address</h2>
                  <div className="form-row">
                    <RadioGroup row aria-label="billingAddress" name="sameasshipping" value={this.state.sameasshipping} className="radio-btn" onChange={this.handleRadioChange}>
                      <FormControlLabel
                        value='0'
                        control={<Radio style={{ color: 'green' }} />}
                        label="Same as shipping address"
                      />
                      <FormControlLabel
                        value='1'
                        control={<Radio style={{ color: 'green' }} />}
                        label="Use a different billing address"
                      />
                    </RadioGroup>
                  </div>
                  {this.state.sameasshipping === '1' && (
                    <div className="form-row" style={{ marginTop: '15px' }}>
                      <div className="form-group col-md-4 col-sm-12">
                        <TextField
                          id="steet"
                          label="Street Address"
                          variant="outlined"
                          className="text-field"
                          name="billingstreetaddress"
                          value={this.state.billingstreetaddress}
                          onChange={(e) => {
                          this.handleInputChange(e)
                        }}
                        error={this.state.fieldErrors['billingstreetaddress'] ? true : false}
                        helperText={this.state.fieldErrors['billingstreetaddress'] ? this.state.fieldErrors['billingstreetaddress'] : ''}

                        />
                      </div>
                      <div className="form-group col-md-4 col-sm-12">
                        <TextField
                          id="buildingdetails"
                          label="Building name/number/apartment"
                          variant="outlined"
                          className="text-field"
                          name="billingbuildingdetails"
                          value={this.state.billingbuildingdetails}
                          onChange={(e) => {
                            this.handleInputChange(e)
                          }}
                          error={this.state.fieldErrors['billingbuildingdetails'] ? true : false}
                          helperText={this.state.fieldErrors['billingbuildingdetails'] ? this.state.fieldErrors['billingbuildingdetails'] : ''}
  
                        />
                      </div>
                      <div className="form-group col-md-4 col-sm-12">
                        <TextField
                          id="city"
                          label="City"
                          variant="outlined"
                          className="text-field"
                          name="billingcity"
                          value={this.state.billingcity}
                          onChange={(e) => {
                            this.handleInputChange(e)
                          }}
                          error={this.state.fieldErrors['billingcity'] ? true : false}
                          helperText={this.state.fieldErrors['billingcity'] ? this.state.fieldErrors['billingcity'] : ''}
  
                        />
                      </div>
                      <div className="form-group col-md-4 col-sm-12">
                        <TextField
                          id="area"
                          label="Area"
                          variant="outlined"
                          className="text-field"
                          name="billingarea"
                          value={this.state.billingarea}
                          onChange={(e) => {
                            this.handleInputChange(e)
                          }}
                          error={this.state.fieldErrors['billingarea'] ? true : false}
                          helperText={this.state.fieldErrors['billingarea'] ? this.state.fieldErrors['billingarea'] : ''}
  
                        />
                        {/* <FormControl variant="outlined" className="select-dropdown">
                          <InputLabel id="area-label">Area</InputLabel>
                          <Select
                            labelId="area-label"
                            id="area"
                            name="billingarea"
                            value={this.state.billingarea}
                            onChange={this.handleSelectChange}
                            label="Area"
                          >
                            <MenuItem value="Punjab">Punjab</MenuItem>
                            <MenuItem value="1">One</MenuItem>
                            <MenuItem value="2">Two</MenuItem>
                            <MenuItem value="3">Three</MenuItem>
                          </Select>
                        </FormControl> */}
                      </div>
                      <div className="form-group col-md-4 col-sm-12">
                        <TextField
                          id="landmark"
                          label="Nearest Landmark"
                          variant="outlined"
                          className="text-field"
                          name="billinglandmark"
                          value={this.state.billinglandmark}
                          onChange={(e) => {
                            this.handleInputChange(e)
                          }}
                          error={this.state.fieldErrors['billinglandmark'] ? true : false}
                          helperText={this.state.fieldErrors['billinglandmark'] ? this.state.fieldErrors['billinglandmark'] : ''}
  
                        />
                      </div>
                    </div>
                  )}
                </div>
                {/* <!-- billing closed --> */}
                <Button variant="contained" className="uploadBtn" component="span" onClick={this.handleSubmit}>
                  Update Profile
                </Button>
              </div>
              {/* <!-- accountwrap closed --> */}
            </div>
            {/* <!-- container closed --> */}
          </section>
          {/* <!-- section closed --> */}
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user,
    myAccount: state.myAccount,
    shippingAddress: state.shippingAddress,
    billingAddress: state.billingAddress
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getUserData: (data) => dispatch(getUserData(data)),
    uploadProfileImageStart: () => dispatch(uploadProfileImageStart()),
    uploadProfileImageFailed: () => dispatch(uploadProfileImageFailed()),
    updateUserAccount: (data) => dispatch(updateUserAccount(data)),
    getShippingAddress: (data) => dispatch(getShippingAddress(data)),
    getBillingAddress: (data) => dispatch(getBillingAddress(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(MyAccount);