import { BACK_TO_LOGIN, LOGIN_FAILED, LOGIN_START, LOGIN_SUCCESS, OTP_PHASE } from '../../actions/types';

const initialData = {
    loading: false,
    data: null,
    error: null,
    startOtpPhase: false,
}

export default function (state = initialData, action) {
    switch (action.type) {
        case LOGIN_START:
            return {
                ...state,
                loading: true,
            };
        case LOGIN_SUCCESS:
            return {
                ...state,
                data: action.payload,
                startOtpPhase: false,
                loading: false,
                error: null
            };
        case LOGIN_FAILED:
            return {
                ...state,
                error: action.payload,
                data: null,
                loading: false
            };
        case OTP_PHASE:
            return {
                ...state,
                startOtpPhase: true,
                loading: false
            }
        case BACK_TO_LOGIN:
            return {
                ...state,
                startOtpPhase: false,
            }
        default:
            return { ...state };
    }
}