import { SAVE_REGISTER_KIT_START, SAVE_REGISTER_KIT_SUCCESS, SAVE_REGISTER_KIT_FAILED } from '../actions/types';

const initialData = {
   loading: false,
   data: null,
   error: null
}


export default function(state = initialData, action) {
    switch(action.type) {
        case SAVE_REGISTER_KIT_START:
            return {
                ...state,
                loading: true
            };
        case SAVE_REGISTER_KIT_SUCCESS: 
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            }
        case SAVE_REGISTER_KIT_FAILED: 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null
            }
        default: 
            return {
                ...state
            }
    }
}