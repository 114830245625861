import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import lockSvg from "../../assets/img/lock.svg";
import closedSvg from "../../assets/img/closed.svg";
import "../../assets/css/secure-checkout.scss";
import {
  FormControl,
  FormControlLabel,
  FormHelperText,
  InputLabel,
  MenuItem,
  Radio,
  RadioGroup,
  Select,
  TextField,
} from "@material-ui/core";
import Login from "../../Common/Login/login";
import { getAuthToken, getUserInfo } from "../../Services/authService";
import { getUserShoppingCartData } from "../../Redux/actions/Shopping-Cart/actions";
import { connect } from "react-redux";
import {
  getAllShoppingCartsQuery,
  saveShoppingCartDataQuery,
} from "../Shopping-Cart/ShoppingCartQuery";
import { getTestListQuery } from "../Home/HomeQuery";
import { getUserData } from "../../Redux/actions/user/user";
import { getUserInfoQuery } from "../RegisterKit/RegisterKitQuery";
import { saveCheckoutPaynow } from "../../Redux/actions/checkout/checkout";
import BackdropLoader from "../../Services/loader";
import { getShippingAddressQuery, getBillingAddressQuery } from "../MyAccount/MyAccountQuery";
import { getShippingAddress, getBillingAddress } from "../../Redux/actions/myAccount/myAccount";
import messagePopup from "../../Services/messagePopupService";
import StripeCheckout from "react-stripe-checkout";
import { loadStripe } from '@stripe/stripe-js';
import axios from "axios";
import { API_URL } from "../../Config";
import swal from "sweetalert";

class Checkout extends Component {
  constructor(props) {
    super(props);
    this.state = {
      email: "",
      phonenumber: "",
      firstname: "",
      lastname: "",
      streetaddress: "",
      buildingdetails: "",
      city: "",
      area: "Punjab",
      landmark: "",
      isSameBillingAddress: "0",
      billingstreetaddress: "",
      billingbuildingdetails: "",
      billingcity: "",
      billingarea: "",
      billinglandmark: "",
      fieldErrors: {},
    };
  }

  testCardData = async () => {
    const userid = getUserInfo().id;
    const variables = {
      obj: {
        active: 1,
        userid: userid,
      },
    };
    await this.props.getUserShoppingCartData({
      query: {
        getCartQuery: getAllShoppingCartsQuery,
        getTestQuery: getTestListQuery,
      },
      variables,
    });
    await this.props.getUserData({
      query: getUserInfoQuery,
      variables: {
        obj: {
          id: userid,
        },
      },
    });
    await this.props.getShippingAddress({
      query: getShippingAddressQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          acrive: 1,
        },
      },
    });
    await this.props.getBillingAddress({
      query: getBillingAddressQuery,
      variables: {
        obj: {
          userid: getUserInfo().id,
          acrive: 1,
        },
      },
    });
  }

  componentDidMount = async () => {
    if (getAuthToken()) {
      await this.testCardData()
      if (parseInt(window.location.href.split("checkout/")[1]) != 1) {
        await this.testCardData()
        let sessionId = window.location.href.split("successStripe?session_id=")[1]
        // await this.props.saveCheckoutPaynow({
        //   apiData: {
        //     sessionId: sessionId,
        //   },
          // actualData: this.props.cartDetails.data,
          // query: saveShoppingCartDataQuery,
        // });
        if(sessionId){
          messagePopup('','Oder Placed Successfully','success')
          this.props.history.push('/shop_all_test')
        }
      }

    }
    this.setState({ isSameBillingAddress: this.props.billingAddress.data.sameasshipping === 0 ? "0" : "1" })
  };
  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props && this.props.user.data) {
      this.setState({
        firstname: this.props.user.data ? this.props.user.data.firstname : "",
        lastname: this.props.user.data ? this.props.user.data.lastname : "",
        email: this.props.user.data ? this.props.user.data.email : "",
        phonenumber: this.props.user.data
          ? this.props.user.data.phonenumber
            ? this.props.user.data.phonenumber
            : ""
          : "",
        streetaddress: this.props.shippingAddress.data
          ? this.props.shippingAddress.data.streetaddress
          : "",
        buildingdetails: this.props.shippingAddress.data
          ? this.props.shippingAddress.data.buildingdetails
          : "",
        city: this.props.shippingAddress.data
          ? this.props.shippingAddress.data.city
          : "",
        area: this.props.shippingAddress.data
          ? this.props.shippingAddress.data.area
          : "",
        landmark: this.props.shippingAddress.data
          ? this.props.shippingAddress.data.landmark
          : "",

        //My code
        billingstreetaddress: this.props.billingAddress.data
          ? this.props.billingAddress.data.streetaddress
          : "",
        billingbuildingdetails: this.props.billingAddress.data
          ? this.props.billingAddress.data.buildingdetails
          : "",
        billingcity: this.props.billingAddress.data
          ? this.props.billingAddress.data.city
          : "",
        billingarea: this.props.billingAddress.data
          ? this.props.billingAddress.data.area
          : "",
        billinglandmark: this.props.billingAddress.data
          ? this.props.billingAddress.data.landmark
          : "",
      });
    }
  };

  handleInputChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    this.validateInputErrors(name, value);
  };

  handleSelectChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    this.validateInputErrors(name, value);
  };

  handleRadioChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    if (value === "0") {
      this.setState({
        billingstreetaddress: "",
        billingbuildingdetails: "",
        billingcity: "",
        billingarea: "",
        billinglandmark: "",
      });
      const {
        billingstreetaddress,
        billingbuildingdetails,
        billingcity,
        billingarea,
        billinglandmark,
        ...rest
      } = { ...this.state.fieldErrors };
      this.setState({ fieldErrors: rest });
    }
  };

  handleExpandCollapse = (evt) => {
    evt.preventDefault();
    this.setState((prevState) => ({ isOpen: !prevState.isOpen }));
  };

  handleApplyPromocode = (evt) => {
    evt.preventDefault();
  };
  createCheckoutSession = (amount) => {
    const tempMsg = this.formValidation();
    if (!tempMsg) {
      let productDetail = [{
        userId: getUserInfo().id,
        productDetails: this.props.cartDetails.data
          ? this.props.cartDetails.data.map((item) => {
            return {
              testId: item.id,
              quantity: '1',
              testPrice: item.price,
              testName: item.testname
            };
          })
          : [],
        totalAmount: `${this.getTotalPrice(this.props.cartDetails.data)}`,
        shippingAddress: this.getShippingAddressString(),
        sameAsShippingAddress:
          this.state.isSameBillingAddress === "0" ? true : false,
        billingAddress:
          this.state.isSameBillingAddress === "1"
            ? this.getBillingAddressString()
            : this.getShippingAddressString()
      }]

      return fetch(`${API_URL}/api/Payments/checkoutPayment`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          amount: parseInt(amount),
          emailId: getUserInfo().email,
          productDetails: productDetail,
          typeOfPayment: "Buy"
        }),
      })
        .then((data) => data.json())
        .then((data) => {
          
          return data.response;
        });
    } else {
      messagePopup("", tempMsg, "error");
    }
  }
  handlePayNow = (token) => {
    if( this.state.streetaddress != '' && this.state.buildingdetails != '' && this.state.area != '' && this.state.city != '', this.state.landmark != ''){
      let stripeKey = "pk_test_51J2E68AFP91qVNcj0nO4DLKCy2Ditt17mCvxsYQ7UvuW2UNVspZzVYdQREdg32SyCgIRRzOPGmWVjx3ADHbBhCYk00zYYD02SC"
      const stripResult = loadStripe(stripeKey).then(async (stripe) => {
        stripe?.redirectToCheckout({
          sessionId: await this.createCheckoutSession("13"),
        });
      })
    }
    else{
      swal('Please Fill all the mandatory fields')
    }
  }

  getShippingAddressString = () => {
    let address = "";
    if (this.state.streetaddress) {
      address = address + this.state.streetaddress;
    }
    if (this.state.buildingdetails) {
      address = address + " " + this.state.buildingdetails;
    }
    if (this.state.city) {
      address = address + " " + this.state.city;
    }
    if (this.state.area) {
      address = address + " " + this.state.area;
    }
    if (this.state.landmark) {
      address = address + " " + this.state.landmark;
    }
    return address;
  };

  getBillingAddressString = () => {
    let address = "";
    if (this.state.billingstreetaddress) {
      address = address + this.state.billingstreetaddress;
    }
    if (this.state.billingbuildingdetails) {
      address = address + " " + this.state.billingbuildingdetails;
    }
    if (this.state.billingcity) {
      address = address + " " + this.state.billingcity;
    }
    if (this.state.billingarea) {
      address = address + " " + this.state.billingarea;
    }
    if (this.state.billinglandmark) {
      address = address + " " + this.state.billinglandmark;
    }
    return address;
  };

  getTotalPrice = (data) => {
    if (data && data.length) {
      let price = 0;
      for (const item of data) {
        price += +item.price * +item.quantity;
      }
      return price;
    } else {
      return 0;
    }
  };

  formValidation = (showError = true) => {
    let fieldErrors = {};
    fieldErrors["streetaddress"] = this.state.streetaddress
      ? ""
      : "Street Address is required.";
    fieldErrors["city"] = this.state.city ? "" : "City is required.";
    fieldErrors["buildingdetails"] = this.state.buildingdetails
      ? ""
      : "Building Name is required.";
    fieldErrors["landmark"] = this.state.landmark
      ? ""
      : "landmark is required field.";
    fieldErrors["area"] = this.state.area ? "" : "Area is required field.";
    if (this.state.isSameBillingAddress === "1") {
      fieldErrors["billingstreetaddress"] = this.state.billingstreetaddress
        ? ""
        : "Street Address is required.";
      fieldErrors["billingcity"] = this.state.billingcity
        ? ""
        : "City is required.";
      fieldErrors["billingbuildingdetails"] = this.state.billingbuildingdetails
        ? ""
        : "Building Name is required.";
      fieldErrors["billinglandmark"] = this.state.billinglandmark
        ? ""
        : "landmark is required field.";
      fieldErrors["billingarea"] = this.state.billingarea
        ? ""
        : "Area is required field.";
    }
    if (showError) {
      this.setState({ fieldErrors });
    }
    return fieldErrors["streetaddress"]
      ? fieldErrors["streetaddress"]
      : fieldErrors["buildingdetails"]
        ? fieldErrors["buildingdetails"]
        : fieldErrors["city"]
          ? fieldErrors["city"]
          : fieldErrors["area"]
            ? fieldErrors["area"]
            : fieldErrors["landmark"]
              ? fieldErrors["landmark"]
              : this.state.isSameBillingAddress === "1"
                ? fieldErrors["billingstreetaddress"]
                  ? fieldErrors["billingstreetaddress"]
                  : fieldErrors["billingbuildingdetails"]
                    ? fieldErrors["billingbuildingdetails"]
                    : fieldErrors["billingcity"]
                      ? fieldErrors["billingcity"]
                      : fieldErrors["billingarea"]
                        ? fieldErrors["billingarea"]
                        : fieldErrors["billinglandmark"]
                          ? fieldErrors["billinglandmark"]
                          : ""
                : "";
  };

  validateInputErrors = (name, value) => {
    let fieldErrors = { ...this.state.fieldErrors };
    if (name === "streetaddress") {
      fieldErrors["streetaddress"] = value ? "" : "Street Address is required.";
    }
    if (name === "city") {
      fieldErrors["city"] = value ? "" : "City is required.";
    }
    if (name === "buildingdetails") {
      fieldErrors["buildingdetails"] = value
        ? ""
        : "Building Name is required.";
    }
    if (name === "landmark") {
      fieldErrors["landmark"] = value ? "" : "landmark is required field.";
    }
    if (name === "area") {
      fieldErrors["area"] = value ? "" : "Area is required field.";
    }
    if (name === "billingstreetaddress") {
      fieldErrors["billingstreetaddress"] = value
        ? ""
        : "Street Address is required.";
    }
    if (name === "billingcity") {
      fieldErrors["billingcity"] = value ? "" : "City is required.";
    }
    if (name === "billingbuildingdetails") {
      fieldErrors["billingbuildingdetails"] = value
        ? ""
        : "Building Name is required.";
    }
    if (name === "billinglandmark") {
      fieldErrors["billinglandmark"] = value
        ? ""
        : "landmark is required field.";
    }
    if (name === "billingarea") {
      fieldErrors["billingarea"] = value ? "" : "Area is required field.";
    }
    this.setState({ fieldErrors });
  };

  submitStripeForm = (token) => {
    // axios({
    //   url: API_URL + `/api/Payments/createPayment`,
    //   method: "post",
    //   data: {},
    //   headers: {
    //     access_token: getAuthToken(),
    //   },
    // });
  };

  handleOpenStripe = () => {
    const tempMsg = this.formValidation();
    if (!tempMsg) {
    } else {
      messagePopup("", tempMsg, "error");
    }
  };

  render() {
    return (
      <div style={{ marginTop: "85px" }}>
        <main className="securecheckout">
          {/* <!-- breadcumb starts here -->	 */}
          <div className="breadcumb">
            <div className="container">
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink to="/checkout">Secure Checkout</NavLink>
                </li>
              </ul>
            </div>
          </div>
          <BackdropLoader
            open={
              this.props.cartDetails.loading ||
              this.props.checkout.loading ||
              this.props.user.loading ||
              this.props.shippingAddress.loading
            }
          />
          {/* <!-- checkout section start here --> */}
          <section className="checkout">
            <div className="container">
              <div className="heading">
                <div className="headshop">
                  <h1>Secure Checkout</h1>
                </div>
                <div className="server">
                  {" "}
                  <img src={lockSvg} alt="lock" /> Secure Server
                </div>
              </div>
              {!getAuthToken() && (
                <div className="logintoacount">
                  <p>
                    <Login btnType="login" elementType="NavLink">
                      Login
                    </Login>{" "}
                    for a quick checkout experience if you already have an
                    account.
                  </p>
                </div>
              )}

              {/* <!-- secure shipping details start here --> */}
              <div className="securewrap">
                <div className="securewrapcnt">
                  <div className="shippingdetails">
                    <h2>Shipping Address</h2>
                    <div className="customerfrom">
                      <div className="form-group">
                        <TextField
                          label="Email Address"
                          variant="outlined"
                          className="text-field"
                          id="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          disabled={true}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="+971 Phone Number"
                          id="phone"
                          name="phonenumber"
                          value={this.state.phonenumber}
                          onChange={this.handleInputChange}
                          disabled={true}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="First Name"
                          id="firstname"
                          name="firstname"
                          value={this.state.firstname}
                          onChange={this.handleInputChange}
                          disabled={true}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Last Name"
                          id="lastname"
                          name="lastname"
                          value={this.state.lastname}
                          onChange={this.handleInputChange}
                          disabled={true}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Street Address"
                          id="streetaddress"
                          name="streetaddress"
                          value={this.state.streetaddress}
                          onChange={this.handleInputChange}
                          required
                          error={
                            this.state.fieldErrors["streetaddress"]
                              ? true
                              : false
                          }
                          helperText={
                            this.state.fieldErrors["streetaddress"]
                              ? this.state.fieldErrors["streetaddress"]
                              : ""
                          }
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Building name/number/villa/apartment"
                          id="buildingdetails"
                          name="buildingdetails"
                          value={this.state.buildingdetails}
                          onChange={this.handleInputChange}
                          required
                          error={
                            this.state.fieldErrors["buildingdetails"]
                              ? true
                              : false
                          }
                          helperText={
                            this.state.fieldErrors["buildingdetails"]
                              ? this.state.fieldErrors["buildingdetails"]
                              : ""
                          }
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="City"
                          id="city"
                          name="city"
                          value={this.state.city}
                          onChange={this.handleInputChange}
                          required
                          error={this.state.fieldErrors["city"] ? true : false}
                          helperText={
                            this.state.fieldErrors["city"]
                              ? this.state.fieldErrors["city"]
                              : ""
                          }
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Area"
                          id="area"
                          name="area"
                          value={this.state.area}
                          onChange={this.handleSelectChange}
                          required
                          error={this.state.fieldErrors["area"] ? true : false}
                          helperText={
                            this.state.fieldErrors["area"]
                              ? this.state.fieldErrors["area"]
                              : ""
                          }
                        />
                        {/* <FormControl
                          variant="outlined"
                          className="select-dropdown"
                          required
                          error={this.state.fieldErrors["area"] ? true : false}
                        >
                          <InputLabel id="area-label">Area</InputLabel>
                          <Select
                            labelId="area-label"
                            id="area"
                            name="area"
                            value={this.state.area}
                            onChange={this.handleSelectChange}
                            label="Area"
                          >
                            <MenuItem value="Punjab">Punjab</MenuItem>
                            <MenuItem value="1">One</MenuItem>
                            <MenuItem value="2">Two</MenuItem>
                            <MenuItem value="3">Three</MenuItem>
                          </Select>
                          {this.state.fieldErrors["area"] && (
                            <FormHelperText>
                              {this.state.fieldErrors["area"]}
                            </FormHelperText>
                          )}
                        </FormControl> */}
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Nearest Landmark"
                          id="landmark"
                          name="landmark"
                          value={this.state.landmark}
                          onChange={this.handleInputChange}
                          required
                          error={
                            this.state.fieldErrors["landmark"] ? true : false
                          }
                          helperText={
                            this.state.fieldErrors["landmark"]
                              ? this.state.fieldErrors["landmark"]
                              : ""
                          }
                        />
                      </div>
                      <p>
                        A landmark helps us in locating your address. Please
                        specify a well-known place near your address like a
                        Mall, Hotel, Gas Station, etc.
                      </p>
                      <h4>Billing Address</h4>
                      <div className="form-row">
                        <RadioGroup
                          row
                          aria-label="billingAddress"
                          name="isSameBillingAddress"
                          value={this.state.isSameBillingAddress}
                          className="radio-btn"
                          onChange={this.handleRadioChange}
                        >
                          <FormControlLabel
                            value="0"
                            control={<Radio style={{ color: "green" }} />}
                            label="Same as shipping address"
                          />
                          <FormControlLabel
                            value="1"
                            control={<Radio style={{ color: "green" }} />}
                            label="Use a different billing address"
                          />
                        </RadioGroup>
                      </div>
                      {this.state.isSameBillingAddress === "1" && (
                        <div className="form-row" style={{ marginTop: "15px" }}>
                          <div className="form-group col-md-6 col-sm-12">
                            <TextField
                              id="steet"
                              label="Street Address"
                              variant="outlined"
                              className="text-field"
                              name="billingstreetaddress"
                              value={this.state.billingstreetaddress}
                              onChange={this.handleInputChange}
                              required
                              error={
                                this.state.fieldErrors["billingstreetaddress"]
                                  ? true
                                  : false
                              }
                              helperText={
                                this.state.fieldErrors["billingstreetaddress"]
                                  ? this.state.fieldErrors[
                                  "billingstreetaddress"
                                  ]
                                  : ""
                              }
                            />
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <TextField
                              id="buildingdetails"
                              label="Building name/number/apartment"
                              variant="outlined"
                              className="text-field"
                              name="billingbuildingdetails"
                              value={this.state.billingbuildingdetails}
                              onChange={this.handleInputChange}
                              required
                              error={
                                this.state.fieldErrors["billingbuildingdetails"]
                                  ? true
                                  : false
                              }
                              helperText={
                                this.state.fieldErrors["billingbuildingdetails"]
                                  ? this.state.fieldErrors[
                                  "billingbuildingdetails"
                                  ]
                                  : ""
                              }
                            />
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <TextField
                              id="city"
                              label="City"
                              variant="outlined"
                              className="text-field"
                              name="billingcity"
                              value={this.state.billingcity}
                              onChange={this.handleInputChange}
                              required
                              error={
                                this.state.fieldErrors["billingcity"]
                                  ? true
                                  : false
                              }
                              helperText={
                                this.state.fieldErrors["billingcity"]
                                  ? this.state.fieldErrors["billingcity"]
                                  : ""
                              }
                            />
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <TextField
                              id="area"
                              label="Area"
                              variant="outlined"
                              className="text-field"
                              name="billingarea"
                              value={this.state.billingarea}
                              onChange={this.handleSelectChange}
                              required
                              error={
                                this.state.fieldErrors["billingarea"]
                                  ? true
                                  : false
                              }
                              helperText={
                                this.state.fieldErrors["billingarea"]
                                  ? this.state.fieldErrors["billingarea"]
                                  : ""
                              }
                            />
                            {/* <FormControl
                              variant="outlined"
                              className="select-dropdown"
                              required
                              error={
                                this.state.fieldErrors["billingarea"]
                                  ? true
                                  : false
                              }
                            >
                              <InputLabel id="area-label">Area</InputLabel>
                              <Select
                                labelId="area-label"
                                id="area"
                                name="billingarea"
                                value={this.state.billingarea}
                                onChange={this.handleSelectChange}
                                label="Area"
                              >
                                <MenuItem value="Punjab">Punjab</MenuItem>
                                <MenuItem value="1">One</MenuItem>
                                <MenuItem value="2">Two</MenuItem>
                                <MenuItem value="3">Three</MenuItem>
                              </Select>
                              {this.state.fieldErrors["billingarea"] && (
                                <FormHelperText>
                                  {this.state.fieldErrors["billingarea"]}
                                </FormHelperText>
                              )}
                            </FormControl> */}
                          </div>
                          <div className="form-group col-md-6 col-sm-12">
                            <TextField
                              id="landmark"
                              label="Nearest Landmark"
                              variant="outlined"
                              className="text-field"
                              name="billinglandmark"
                              value={this.state.billinglandmark}
                              onChange={this.handleInputChange}
                              required
                              error={
                                this.state.fieldErrors["billinglandmark"]
                                  ? true
                                  : false
                              }
                              helperText={
                                this.state.fieldErrors["billinglandmark"]
                                  ? this.state.fieldErrors["billinglandmark"]
                                  : ""
                              }
                            />
                          </div>
                        </div>
                      )}
                    </div>
                  </div>
                  {!getAuthToken() && (
                    <p>
                      Note: By signing up here, you are agreeing to our "
                      <NavLink to="/">Terms of use</NavLink>".
                    </p>
                  )}
                </div>
                {/* <!-- ordersummary start here --> */}
                <div className="ordersummary">
                  <h3>Order Summary</h3>
                  {/* {ShoppingItems.map((val, index) => ( */}
                  <div>
                    <div className="summarydetail">
                      <div className="itemtxt">Subtotal</div>
                      <div className="itemprice">
                        AED {this.getTotalPrice(this.props.cartDetails.data)}
                      </div>
                    </div>
                    <div className="summarydetail">
                      <div className="itemtxt">Shipping</div>
                      <div className="itemprice fontweight600">Free</div>
                    </div>
                    <div className="summarydetail">
                      <div className="itentxt ">Total</div>
                      <div className="itemprice fontweight600">
                        AED {this.getTotalPrice(this.props.cartDetails.data)}
                      </div>
                    </div>
                    {/* <div className="promowrap">
                      <NavLink
                        className={`${this.state.isOpen ? "" : "collapsed"}`}
                        onClick={(e) => this.handleExpandCollapse(e)}
                        to="/"
                        role="button"
                      >
                        Add promo code
                      </NavLink>
                      <div
                        className={`collapse ${this.state.isOpen ? "show" : ""
                          }`}
                        id="promocode"
                      >
                        <div className="promobody">
                          <p>use thulsee2021 to get 5% inauguration discount</p>
                          <input
                            type="text"
                            id="code"
                            name="promocode"
                            placeholder="Code"
                          />
                          <NavLink to="/" onClick={this.handleApplyPromocode}>
                            Apply
                          </NavLink>
                          <img
                            id="promoclosed"
                            src={closedSvg}
                            alt="closed"
                            onClick={(e) => this.handleExpandCollapse(e)}
                          />
                        </div>
                      </div>
                    </div> */}
                    <div className="summarydetail">
                      <div className="grandtotal fontweight600">Total</div>
                      <div className="itemprice fontweight600">
                        AED {this.getTotalPrice(this.props.cartDetails.data)}
                      </div>
                    </div>
                    {/* <StripeCheckout
                      token={this.handlePayNow}
                      amount={
                        this.getTotalPrice(this.props.cartDetails.data) * 100
                      }
                      currency="AED"
                      stripeKey="pk_test_51IyAneLFKic3XGgFKdDiFGG43TAsUZzOhmVKPG8H2OFbdKETBNVFVt9konzmN8yBBfRdW1nM6m22o1GDoJzB0aSw005RmzHOTX"
                    > */}
                    <button
                      className="btncheckout"
                      // disabled={this.formValidation(false) ? true : false}
                      // style={{
                      //   cursor: this.formValidation(false)
                      //     ? "not-allowed"
                      //     : "pointer",
                      // }}
                      onClick={this.handlePayNow}
                    >
                      Pay Now
                    </button>
                    {/* </StripeCheckout> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartDetails: state.cartDetails,
    user: state.user,
    checkout: state.checkout,
    shippingAddress: state.shippingAddress,
    billingAddress: state.billingAddress
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getUserShoppingCartData: (data) => dispatch(getUserShoppingCartData(data)),
    getUserData: (data) => dispatch(getUserData(data)),
    saveCheckoutPaynow: (data) => dispatch(saveCheckoutPaynow(data)),
    getShippingAddress: (data) => dispatch(getShippingAddress(data)),
    getBillingAddress: (data) => dispatch(getBillingAddress(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Checkout);
