import {Component} from 'react';
import { Provider } from 'react-redux'
import store from './store'
import './assets/css/stylesheet.scss';
import '../node_modules/bootstrap/dist/css/bootstrap.min.css';
import TabRoutes from './Pages/Navigation/Routes';
import CookieConsent from "react-cookie-consent";

class App extends Component {
  render(){
    return (
      <div className="App">
        <CookieConsent 
        location="bottom"
        buttonText="Accept"
        cookieName="myAwesomeCookieName2"
        style={{ background: "#e9fbef", color: '#22ab4b' }}
        buttonStyle={{ color: "#4e503b", fontSize: "13px" }}
        expires={150}>
          This website uses cookies to enhance the user experience.
        </CookieConsent>
        <Provider store={store}>
          <TabRoutes />
        </Provider>
      </div>
    );
  }
}

export default App;
