import React, { Component } from 'react';
import TextField from '@material-ui/core/TextField';
import '../../assets/css/test-results.scss';
import btnDownload from '../../assets/img/btndownload.svg';
// import testResults from '../../dummy/testResults.json';
import ViewTest from './viewTest';
import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import { connect } from 'react-redux';
import { getTestsResultStart } from '../../Redux/actions/Tests/testsResult';
import { getTestsReportQuery } from './testsQuery';
import { getUserInfo } from '../../Services/authService';
import BackdropLoader from '../../Services/loader';
import btnDVisiblity from '../../assets/img/btnvisibility.svg';
import { fetchMethod } from '../../Services/httpService';
import messagePopup from '../../Services/messagePopupService';



class TestResults extends Component {
    constructor(props) {
        super(props);
        this.state = {
            matchTestListArr: [],
            testList: [],
            testResults: [],
            test: "",
            testArr: [],
            loading: false
        }
    }

    handleSubmitCourse = (e) => {
        e.preventDefault();
    }


    handleChangeCourse = e => {
        const { _, value } = e.target
        const testResults = this.props.testsResult.data.filter(item => item.testid === value.testid);
        this.setState({ test: value, testResults });
    };

    getUnique(arr, comp) {
        const unique = arr
            //store the comparison values in array
            .map(e => e[comp])

            // store the keys of the unique objects
            .map((e, i, final) => final.indexOf(e) === i && i)

            .filter(e => arr[e])

            .map(e => arr[e]);

        return unique;
    }

    componentDidMount() {
        const variables = {
            obj: {
                userid: getUserInfo() ? getUserInfo().id : 0,
                active: 1
            }
        }
        this.props.getTestsResultStart({
            query: getTestsReportQuery,
            variables
        })
    }

    componentDidUpdate = (prevProps) => {
        if (prevProps !== this.props && this.props.testsResult.data) {
            const testArr = this.props.testsResult.data ? this.props.testsResult.data.map(item => {
                if (!this.state.matchTestListArr.includes(item.testid)) {
                    let arr = this.state.matchTestListArr
                    let list = this.state.testList
                    arr.push(item.testid)
                    list.push({
                        testid: item.testid,
                        testname: item.testname
                    })
                    this.setState({
                        matchTestListArr: arr, testList: list
                    })
                }
                return {
                    testid: item.testid,
                    testname: item.testname
                }
            }) : [{
                testid: '',
                testname: ''
            }]
            const testDetails = testArr[0] ? testArr[0] : {
                testid: '',
                testname: ''
            };
            const testResults = this.props.testsResult.data ? this.props.testsResult.data.filter(item => item.testid === testDetails.testid) : []
            this.setState({
                testArr,
                test: testDetails,
                testResults
            })
        }
    }

    handleView = (data) => {
        this.props.history.push(`/testResults/${data.reportid}/view`)
    }

    handleDownload = async (data) => {
        this.setState({ loading: true })
        try {
            const response = await fetchMethod('/api/Reports/createReport', {
                userId: data.userid,
                sampleId: data.sampleid
            }, 'post', true);
            if (response && response.status === 200) {
                if (response.data && response.data.response && response.data.response.pdfPath) {
                    this.setState({ loading: false })
                    var link = document.createElement("a");
                    link.setAttribute('download', 'report.pdf');
                    link.setAttribute('target', '_blank');
                    link.href = response.data.response.pdfPath;
                    document.body.appendChild(link);
                    link.click();
                    link.remove();
                } else {
                    messagePopup('', 'Download report failed', 'error');
                    this.setState({ loading: false })
                }
            } else {
                messagePopup('', 'Download report failed', 'error');
                this.setState({ loading: false })
            }
        } catch (error) {
            messagePopup('', 'Download report failed', 'error');
            this.setState({ loading: false })
        }
    }

    render() {
        return (
            <>
                <main className="testresultpage">
                    <BackdropLoader open={this.props.testsResult.loading || this.state.loading} />
                    <section className="dashboardwrap">
                        <div className="container">
                            <div className="flex">

                                <div className="header"><h1>Test Results</h1></div>
                                <div className="inputfield">
                                    {/* <div className="form-group date">
                                        <TextField
                                            id="date"
                                            label="Birthday"
                                            type="date"
                                            defaultValue={this.state.minDate}
                                            inputProps={{
                                                max: this.state.minDate
                                            }}
                                            InputLabelProps={{
                                            shrink: true,
                                            }}
                                        />
                                    </div> */}
                                    {/* <div className="form-group date">
                                        <TextField
                                            id="date"
                                            label="Birthday"
                                            type="date"
                                            defaultValue={this.state.minDate}
                                            inputProps={{
                                                min: "2020-10-10"
                                            }}
                                            InputLabelProps={{
                                                shrink: true,
                                                }}
                                        />
                                    </div> */}

                                    <div className="form-group dropdown">
                                        <FormControl variant="outlined" className="select-dropdown">
                                            <InputLabel id="area-label">Test Name</InputLabel>
                                            <Select
                                                labelId="area-label"
                                                id="area"
                                                name="area"
                                                value={this.state.test}
                                                onChange={this.handleChangeCourse}
                                                label="Test Name"
                                            >
                                                {this.state.testList.map(test => {
                                                    return <MenuItem key={test.testid} value={test}>
                                                        {test.testname}
                                                    </MenuItem>
                                                })}
                                            </Select>
                                        </FormControl>
                                    </div>
                                </div>

                                <div className="resulttable table-responsive">
                                    <table className="table table-hover">
                                        <thead>
                                            <tr>
                                                <th scope="col">Date</th>
                                                <th scope="col">Test name</th>
                                                <th scope="col">Result date</th>
                                                <th scope="col">Action</th>
                                            </tr>
                                        </thead>
                                        {this.state.testResults.map((val, index) => (
                                            <tbody key={index}>
                                                <tr>
                                                    <td>{val.testdate}</td>
                                                    <td>{val.testname}</td>
                                                    <td>{val.resultsdate}</td>

                                                    <td>
                                                        <button className="btnview" type="button" onClick={() => this.handleView(val)} >
                                                            <img src={btnDVisiblity} alt="Button Visibility" /> View
                                                        </button>
                                                        {/* <ViewTest datas={val}/> */}
                                                    </td>
                                                </tr>
                                            </tbody>
                                        ))}
                                    </table>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>
            </>
        )
    }
}

const mapStateToProps = (state) => {
    return {
        testsResult: state.testsResult
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTestsResultStart: (data) => dispatch(getTestsResultStart(data)),
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestResults);
