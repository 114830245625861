import { fetchGraphMethod, fetchMethod } from "../../../Services/httpService";
import messagePopup from "../../../Services/messagePopupService";
import {
  GET_CHAT_FAILED,
  GET_CHAT_SUCCESS,
  SAVE_CHAT_START,
  UPDATE_CHAT_FAILED,
  GET_CHAT_START,
} from "../types";

export const removeChatData = () => (dispatch) => {
  dispatch({
    type: GET_CHAT_START,
  });
};

export const getChatStart = (data) => async (dispatch) => {
  dispatch({
    type: GET_CHAT_START,
  });
  try {
    const response = await fetchGraphMethod(data.query, data.variables, true);
    if (response.status === 200) {
      if (
        response.data != "" &&
        response.data.data != "" &&
        response.data.data.allChathistorydata != "" &&
        response.data.data.allChathistorydata.Chathistorydata != ""
      ) {
      }
      const allChat = response.data.data.allChathistorydata.Chathistorydata;
      const totalCount = 0;
      dispatch({
        type: GET_CHAT_SUCCESS,
        payload: { chatDetails: allChat, totalCount },
      });
    } else {
      messagePopup("", "Get Doctors List failed", "error");
      dispatch({
        type: GET_CHAT_FAILED,
        payload: "Get Doctors List failed",
      });
    }
  } catch (error) {
    messagePopup("", "Get Doctors List failed", "error");
    dispatch({
      type: GET_CHAT_FAILED,
      payload: "Get Doctors List failed",
    });
  }
};

export const saveChatStart = (data) => async (dispatch) => {
  dispatch({
    type: SAVE_CHAT_START,
  });
  try {
    const { query, variables } = data;
    let userroomname = variables.obj.userroomname
    let usermessage = variables.obj.usermessage
    const response = await fetchGraphMethod(query, variables, true);
    if (response.status === 200) {
        const mailResponse = await fetchMethod(`/sendMailToDoctorForMassage?userroomname=${userroomname}&usermessage=${usermessage}`, null, 'post', true);
      // if (
      //   response.data &&
      //   response.data.response &&
      //   response.data.response.status === 200
      // ) {
      //   messagePopup("", "Doctor Added successfully", "success");
      // }
    } else {
      messagePopup("", "Message save failed", "error");
      dispatch({
        type: UPDATE_CHAT_FAILED,
        payload: "Message save failed",
      });
    }
  } catch (error) {
    messagePopup("", "Message save failed", "error");
    dispatch({
      type: UPDATE_CHAT_FAILED,
      payload: "Message save failed",
    });
  }
};
