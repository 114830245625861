import { applyMiddleware } from 'redux'
import { configureStore as createStore } from '@reduxjs/toolkit'
import { composeWithDevTools } from 'redux-devtools-extension'
// import logger from 'redux-logger'
import thunk from 'redux-thunk'

import rootReducer from './Redux/reducers'

const store = createStore({
  reducer: rootReducer,
  middleware: [thunk],
}
)

export default store