import React, { Component } from 'react';
import "../../assets/css/aboutus.scss";
import missionSvg from "../../assets/img/mission.svg";
import bloggingPng from "../../assets/img/blogging.png";
import cofounderPng from "../../assets/img/cofounder.png";
import {NavLink} from 'react-router-dom';


class About extends Component {
  render() {
    return (

        <div className= "pageWrap" style={{marginTop: '85px'}}>
          <main className="aboutus">
              {/* <!-- breadcumb starts here -->	 */}
            <div className="breadcumb">
                  <div className="container">
                      <ul>
                          <li><NavLink to='/'>Home</NavLink></li>
                          <li><NavLink to='/about'>About Us</NavLink></li>
                      </ul>
                  </div>
              </div>

              {/* <!-- mission starts here --> */}
              <section className="mission">
                  <div className="container">
                      <div className="missionwrap">
                          <div className="missiontext">
                              <h1>Our Mission</h1>
                              <h2>To help you become your best self.</h2>                    
                          </div>
                          <div className="missionimg">
                              <img src={missionSvg} alt="mission"/>
                          </div>
                      </div>
                  </div>
              </section>

              {/* <!-- betterhlth starts here --> */}
              <section className="betterhlth">
                  <div className="container">
                      <div className="betterhlthwrap">
                          <div className="betterhlthtext">
                              <p>We want you to take the next step towards better health. If you're curious about your wellbeing you're in the right place.</p>
                          </div>
                          <div className="betterhlthimg">
                              <img src={bloggingPng} alt="blogging"/>
                          </div>
                      </div>
                  </div>
              </section>

              {/* <!-- cofounder starts here --> */}
              <section className="cofounder">
                  <div className="container">
                      <div className="cofounderwrap">
                          {/* <div className="cofounderimg">
                              <img src={cofounderPng} alt="cofounder"/>
                          </div> */}
                          <div className="founderinfo">
                              <h3>Note from our founder Divaksha:</h3>
                              <p>I am extremely passionate about wellbeing. I have suffered with an autoimmune for the last 16 years. I tried and tested many different things and my journey has set me on this path. I believe if we are aware of what's happening in our bodies we can do our best to improve our health and wellbeing. We have 3 homes: our body, mind and soul. It is important to pay attention to all.</p>
                              <p>It's never too late to take the step and take care of your health. Thulsee is a tool available to you at any time & anywhere. Be proactive, take care of you.</p>
                          </div>
                      </div>
                  </div>
              </section>
          </main>
        </div>

    );
  }
}

export default About;