import { BOOK_APPOINTMENT_FAILED, BOOK_APPOINTMENT_START, GET_APPOINTMENTS_FAILED, GET_APPOINTMENTS_START, GET_APPOINTMENTS_SUCCESS, REQUEST_APPOINTMENT_FAILED, REQUEST_APPOINTMENT_START, CANCEL_APPOINTMENT_START, CANCEL_APPOINTMENT_SUCCESS, CANCEL_APPOINTMENT_FAILED, GET_DOCTOR_SLOTS_START, GET_DOCTOR_SLOTS_FAILED, GET_DOCTOR_SLOTS_SUCCESS, REDIRECT_TO_PAYMENT_PORTAL, GET_LATEST_MESSAGE_START, GET_LATEST_MESSAGE_SUCCESS, GET_LATEST_MESSAGE_FAILED, SHOW_ERROR_TEXT, HIDE_ERROR_TEXT } from '../actions/types';

const initialData = {
    loading: false,
    data: null,
    error: null,
    showErrorText: false,
    doctorSlots: [],
    latestMessage: [],
}


export default function (state = initialData, action) {
    switch (action.type) {
        case GET_APPOINTMENTS_START:
            return {
                ...state,
                loading: true,
            };
        case GET_APPOINTMENTS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            };
        case GET_APPOINTMENTS_FAILED:
            return {
                ...state,
                error: action.payload,
                data: null,
                loading: false
            };
        case REQUEST_APPOINTMENT_START:
            return {
                ...state,
                loading: true,
            };
        case REQUEST_APPOINTMENT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case BOOK_APPOINTMENT_START:
            return {
                ...state,
                loading: true,
            };
        case BOOK_APPOINTMENT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case CANCEL_APPOINTMENT_START:
            return {
                ...state,
                error: action.payload,
                loading: true
            };
        case CANCEL_APPOINTMENT_SUCCESS:
            return {
                ...state,
                loading: false,
                error: null
            };
        case CANCEL_APPOINTMENT_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            };
        case GET_DOCTOR_SLOTS_START:
            return {
                ...state,
                error: action.payload,
                loading: true
            }
        case GET_DOCTOR_SLOTS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false
            }
        case GET_DOCTOR_SLOTS_SUCCESS:
            return {
                ...state,
                error: null,
                loading: false,
                doctorSlots: action.payload
            }
        case REDIRECT_TO_PAYMENT_PORTAL:
            return {
                ...state,
                loading: true,
            }
        case GET_LATEST_MESSAGE_START:
            return {
                ...state,
                loading: true
            }
        case GET_LATEST_MESSAGE_SUCCESS:
            return {
                ...state,
                loading: false,
                latestMessage: action.payload
            }
        case GET_LATEST_MESSAGE_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case SHOW_ERROR_TEXT:
            return {
                ...state,
                showErrorText: true
            }
        case HIDE_ERROR_TEXT:
            return {
                ...state,
                showErrorText: false
            }

        default:
            return { ...state };

    }
}