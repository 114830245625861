import React, { Component } from 'react';
import '../../assets/css/faq.scss';
import SearchArrow from '../../assets/img/searcharrow.svg';
import {NavLink} from 'react-router-dom';

class Faq extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: { 'h1': true },
      text: '',
      sendQuery: []
    }
  }

  handleChange = (e) => { this.setState({text: e.target.value})}

  // Send ypur questions
  sendQueries = (e) => {
      e.preventDefault();
      const newItem = {
          text: this.state.text,
      }

      this.setState({
          sendQuery: this.state.sendQuery.concat(newItem)
      })
      
  }

  handleExpandCollapse = (key) => {
    const {isOpen} = this.state;
    isOpen[key] = isOpen[key] ? false : true;
    this.setState({isOpen});
  }
  
  render() {
    return (
        <div className= "pageWrap" style={{marginTop:"100px"}}>
            <main className="faqpage">
                <section className="faqbanner">
                    {/* <!-- breadcumb starts here -->	 */}
                    <div className="breadcumb">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/faq">FAQ's</NavLink></li>
                            </ul>
                        </div>
                    </div>            
                    <div className="faqsearch">
                        <div className="container">
                            <div className="faqsearchwrap">
                                <h1>How can we help?</h1>
                                    <form onSubmit={this.sendQueries}>
                                        <input type="text" className="form-control" placeholder="Send your question to us" value={this.state.text} onChange={this.handleChange} />                        
                                        <button type="submit"><img src={SearchArrow} alt="searcharrow" style={{width:'35px', height:'32px'}}/></button>
                                    </form>
                                <p>You can also browse the below FAQs to find what you are looking for</p>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <!-- FAQs --> */}
                <section className="faq">
                    <div className="container">
                        <div className="row">
                            <div className="col-12">
                                <h2>Frequently asked questions</h2>   
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div id="faqs">
                                    <h3>Account</h3>
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div id="h1" className={`card-header ${this.state.isOpen['h1'] ? '' : 'collapsed'}`} onClick={() => this.handleExpandCollapse('h1')}>
                                            If you have forgotten your password?
                                        </div>
                                        <div id="q1" className={`collapse ${this.state.isOpen['h1'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h2'] ? '' : 'collapsed'}`} id="h2" onClick={() => this.handleExpandCollapse('h2')}>
                                                Wrong email address when setting up account?
                                        </div>
                                        <div id="q2" className={`collapse ${this.state.isOpen['h2'] ? 'show' : ''}`} >
                                            <div className="card-body">
                                                <p><span>ANS:</span> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h3'] ? '' : 'collapsed'}`} id="h3" onClick={() => this.handleExpandCollapse('h3')}>
                                            Issues logging in to account?
                                        </div>
                                        <div id="q3" className={`collapse ${this.state.isOpen['h3'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                            </div>
                                        </div>
                                    </div> 
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h4'] ? '' : 'collapsed'}`} id="h4" onClick={() => this.handleExpandCollapse('h4')}>
                                            How do you keep my data safe?
                                        </div>
                                        <div id="q4" className={`collapse ${this.state.isOpen['h4'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h5'] ? '' : 'collapsed'}`} id="h5" onClick={() => this.handleExpandCollapse('h5')}>
                                            How do I change my account detail?
                                        </div>
                                        <div id="q5" className={`collapse ${this.state.isOpen['h5'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h6'] ? '' : 'collapsed'}`} id="h6" onClick={() => this.handleExpandCollapse('h6')}>
                                            Video link isn't working?
                                        </div>
                                        <div id="q6" className={`collapse ${this.state.isOpen['h6'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span> Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- heading --> */}
                                    <h3>Delivery</h3>
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h7'] ? '' : 'collapsed'}`} id="h7" onClick={() => this.handleExpandCollapse('h7')}>
                                            Where is my kit?
                                        </div>
                                        <div id="q7" className={`collapse ${this.state.isOpen['h7'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span>Your test kits are delivered by our service provider Aramex. All deliveries will be done within 3 -5 days. If you have not received your test kit , please contact us on <NavLink to="mailto:info@thulsee.com">info@thulsee.com</NavLink></p>
                                            </div>
                                        </div>
                                    </div>

                                    {/* <!-- heading --> */}
                                    <h3>Test kit</h3>
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h8'] ? '' : 'collapsed'}`} id="h8" onClick={() => this.handleExpandCollapse('h8')}>
                                            What do I receive in my test kit?
                                        </div>
                                        <div id="q8" className={`collapse ${this.state.isOpen['h8'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span>Your test kits are delivered by our service provider Aramex. All deliveries will be done within 3 -5 days. If you have not received your test kit , please contact us on <NavLink to="mailto:info@thulsee.com">info@thulsee.com</NavLink></p>
                                            </div>
                                        </div>
                                    </div>    
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h9'] ? '' : 'collapsed'}`} id="h9" onClick={() => this.handleExpandCollapse('h9')}>
                                            What do I do with my lancets once I've used them?
                                        </div>
                                        <div id="q9" className={`collapse ${this.state.isOpen['h9'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span>Your test kits are delivered by our service provider Aramex. All deliveries will be done within 3 -5 days. If you have not received your test kit , please contact us on <NavLink to="mailto:info@thulsee.com">info@thulsee.com</NavLink></p>
                                            </div>
                                        </div>
                                    </div>
                                    {/* <!-- question --> */}
                                    <div className="card">
                                        <div className={`card-header ${this.state.isOpen['h10'] ? '' : 'collapsed'}`} id="h10" onClick={() => this.handleExpandCollapse('h10')}>
                                            I didn’t get results for all of the tests. What do I do about this?
                                        </div>
                                        <div id="q10" className={`collapse ${this.state.isOpen['h10'] ? 'show' : ''}`}>
                                            <div className="card-body">
                                                <p><span>ANS:</span>Your test kits are delivered by our service provider Aramex. All deliveries will be done within 3 -5 days. If you have not received your test kit , please contact us on <NavLink to="mailto:info@thulsee.com">info@thulsee.com</NavLink></p>
                                            </div>
                                        </div>
                                    </div>     
                                </div>  
                            </div>
                        </div>     
                    </div>
                </section>
            </main>       
        </div> 
    );
  }
}

export default Faq;