export const getAllOrderHistoryQuery = `
query($where:JSON){
  allPayments(where:$where){
    Payments{
      paymentorderidmaprel{
        Orders{
            id
            orderstatus
            orderdate
            totalamount
            userid
            active
            shipmentid
            testkitdelivertouser
            nursename
            orderProductDetailsOrderIdMap{
              Orderproductdetails{
                quantity
                testid
                orderid
                orderproductdetailstestidrel{
                  Tests{
                    id
                    testname
                    description
                    price
                  }
                }
              }
            }
            sampleDetailsOrderIdMap{
              Sampledetails{
                shipmentid
                active
              }
            }
            orderdeliverslotidmaprel{
              Doctorslots{
                id
                slotsdate
                slotsstarttime
                slotsendtime
                nursename
              }
            }
        }
      }
      paymentappointmentidmaprel{
        Appointments{
          id
          createdon
          acceptstatus
          paymentAppointmentIdMap{
            Payments{
              amount
            }
          }
          appointmentslotsidmaprel{
        Doctorslots{
          slotsdate
          slotsstarttime
          slotsendtime
        }
      }
        appointmentdoctoruseridmaprel{
        Userdata{
          firstname
          lastname
        }
      }
        }
      }
    }
    
  }
}
`