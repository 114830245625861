import { fetchGraphMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { UPDATE_ACCOUNT_START,UPDATE_ACCOUNT_SUCCESS,UPDATE_ACCOUNT_FAILED, GET_SHIPPING_ADDRESS_FAILED, GET_SHIPPING_ADDRESS_START, GET_SHIPPING_ADDRESS_SUCCESS, GET_USER_INFO_SUCCESS, GET_BILLING_ADDRESS_SUCCESS, GET_BILLING_ADDRESS_START, GET_BILLING_ADDRESS_FAILED } from '../types';

export const uploadProfileImageStart = () => dispatch => {
    dispatch({
        type: UPDATE_ACCOUNT_START
    }) 
}

export const uploadProfileImageFailed = () => dispatch => {
    messagePopup('','Image Upload Failed','error')
    dispatch({
        type: UPDATE_ACCOUNT_FAILED,
        payload: 'Image Upload Failed'
    })
}

export const updateUserAccount = (data) => async dispatch => {
    try {
     dispatch({
       type: UPDATE_ACCOUNT_START
     }) 
     const {email,...restdata} = data.data.personalInfo
     const response = await fetchGraphMethod(data.query.personalInfo,{obj: restdata},true);
     if(response && response.status === 200){
        const response1 = await fetchGraphMethod(data.query.shippingAddress,{obj: data.data.shippingAddress},true);
        if(response1 && response1.status === 200){
            const response2 = await fetchGraphMethod(data.query.billingAddress,{obj: data.data.billingAddress},true);
            if(response2 && response2.status === 200){
                messagePopup('','Profile Updated Successfully','success')
                dispatch({
                    type: GET_SHIPPING_ADDRESS_SUCCESS,
                    payload: response1.data.data.saveShippingaddress
                   }) 
                dispatch({
                    type: GET_BILLING_ADDRESS_SUCCESS,
                    payload: response2.data.data.saveBillingaddress
                   }) 
                dispatch({
                    type: GET_USER_INFO_SUCCESS,
                    payload: {...response.data.data.saveUserdata,email: email}
                })
                dispatch({
                  type: UPDATE_ACCOUNT_SUCCESS,
                  payload: 'Profile updated successfully'
                })
            }
        } else {
            messagePopup('','Update Profile Failed','error')
            dispatch({
              type: UPDATE_ACCOUNT_FAILED,
              payload: 'Update Profile Failed'
            }) 
        }
     } else {
        messagePopup('','Update Profile Failed','error')
        dispatch({
          type: UPDATE_ACCOUNT_FAILED,
          payload: 'Update Profile Failed'
        })
     }
    } catch (error) {
     messagePopup('','Update Profile Failed','error')
     dispatch({
       type: UPDATE_ACCOUNT_FAILED,
       payload: 'Update Profile Failed'
     })
    } 
 };

export const getShippingAddress = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_SHIPPING_ADDRESS_START
        })
        const response = await fetchGraphMethod(data.query,data.variables,true);
        if(response.status === 200) {
           const address = response.data.data.allShippingaddresses.Shippingaddresses[0];
           dispatch({
            type: GET_SHIPPING_ADDRESS_SUCCESS,
            payload: address
           }) 
        } else {
            messagePopup('','Get Shipping Address Falied','error');
            dispatch({
                type: GET_SHIPPING_ADDRESS_FAILED,
                payload: 'Test List Failed'
            }) 
        }
    } catch (error) {
        messagePopup('','Get Shipping Address Falied','error');
        dispatch({
            type: GET_SHIPPING_ADDRESS_FAILED,
            payload: 'Test List Failed'
        })
    }
};

export const getBillingAddress = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_BILLING_ADDRESS_START
        })
        const response = await fetchGraphMethod(data.query,data.variables,true);
        if(response.status === 200) {
           const address = response.data.data.allBillingaddresses.Billingaddresses[0];
           dispatch({
            type: GET_BILLING_ADDRESS_SUCCESS,
            payload: address
           }) 
        } else {
            messagePopup('','Get Billing Address Falied','error');
            dispatch({
                type: GET_BILLING_ADDRESS_FAILED,
                payload: 'Test List Failed'
            }) 
        }
    } catch (error) {
        messagePopup('','Get Billing Address Falied','error');
        dispatch({
            type: GET_BILLING_ADDRESS_FAILED,
            payload: 'Test List Failed'
        })
    }
};