import React, { Component } from 'react';
import {NavLink} from "react-router-dom";


class Legal extends Component {
  render() {
    return (
        <div className= "pageWrap" style={{marginTop:"85px"}}>
            {/*
            <div style={{marginTop:"200px"}}><h1>Legal</h1>
        <p>Magna ad exercitation occaecat consequat anim proident esse. Qui culpa et quis fugiat pariatur quis cillum. Quis velit consequat minim enim dolore fugiat velit minim est nostrud incididunt ex eu incididunt. Id ipsum est Lorem id cillum nulla velit id cillum duis ad labore. Dolor exercitation duis incididunt ea id voluptate nisi ea tempor irure quis proident commodo. Aliqua minim nulla dolore laboris pariatur duis ex culpa. Labore eu adipisicing mollit sit reprehenderit minim exercitation aliqua mollit.</p></div>
            */}
            <main className="otherPage">
                <div className="breadcumb">
                    <div className="container">
                        <ul>
                            <li><NavLink to="/">Home</NavLink></li>
                            <li><NavLink to="/legal">Legal</NavLink></li>
                        </ul>
                    </div>
                </div>

                <section className="checkout">
                    <div className="container">
                        <div className="heading">
                            <div className="headshop">
                                <h1>Legal</h1>
                                <p style={{marginTop:"30px"}}>Magna ad exercitation occaecat consequat anim proident esse. Qui culpa et quis fugiat pariatur quis cillum. Quis velit consequat minim enim dolore fugiat velit minim est nostrud incididunt ex eu incididunt. Id ipsum est Lorem id cillum nulla velit id cillum duis ad labore. Dolor exercitation duis incididunt ea id voluptate nisi ea tempor irure quis proident commodo. Aliqua minim nulla dolore laboris pariatur duis ex culpa. Labore eu adipisicing mollit sit reprehenderit minim exercitation aliqua mollit.</p>
                            </div>
                         </div>
                    </div>
                </section>
            </main>
        </div>

    );
  }
}

export default Legal;