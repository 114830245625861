import React from 'react';
import { HashRouter as Router, Route, Switch, Redirect } from "react-router-dom";
import Footer from '../../Common/Footer/footer';
import About from '../About/about';
import How from '../HowItWorks/how';
import Science from '../Science/science';
import Faq from '../Faq/faq';
import Contact from '../Contact/contact';
import Legal from '../Legal/legal';
import Shop from '../ShopAllTests/shop';
import Wellness from '../WellnessTests/wellness';
import Women from '../Women/women';
import Men from '../Men/men';
import Terms from '../Terms/terms';
import Privacy from '../Privacy/privacy';
// import Login from '../../Common/Login/login';
import Home from '../Home/home';
import Notfound from '../Notfound/notfound';
import Header from "./../../Common/Header/header";
import Header2 from "./../../Common/Header/headerLogin";
import ProductDetail from '../ProductDetail/ProductDetail';
import ShoppingCart from '../Shopping-Cart/ShoppingCart';
import Checkout from '../Checkout/Checkout';
import OrderHistory from '../OrderHistory/OrderHistory';
import MyAccount from '../MyAccount/MyAccount';
import ChangePassword from '../../Common/ChangePassword/ChangePassword';
import Appointment from '../Appointment/Appointment';
import Tests from '../Tests/tests';
import TestDetails from '../Tests/testDetails';
import MyProfile from '../MyAccount/MyProfile';
import CreateAppointments from '../Appointment/CreateAppointment';
import Dashboard from '../Home/dashboard';
import TestResults from '../Tests/testResults';
import TestName from '../Tests/testName';
import { getAuthToken } from '../../Services/authService';
import RegisterKit from '../RegisterKit/RegisterKit';
import Chat from '../Chat/Chat'
import Cookie from '../Cookie/cookie';
import InvoiceTemplate from '../OrderHistory/InvoiceTemplate';
import JoinCall from '../Appointment/JoinCall';

class TabRoutes extends React.Component {
  render() {
    // let userAuth;
    // const authToken = getAuthToken();
    // if(!authToken){
    //     userAuth = <Header/>;
    // } else {
    //     userAuth = <Header2/>;
    // }
    return (
      <Router>
        {/* {userAuth} */}
        <Switch>
          {/*             Unauthorised And Authorised Routes                     */}
          <Route exact path="/" name="Home Page" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <Home {...props} />
              </>
            ) : (
              <>
                <Header />
                <Home {...props} />
              </>
            )

          } />
          <Route exact path="/test_details/:id" name="Product Detaisl" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <ProductDetail {...props} />
              </>
            ) : (
              <>
                <Header />
                <ProductDetail {...props} />
              </>
            )

          } />
          <Route exact path="/shopping_cart" name="Shopping Cart" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <ShoppingCart {...props} />
              </>
            ) : (
              <>
                <Header />
                <ShoppingCart {...props} />
              </>
            )

          } />
          <Route exact path="/register-kit" name="Register A Kit" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <RegisterKit {...props} />
              </>
            ) : (
              <>
                <Header />
                <RegisterKit {...props} />
              </>
            )

          } />
          <Route exact path="/shop_all_test" name="Shop All Tests" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Shop {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Shop {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/about" name="about us" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <About {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <About {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/how_it_works" name="How it works" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <How {...props} />
              </>
            ) : (
              // <>
              //   <Header2/>
              //   <How {...props}/>
              // </>
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/science" name="the science" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Science {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Science {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/faq" name="FAQ's" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Faq {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Faq {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/contact" name="Contact Us" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Contact {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Contact {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/legal" name="Legal" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Legal {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Legal {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/wellness_tests" name="Wellness Tests" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Wellness {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Wellness {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/women_health_tests" name="Women Tests" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Women {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Women {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/men_health_tests" name="Men Tests" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Men {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Men {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/terms" name="Terms" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Terms {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Terms {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/privacy" name="Privacy" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Privacy {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Privacy {...props} />
              </>
              // <Redirect to="/"></Redirect>
            )
          } />

          <Route exact path="/cookie" name="cookie" render={(props) =>
            !getAuthToken() ? (
              <>
                <Header />
                <Cookie {...props} />
              </>
            ) : (
              <>
                <Header2 />
                <Cookie {...props} />
              </>
            )
          } />

          {/*===================================================== Authorised Routes ==========================================*/}
          <Route exact path="/ChangePassword" name="Change Password" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <ChangePassword {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/checkout/:id" name="Checkout" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <Checkout {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/OrderHistory" name="Order History" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <OrderHistory {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/OrderHistory" name="Order History" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <OrderHistory {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/MyAccount" name="My Account" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <MyAccount {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/Appointment/:id" name="Appointment" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <Appointment {...props} />
              </>
            ) : (
              <>
                <Header/>
                <Appointment {...props} />
              </>
            )
          } />
          <Route exact path="/dashboard" name="Dashboard" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <Dashboard {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/testResults" name="Test Results" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <TestResults {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/testResults/:id/view" name="Test Results View" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <TestName {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/MyProfile" name="My Profile" render={(props) =>
            getAuthToken() ? (
              <>
                <Header2 />
                <MyProfile {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/Chat" name="Chat" render={(props) =>
            getAuthToken() ? (
              <>
                <Chat {...props} />
              </>
            ) : (
              <Redirect to="/"></Redirect>
            )
          } />
          <Route exact path="/OrderHistory/:orderId/orderInvoice" name="Invoice" render={(props) =>
          getAuthToken() ? (
            <>
              <Header2/>
              <InvoiceTemplate {...props}/>
            </>
          ) : (
            <Redirect to='/'/>
          )
          } />
          <Route exact path="/OrderHistory/:appointmentId/appointmentInvoice" name="Invoice" render={(props) =>
          getAuthToken() ? (
            <>
              <Header2/>
              <InvoiceTemplate {...props}/>
            </>
          ) : (
            <Redirect to='/'/>
          )
          } />
          {/* <Route exact path="/createAppointment" name="Create Appointment" render={(props) => 
                        getAuthToken() ? (
                            <>
                              <Header2/>
                              <CreateAppointments {...props}/>
                            </>
                        ) : (
                          <Redirect to="/"></Redirect>
                        )
                    }/> */}
          <Route exact path="/testDetails" name="TestDetails" render={(props) => <TestDetails {...props} />} />
          {/*  <Route component={Notfound}>
          </Route>*/}
          <Route exact path="/callJoin/:callId/:userId" name="joinCall" render={(props) => <JoinCall {...props} />} />

          <Route render={() =>
            !getAuthToken() ? (
              <>
                <Header />
                <Notfound />
              </>
            ) : (
              <>
                <Header2 />
                <Notfound />
              </>
            )
          } />

          {/* <Route exact path="/how_it_works" name="How it works" render={(props) => <How {...props}/>}/> */}
          {/* <Route exact path="/science" name="the science" render={(props)=> <Science {...props}/>}/> */}
          {/* <Route exact path="/faq" name="FAQ's" render={(props) => <Faq {...props}/> }/> */}
          {/* <Route exact path="/contact" name="contact us" render={(props) => <Contact {...props}/> }/> */}
          {/* <Route exact path="/legal" name="legal" render={(props) => <Legal {...props}/> }/> */}
          {/* <Route exact path="/test_details" name="shop all tests" render={(props) => <Shop {...props}/> }/> */}
          {/* <Route exact path="/test_details/:id" name="product detaisl" render={(props) => <ProductDetail {...props}/> }/> */}
          {/* <Route exact path="/wellness_tests" name="wellness Tests" render={(props) => <Wellness {...props}/> }/> */}
          {/* <Route exact path="/women_health_tests" name="women tests" render={(props) => <Women {...props}/> }/> */}
          {/* <Route exact path="/men_health_tests" name="men tests" render={(props) => <Men {...props}/> }/> */}
          {/* <Route exact path="/terms" name="Terms" render={(props) => <Terms {...props}/> }/> */}
          {/* <Route exact path="/privacy" name="Privacy" render={(props) => <Privacy {...props}/> }/> */}
          {/* <Route exact path="/login" name="Login" render={(props) => <Login {...props}/> }/> */}
          {/* <Route exact path="/ChangePassword" name="Change Password" render={(props) => <ChangePassword {...props}/> }/> */}
          {/* <Route exact path="/shopping_cart" name="Shopping Cart" render={(props) => <ShoppingCart {...props}/> }/> */}
          {/* <Route exact path="/checkout" name="Checkout" render={(props) => <Checkout {...props}/> }/> */}
          {/* <Route exact path="/OrderHistory" name="Order History" render={(props) => <OrderHistory {...props}/> }/> */}
          {/* <Route exact path="/MyAccount" name="My Account" render={(props) => <MyAccount {...props}/> }/> */}
          {/* <Route exact path="/Appointment" name="Appointment" render={(props) => <Appointment {...props}/> }/> */}
          {/* <Route exact path="/tests" name="Tests" render={(props) => <Tests {...props}/> }/> */}
          {/* <Route exact path="/MyProfile" name="MyProfile" render={(props) => <MyProfile {...props}/> }/> */}
          {/* <Route exact path="/CreateAppointment" name="CreateAppointments" render={(props) => <CreateAppointments {...props}/> }/> */}
          {/* <Route exact path="/dashboard" name="Dashboard" render={(props) => <Dashboard {...props}/> }/> */}
          {/* <Route exact path="/testResults" name="TestResults" render={(props) => <TestResults {...props}/> }/> */}
          {/* <Route exact path="/view" name="TestName" render={(props) => <TestName {...props}/> }/> */}
          {/* <Route exact path="/register-kit" name="Register A Kit" render={(props) => <RegisterKit {...props}/> }/> */}

        </Switch>
        <Footer />
      </Router>
    )
  }
}
export default TabRoutes;