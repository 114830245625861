import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
import '../../assets/css/test-name.scss';
import testNameIcon from '../../assets/img/test-name-graph.jpg';
import { getTestResult } from '../../Redux/actions/Tests/testResult';
import { ddmmyyDateFormat } from '../../Services/dateTimeFormatter';
import AlmondReactivity from './AlmondReactivity';
import FSHMeasure from './FSHMeasure';
import { getTestResultQuery } from './testsQuery';

const colorScheme = {
  optimal: `#4CBB17`,
  high: '#FF5733',
  low: '#FFC300',
  subOptimal: 'lightBlue',
}

function TestName(props) {
  const [statusColour, setStatusColour] = useState('black')
  useEffect(() => {
    let id = window.location.href.split("testResults/")[1].split('/')[0]
    const variables = {
      where: {
        reportid: id
      }
    }
    props.getTestResult({ query: getTestResultQuery, variables })
  }, [])

  const calcStatus = () => {
    if (props.testResult.data?.testResult.testparametervalue >= props.testResult.data?.testParameter.optimumvalue.split('-')[0] && props.testResult.data?.testResult.testparametervalue <= props.testResult.data?.testParameter.optimumvalue.split('-')[1]) {
      return 'optimal'
    }
    else if (props.testResult.data?.testResult.testparametervalue <= props.testResult.data?.testParameter.lowvalue) {
      return 'Low'
    }
    else if (props.testResult.data?.testResult.testparametervalue >= props.testResult.data?.testParameter.highvalue) {
      return 'High'
    }
    else if ((props.testResult.data?.testResult.testparametervalue > props.testResult.data?.testParameter.optimumvalue.split('-')[1] && props.testResult.data?.testResult.testparametervalue < props.testResult.data?.testParameter.highvalue) ||
      (props.testResult.data?.testResult.testparametervalue < props.testResult.data?.testParameter.optimumvalue.split('-')[1] && props.testResult.data?.testResult.testparametervalue > props.testResult.data?.testParameter.lowvalue)) {
      return 'Sub-optimal'
    }
  }
  const calcColor = () => {
    if (calcStatus() === 'optimal') {
      return (colorScheme.optimal)
    }
    else if (calcStatus() === 'Low') {
      return (colorScheme.low)
    }
    else if (calcStatus() === 'High') {
      return (colorScheme.high)
    }
    else if (calcStatus() === 'Sub-optimal') {
      return (colorScheme.subOptimal)
    }

  }
  return (
    <>
      <main className="testnamepage">
        <section className="breadcumb">
          <div className="container">
            <ul>
              <li><NavLink to="/dashboard">Dashboard</NavLink></li>
              <li><NavLink to="#">{props.testResult.data?.testParameter.testparametertestidmaprel.Tests[0].testname}</NavLink></li>
            </ul>
          </div>
        </section>

        <section className="testwrapp">
          <div className="container">
            <div className="flex">
              {/* <div className="header"><h1>{props.testResult.data?.testParameter.testparametertestidmaprel.Tests[0].testname}</h1></div> */}
              {/* <div><img src={testNameIcon} alt="svg" /></div> */}
              <div className="testnamegraph">
                <div className="row testFlexBox">
                  <div className="col-sm-8">
                    <FSHMeasure result={
                      props.testResult.data?.testResult.testparametervalue}
                      parameter={props.testResult.data?.testParameter}
                      marks={[{
                        value: 0,
                        label: '0'
                      },
                      {
                        value: props.testResult.data?.testParameter.highvalue > props.testResult.data?.testResult.testparametervalue ? props.testResult.data?.testParameter.highvalue : props.testResult.data?.testResult.testparametervalue,
                        label: `${props.testResult.data?.testParameter.highvalue > props.testResult.data?.testResult.testparametervalue ? props.testResult.data?.testParameter.highvalue : props.testResult.data?.testResult.testparametervalue}`
                      },
                      {
                        value: props.testResult.data?.testResult.testparametervalue,
                      }]}
                      valueColor={calcColor()}
                    />
                  </div>
                  <div className="col-sm-4 dataFlexBox">
                    <div className="data">
                      <div className='dataFlex'>
                        <div>
                          <h5 className="title">Test Date : {' '}<b>{ddmmyyDateFormat(props.testResult.data?.testResult.reporttestresultreportidmaprel.Reports[0].reportsampleidmaprel.Sampledetails[0].createdon)}</b></h5>
                          <h5 className="title">Result Date :{' '}
                            <b>{ddmmyyDateFormat(props.testResult.data?.testResult.createdon)}</b></h5>
                        </div>
                      </div>
                      <div className="dataFlex">
                        <div>
                          <h5 className="title">Your Level</h5>
                          <label style={{ color: calcColor() }}>{calcStatus()}</label>
                        </div>
                        <span className="value" style={{ fontWeight: 'bold' }}>{props.testResult.data?.testResult.testparametervalue} {props.testResult.data?.testParameter.measure}</span>
                      </div>
                      <div className="rangeFlex">
                        <label>Optimal Range</label>
                        <span className="value">{props.testResult.data?.testParameter.optimumvalue}</span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
const mapStateToProps = (state) => {
  return {
    testResult: state.result
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTestResult: (data) => dispatch(getTestResult(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(TestName);
