import { GET_KIT_SAMPLE_ID_STATUS_FAILED, GET_KIT_SAMPLE_ID_STATUS_INITIAL, GET_KIT_SAMPLE_ID_STATUS_SUCCESS } from "../actions/types";

const initialData = {
   status: null,
   data: null,
   error: null,
   msg: ''
}

export default function(state = initialData, action) {
    switch(action.type) {
        case GET_KIT_SAMPLE_ID_STATUS_INITIAL: 
            return {
                ...state,
                status: null,
                data: null,
                error: null,
                msg: ''
            }
        case GET_KIT_SAMPLE_ID_STATUS_SUCCESS: 
            return {
                ...state,
                error: null,
                status: action.payload.status,
                data: action.payload.data,
                msg: action.payload.msg
            }
        case GET_KIT_SAMPLE_ID_STATUS_FAILED: 
            return {
                ...state,
                error: action.payload,
                data: null,
                status: null,
                msg: ''
            }
        default: 
            return {
                ...state
            }
    }
}