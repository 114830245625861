export function unixTimestampToDate (UNIX_timestamp) {
    var a = new Date(UNIX_timestamp );
    var months = ['Jan','Feb','Mar','Apr','May','Jun','Jul','Aug','Sep','Oct','Nov','Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    return  (date + ' ' + month + ' ' + year)
  }
  
  // Both gives same time
  export function unixTimestampToTime (UNIX_timestamp) {
    var a = new Date(UNIX_timestamp);
    var hour = a.getHours();
    var min = a.getMinutes();
    var sec = a.getSeconds();
    return  (hour + ':' + min + ':' + sec)
  }

  export const getColonSlotFormatedDate = (date) => {
    console.log("pppppp",date);
    const tempdate = new Date(date);
    let currentdate = tempdate.getDate();
    let month = tempdate.getMonth() + 1;
    let year = tempdate.getFullYear();
    currentdate = currentdate < 10 ? `0${currentdate}` : currentdate;
    month = month < 10 ? `0${month}` : month;
    return `${year}-${month}-${currentdate}`;
  };
  export const ddmmyyDateFormat = (date) => {
    console.log("dateeeeeee",date);
    const tempdate = new Date(date);;
    console.log("tempdate",tempdate);
    let currentdate = tempdate.getDate();
    console.log("currentdate",currentdate);
    let month = tempdate.getMonth() + 1;
    console.log("month",month);
    let year = tempdate.getFullYear();
    console.log("year",year);
    currentdate = currentdate < 10 ? `0${currentdate}` : currentdate;
    console.log("currentdate",currentdate);
    month = month < 10 ? `0${month}` : month;
    console.log("dateeeeeeeeeeeeeeeeeee",`${currentdate}-${month}-${year}`);
    return `${currentdate}-${month}-${year}`;
  };
  export const getColonSlotFormatedTime = (time) => {
    console.log("timeeeeeeeeaaaaaaaaaaaaaaaa",time)
    let timeArr = time.split(":");
    console.log(` timeArr[0] + ":" + timeArr[1]`, timeArr[0] + ":" + timeArr[1]);
    return timeArr[0] + ":" + timeArr[1];
  };

  export const getSlotLocalDateTime = (date,time,flag) => {
    console.log("11111111111111",date,time,flag)
    const formattedDate = getColonSlotFormatedDate(date);
    console.log("formattedDate",formattedDate)
    const utcFormattedDate = formattedDate + "T" + time + '.000Z'
    const localFormattedDate = new Date(utcFormattedDate).toLocaleString([], { hour12: false});
    console.log("----------->",localFormattedDate);
    if(flag === 'time') {
      console.log("timeeeeeeeeeeee",localFormattedDate.split(', ')[1]);
       return localFormattedDate.split(', ')[1]
    } 
    if(flag === 'date') {
        return formattedDate
    }
  }

  export const currentDateDbFormate = () => {
    let createdOnDate = new Date().toISOString().replace("T"," ");
    return createdOnDate.split(".")[0];
  }

export default {
    unixTimestampToDate,
    unixTimestampToTime,
    getColonSlotFormatedDate,
    getSlotLocalDateTime,
    currentDateDbFormate,
    ddmmyyDateFormat,
    getColonSlotFormatedTime
}  