import {combineReducers} from 'redux';
import loginReducer from './login';
import signupReducer from './signup';
import resetPasswordReducer from'./resetPassword';
import forgotPasswordReducer from './forgotPassword';
import changePasswordReducer from './changePassword'

export default combineReducers({
   login: loginReducer,
   signup: signupReducer,
   resetPassword: resetPasswordReducer,
   forgotPassword: forgotPasswordReducer,
   changePassword: changePasswordReducer
})