import React, { Component } from 'react'
import CustomeSlider from './CustomSlider'

const marks1 = [
    {
      value: 0,
      label: 'Normal'
    },
    {
      value: 17,
      label: '17'
    }
  ];
const marks2 = [
    {
      value: 18,
    },
    {
      value: 37,
      label: 'Mild'
    },
    {
      value: 58,
      label: '58'
    }
  ];

const marks3 = [
    {
      value: 59,
    },
    {
      value: 87,
      label: 'Moderate'
    },
    {
      value: 116,
      label: '116'
    }
  ];
const marks4 = [
    {
      value: 117,
    },
    {
      value: 174,
      label: 'High'
    }
  ];

export default class AlmondReactivity extends Component {
    constructor(props) {
        super(props);
        this.state = {
            firstValue: null,
            secondValue: null,
            thirdValue: null,
            fourthValue: null 
        }
    }
    componentDidMount = () => {
        this.setState({firstValue: 11})
    }
    render() {
        return (
            <div className="progreessBarFlexBox">
              <div className="titleBox">
                <h4 className="title">Almond reactivity result</h4>
                {/* <h5 className="subtitle">Measure in</h5> */}
              </div>
              <div className="row">
                <div className="col-sm-3" style={{paddingRight: '0'}}>
                    <CustomeSlider 
                        value={this.state.firstValue} 
                        valueLabelDisplay={!this.state.firstValue ? 'off' : 'on'} 
                        thumbbackgroundcolor={!this.state.firstValue ? "transparent" : "#fff"}
                        thumbborder={!this.state.firstValue ? '0px solid currentColor' : '2px solid currentColor'}
                        marks={marks1} 
                        min={0}
                        max={17}
                        slidercolor='#7fe69e'
                    />
                </div>
                <div className="col-sm-3" style={{paddingLeft: '0',paddingRight: '0'}}>
                    <CustomeSlider 
                        valueLabelDisplay={!this.state.secondValue ? 'off' : 'on'} 
                        value={this.state.secondValue} 
                        thumbbackgroundcolor={!this.state.secondValue ? "transparent" : "#fff"}
                        thumbborder={!this.state.secondValue ? '0px solid currentColor' : '2px solid currentColor'}
                        min={18} 
                        max={58}
                        slidercolor='#6c63ff'
                        marks={marks2} 
                    />
                </div>
                <div className="col-sm-3" style={{paddingLeft: '0',paddingRight: '0'}}>
                    <CustomeSlider 
                        valueLabelDisplay={!this.state.thirdValue ? 'off' : 'on'} 
                        value={this.state.thirdValue} 
                        thumbbackgroundcolor={!this.state.thirdValue ? "transparent" : "#fff"}
                        thumbborder={!this.state.thirdValue ? '0px solid currentColor' : '2px solid currentColor'}
                        min={59} 
                        max={116}
                        slidercolor='#fdc661'
                        marks={marks3} 
                    />
                </div>
                <div className="col-sm-3" style={{paddingLeft: '0'}}>
                    <CustomeSlider 
                        valueLabelDisplay={!this.state.fourthValue ? 'off' : 'on'} 
                        value={this.state.fourthValue} 
                        thumbbackgroundcolor={!this.state.fourthValue ? "transparent" : "#fff"}
                        thumbborder={!this.state.fourthValue ? '0px solid currentColor' : '2px solid currentColor'}
                        min={117} 
                        max={174}
                        slidercolor='#fe6d73'
                        marks={marks4} 
                    />
                </div>
            </div>  
            </div>
        )
    }
}
