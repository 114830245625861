import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { CHANGE_PASSWORD_FAILED, CHANGE_PASSWORD_START, CHANGE_PASSWORD_SUCCESS } from '../types';
import history from '../../../history'
import { getAuthToken } from '../../../Services/authService';

export const changePasswordStart = (data) => async (dispatch) => {
    dispatch({
        type: CHANGE_PASSWORD_START
    })
    try {
        const accessToken = getAuthToken(); 
        const {oldPassword,newPassword,resetForm} = data;
        const response = await fetchMethod(
            `/api/Userdata/change-password?access_token=${accessToken}`,
            {
               oldPassword,
               newPassword
            },
            'post',
            false
           )
           if(response && response.status === 204){
           resetForm()
           messagePopup('', 'Password changed successfully', 'success');
           dispatch({
            type: CHANGE_PASSWORD_SUCCESS,
            payload: 'Password changed successfully'
           })
        } else {
           messagePopup('', 'Invalid current password', 'error');
           dispatch({
            type: CHANGE_PASSWORD_FAILED,
            payload: 'Invalid current password'
          })
        }
       
      } catch (error) {
         messagePopup('','Invalid current password','error')   
         dispatch({
            type: CHANGE_PASSWORD_FAILED,
            payload: 'Invalid current password'
          }) 
    }
    
};