export const SHOPPING_GET_DATA = 'SHOPPING_GET_DATA';
export const ADD_DATA = 'ADD_DATA';

export const PATIENTSTEST = 'PATIENTSTEST';
export const ORDERS = 'ORDERS';

/* REGISTER KIT ACTION TYPE  */
export const SAVE_REGISTER_KIT_START = 'SAVE_REGISTER_KIT_START';
export const SAVE_REGISTER_KIT_SUCCESS = 'SAVE_REGISTER_KIT_SUCCESS';
export const SAVE_REGISTER_KIT_FAILED = 'SAVE_REGISTER_KIT_FAILED';

export const GET_KIT_SAMPLE_ID_STATUS_INITIAL = 'GET_KIT_SAMPLE_ID_STATUS_INITIAL';
export const GET_KIT_SAMPLE_ID_STATUS_SUCCESS = 'GET_KIT_SAMPLE_ID_STATUS_SUCCESS';
export const GET_KIT_SAMPLE_ID_STATUS_FAILED = 'GET_KIT_SAMPLE_ID_STATUS_FAILED';

/* USER INFO ACTION TYPE  */
export const GET_USER_INFO_START = 'GET_USER_INFO_START';
export const GET_USER_INFO_SUCCESS = 'GET_USER_INFO_SUCCESS';
export const GET_USER_INFO_FAILED = 'GET_USER_INFO_FAILED';

/* TEST ACTION */
export const GET_TESTS_START = 'GET_TESTS_START';
export const GET_TESTS_SUCCESS = 'GET_TESTS_SUCCESS';
export const GET_TESTS_FAILED = 'GET_TESTS_FAILED';

/* Category Based TEST ACTION */
export const GET_CATEGORY_TESTS_START = 'GET_CATEGORY_TESTS_START';
export const GET_CATEGORY_TESTS_SUCCESS = 'GET_CATEGORY_TESTS_SUCCESS';
export const GET_CATEGORY_TESTS_FAILED = 'GET_CATEGORY_TESTS_FAILED';

/* TEST Details ACTION */
export const GET_TEST_START = 'GET_TEST_START';
export const GET_TEST_SUCCESS = 'GET_TEST_SUCCESS';
export const GET_TEST_FAILED = 'GET_TEST_FAILED';


/* SHOPPING CART ACTION */
export const GET_SHOPPING_CART_START = 'GET_SHOPPING_CART_START';
export const GET_SHOPPING_CART_SUCCESS = 'GET_SHOPPING_CART_SUCCESS';
export const GET_SHOPPING_CART_FAILED = 'GET_SHOPPING_CART_FAILED';

export const GET_CART_TOTAL_COUNT = 'GET_CART_TOTAL_COUNT';

export const ADD_SHOPPING_CART_START = 'ADD_SHOPPING_CART_START';
export const ADD_SHOPPING_CART_SUCCESS = 'ADD_SHOPPING_CART_SUCCESS';
export const ADD_SHOPPING_CART_FAILED = 'ADD_SHOPPING_CART_FAILED';

/* CHECKOUT ACTION */
export const SAVE_CHECKOUT_START = 'SAVE_CHECKOUT_START';
export const SAVE_CHECKOUT_SUCCESS = 'SAVE_CHECKOUT_SUCCESS';
export const SAVE_CHECKOUT_FAILED = 'SAVE_CHECKOUT_FAILED';

/* SHIPPING ADDRESS ACTION */
export const GET_SHIPPING_ADDRESS_START = 'GET_SHIPPING_ADDRESS_START';
export const GET_SHIPPING_ADDRESS_SUCCESS = 'GET_SHIPPING_ADDRESS_SUCCESS';
export const GET_SHIPPING_ADDRESS_FAILED = 'GET_SHIPPING_ADDRESS_FAILED';

/* BILLING ADDRESS ACTION */
export const GET_BILLING_ADDRESS_START = 'GET_BILLING_ADDRESS_START';
export const GET_BILLING_ADDRESS_SUCCESS = 'GET_BILLING_ADDRESS_SUCCESS';
export const GET_BILLING_ADDRESS_FAILED = 'GET_BILLING_ADDRESS_FAILED';

/* MY PROFILE ACTION */
export const UPDATE_ACCOUNT_START = 'UPDATE_ACCOUNT_START';
export const UPDATE_ACCOUNT_SUCCESS = 'UPDATE_ACCOUNT_SUCCESS';
export const UPDATE_ACCOUNT_FAILED = 'UPDATE_ACCOUNT_FAILED';

/* ORDER HISTORY ACTION */
export const GET_ORDER_HISTORY_START = 'GET_ORDER_HISTORY_START';
export const GET_ORDER_HISTORY_SUCCESS = 'GET_ORDER_HISTORY_SUCCESS';
export const GET_ORDER_HISTORY_FAILED = 'GET_ORDER_HISTORY_FAILED'

/*  AUTH ACTIONS    */
export const LOGIN_START = 'LOGIN_START';
export const LOGIN_SUCCESS = 'LOGIN_SUCCESS';
export const LOGIN_FAILED = 'LOGIN_FAILED';
export const OTP_PHASE = 'OTP_PHASE';
export const BACK_TO_LOGIN = 'BACK_TO_LOGIN';

export const SIGNUP_START = 'SIGNUP_START';
export const SIGNUP_SUCCESS = 'SIGNUP_SUCCESS';
export const SIGNUP_FAILED = 'SIGNUP_FAILED';

export const RESET_PASSWORD_START = 'RESET_PASSWORD_START';
export const RESET_PASSWORD_SUCCESS = 'RESET_PASSWORD_SUCCESS';
export const RESET_PASSWORD_FAILED = 'RESET_PASSWORD_FAILED';

export const FORGOT_PASSWORD_START = 'FORGOT_PASSWORD_START';
export const FORGOT_PASSWORD_SUCCESS = 'FORGOT_PASSWORD_SUCCESS';
export const FORGOT_PASSWORD_FAILED = 'FORGOT_PASSWORD_FAILED';

export const CHANGE_PASSWORD_START = 'CHANGE_PASSWORD_START';
export const CHANGE_PASSWORD_SUCCESS = 'CHANGE_PASSWORD_SUCCESS';
export const CHANGE_PASSWORD_FAILED = 'CHANGE_PASSWORD_FAILED';

/* TESTS RESULTS ACTION */
export const GET_TESTS_RESULT_START = 'GET_TESTS_RESULT_START';
export const GET_TESTS_RESULT_SUCCESS = 'GET_TESTS_RESULT_SUCCESS';
export const GET_TESTS_RESULT_FAILED = 'GET_TESTS_RESULT_FAILED';
export const GET_TEST_RESULT_START = `GET_TEST_RESULT_START`;
export const GET_TEST_RESULT_SUCCESS = `GET_TEST_RESULT_SUCCESS`;
export const GET_TEST_RESULT_FAILED = `GET_TEST_RESULT_FAILED`;

/* CONTACT US ACTION */
export const SAVE_CONTACT_US_START = 'SAVE_CONTACT_US_START';
export const SAVE_CONTACT_US_SUCCESS = 'SAVE_CONTACT_US_SUCCESS';
export const SAVE_CONTACT_US_FAILED = 'SAVE_CONTACT_US_FAILED'

/* APPOINTMENTS ACTION */
export const GET_APPOINTMENTS_START = 'GET_APPOINTMENTS_START';
export const GET_APPOINTMENTS_SUCCESS = 'GET_APPOINTMENTS_SUCCESS';
export const GET_APPOINTMENTS_FAILED = 'GET_APPOINTMENTS_FAILED';
export const REQUEST_APPOINTMENT_START = 'REQUEST_APPOINTMENT_START';
export const GET_DOCTOR_SLOTS_START = 'GET_DOCTOR_SLOTS_START';
export const GET_DOCTOR_SLOTS_FAILED = 'GET_DOCTOR_SLOTS_FAILED';
export const GET_DOCTOR_SLOTS_SUCCESS = 'GET_DOCTOR_SLOTS_SUCCESS';
export const REDIRECT_TO_PAYMENT_PORTAL = 'REDIRECT_TO_PAYMENT_PORTAL';
export const SHOW_ERROR_TEXT = 'SHOW_ERROR_TEXT';
export const HIDE_ERROR_TEXT = 'HIDE_ERROR_TEXT';
// export const REQUEST_APPOINTMENT_SUCCESS = 'REQUEST_APPOINTMENT_SUCCESS';
export const REQUEST_APPOINTMENT_FAILED = 'REQUEST_APPOINTMENT_FAILED';
export const BOOK_APPOINTMENT_START = 'BOOK_APPOINTMENT_START';
// export const BOOK_APPOINTMENT_SUCCESS = 'BOOK_APPOINTMENT_SUCCESS';
export const BOOK_APPOINTMENT_FAILED = 'BOOK_APPOINTMENT_FAILED';
export const CANCEL_APPOINTMENT_START = 'CANCEL_APPOINTMENT_START';
export const CANCEL_APPOINTMENT_SUCCESS = 'CANCEL_APPOINTMENT_SUCCESS';
export const CANCEL_APPOINTMENT_FAILED = 'CANCEL_APPOINTMENT_FAILED';
//get latest message date
export const GET_LATEST_MESSAGE_START = "GET_LATEST_MESSAGE_START";
export const GET_LATEST_MESSAGE_SUCCESS = "GET_LATEST_MESSAGE_SUCCESS";
export const GET_LATEST_MESSAGE_FAILED = "GET_LATEST_MESSAGE_FAILED";


// CHAT ACTION 

export const SAVE_CHAT_START = 'SAVE_CHAT_START'
export const UPDATE_CHAT_START = 'UPDATE_CHAT_START'
export const CANCEL_CHAT_START = 'CANCEL_CHAT_START'
export const UPDATE_CHAT_SUCCESS = 'UPDATE_CHAT_SUCCESS'
export const UPDATE_CHAT_FAILED = 'UPDATE_CHAT_FAILED'
export const GET_CHAT_START = 'GET_CHAT_START'
export const GET_CHAT_SUCCESS = 'GET_CHAT_SUCCESS'
export const GET_CHAT_FAILED = 'GET_CHAT_FAILED'

//Dashboard
export const GET_TEST_LIST_START = 'GET_TEST_LIST'
export const GET_TEST_LIST_FAILED = 'GET_TEST_LIST_FAILED'
export const GET_TEST_LIST_SUCCESS = 'GET_TEST_LIST_SUCCESS'
export const GET_TEST_PARAMETER_START = 'GET_TEST_PARAMETER_START'
export const GET_TEST_PARAMETER_SUCCESS = 'GET_TEST_PARAMETER_SUCCESS'
export const GET_TEST_PARAMETER_FAILED = 'GET_TEST_PARAMETER_FAILED'

//Invoice
export const GET_INVOICE_DETAILS_START = 'GET_INVOICE_DETAILS_START'
export const GET_INVOICE_DETAILS_SUCCESS = 'GET_INVOICE_DETAILS_SUCCESS'
export const GET_INVOICE_DETAILS_FAILED = 'GET_INVOICE_DETAILS_FAILED'
export const GET_APPOINTMENT_INVOICE_DETAILS_START = 'GET_APPOINTMENT_INVOICE_DETAILS_START'
export const GET_APPOINTMENT_INVOICE_DETAILS_SUCCESS = 'GET_APPOINTMENT_INVOICE_DETAILS_SUCCESS'
export const GET_APPOINTMENT_INVOICE_DETAILS_FAILED = 'GET_APPOINTMENT_INVOICE_DETAILS_FAILED'
