import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import { connect } from 'react-redux';
import swal from 'sweetalert';
import axios from 'axios';
import "../../assets/css/shopping-cart.scss";
import closedSvg from "../../assets/img/closed.svg";
// import contactTxturePng from "../../assets/img/contact-txture.png";
import lockSvg from "../../assets/img/lock.svg";
import kitPng from "../../assets/img/kit.png";
import ShoppingItems from '../../dummy/shoppingCart.json';
import CartData from './cardData';
import { addShoppingCartData, decreaseProductQuantity, deleteShoppingCartData, deleteUserShoppingCartData, getUserShoppingCartData, updateUserShoppingCartData } from '../../Redux/actions/Shopping-Cart/actions';
import { getAuthToken, getUserInfo } from '../../Services/authService';
import Login from '../../Common/Login/login';
import { getAllShoppingCartsQuery, saveShoppingCartDataQuery } from './ShoppingCartQuery';
import { getTestListQuery } from '../Home/HomeQuery';
import BackdropLoader from '../../Services/loader';
import { Button } from '@material-ui/core';


class ShoppingCart extends Component {
  constructor(props) {
    super(props);
    this.state = {
      isOpen: false,
      items: 0,
      promocode: '',
    }
  }
  handleExpandCollapse = (evt) => {
    evt.preventDefault();
    this.setState(prevState => ({ isOpen: !prevState.isOpen }))
  }

  handleInputChange = (evt) => {
    this.setState({ promocode: evt.target.value })
  }

  handleApplyPromocode = (evt) => {
    evt.preventDefault();
    if (this.state.promocode === 'thulsee2021') {

    }
  }

  handleCheckoutClick = () => {
    this.props.history.push('/checkout/1')
  }

  handleAddItems = () => {
    this.setState({ items: this.state.items + 1 })
  }

  handleDecreaseItems = () => {
    this.setState({ items: this.state.items - 1 })
  }

  handleRemoveItems = () => {
    this.setState({
      mockData: this.state.mockData.filter(item => item.id !== item)
    })
  }


  componentDidMount() {
    // this.props.fetchPosts();
    if (getAuthToken()) {
      const variables = {
        obj: {
          active: 1,
          userid: getUserInfo().id
        }
      }
      this.props.getUserShoppingCartData({
        query: {
          getCartQuery: getAllShoppingCartsQuery,
          getTestQuery: getTestListQuery
        },
        variables
      })
    }
  }

  handleDel = data => {
    swal({
      title: "Are you sure you want to remove this from your cart?",
      text: "",
      icon: "warning",
      buttons: ["No", "Yes"],
      dangerMode: true
    })
      .then((res) => {
        if (res == true) {
          if (data) {
            if (getAuthToken()) {
              const variables = {
                obj: {
                  testid: data.id,
                  userid: getUserInfo().id,
                  id: data.cartId,
                  active: 0
                }
              }
              this.props.deleteUserShoppingCartData({
                query: saveShoppingCartDataQuery,
                variables,
              })

            } else {
              this.props.deleteShoppingCartData(data.id)
            }

          }
        }
      });
  };

  handleDecreaseItems = (data) => {
    if (getAuthToken()) {
      const variables = {
        obj: {
          testid: data.id,
          quantity: `${+data.quantity - 1}`,
          userid: getUserInfo().id,
          id: data.cartId
        }
      }
      this.props.updateUserShoppingCartData({
        query: saveShoppingCartDataQuery,
        variables,
      })

    } else {
      this.props.handleDecreaseProductQuantity(data)
    }
  }

  handleAddItemsClick = (data) => {
    if (getAuthToken()) {
      const variables = {
        obj: {
          testid: data.id,
          quantity: `${+data.quantity + 1}`,
          userid: getUserInfo().id,
          id: data.cartId
        }
      }
      this.props.updateUserShoppingCartData({
        query: saveShoppingCartDataQuery,
        variables,
      })
    } else {
      this.props.addToCart({ ...data, quantity: 1 })
    }
  }

  getTotalPrice = (data) => {
    if (data && data.length) {
      let price = 0;
      for (const item of data) {
        price += (+item.price * +item.quantity)
      }
      return `AED ${price}`
    } else {
      return 0;
    }
  }

  render() {
    return (
      <div className="pageWrap" style={{ marginTop: '85px' }}>
        <main className="shoppingcart">
          {/* <!-- breadcumb starts here -->	 */}
          <div className="breadcumb">
            <div className="container">
              <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/shopping_cart">Shopping Cart</NavLink></li>
              </ul>
            </div>
          </div>
          <BackdropLoader open={this.props.cartDetails.loading} />

          {/* <!-- shopping section start here --> */}
          <section className="shopping">
            <div className="container">
              <div className="heading">
                <div className="headshop"><h1>Shopping Cart</h1></div>
                <div className="server"> <img src={lockSvg} alt="lock" /> Secure Server</div>
              </div>
              {/* <!-- shoppingwrap start here --> */}
              <div className="shoppingwrap">
                <div className="shoppinglist">
                  {this.props.cartDetails.data && this.props.cartDetails.data.map((item, index) => (
                    <div className="flex" key={index}>

                      <div className="column">
                        <div className="productimg"><img src={kitPng} alt="kit" /> </div>
                        <div className="productname">
                          {item.testname}(AED {item.price})
                          {/* <div className="remove"> <NavLink to="/"><img src={trashIconSvg} alt="trash-icon"/>Remove</NavLink></div> */}

                        </div>
                      </div>
                      <div className="column">
                        <div className="quantityprice">
                          <CartData data={item} handleDel={this.handleDel} todo={item} handleDecreaseItems={this.handleDecreaseItems} handleAddItems={this.handleAddItemsClick} />
                        </div>
                      </div>
                    </div>
                  ))}
                </div>

                {/* <!-- ordersummary start here --> */}
                <div className="ordersummary">
                  <h3>Order Summary</h3>
                  {/* {ShoppingItems.map((item, index) => ( */}
                  <div>
                    <div className="summarydetail">
                      <div className="itemtxt">Subtotal</div>
                      <div className="itemprice">{this.getTotalPrice(this.props.cartDetails.data)}</div>
                    </div>
                    <div className="summarydetail">
                      <div className="itemtxt">Shipping</div>
                      <div className="itemprice fontweight600">Free</div>
                    </div>
                    <div className="summarydetail">
                      <div className="itentxt ">Total</div>
                      <div className="itemprice fontweight600">{this.getTotalPrice(this.props.cartDetails.data)}</div>
                    </div>
                    {/* <div className="promowrap">
                                      <NavLink className={`${this.state.isOpen ? '' : 'collapsed'}`} onClick={(e) => this.handleExpandCollapse(e)} to="/" role="button">Add promo code</NavLink>
                                      <div className={`collapse ${this.state.isOpen ? 'show' : ''}`} id="promocode">
                                          <div className="promobody">
                                              <p>use thulsee2021 to get 5% inauguration discount</p>
                                              <input type="text" id="code" name="promocode" placeholder="Code" value={this.state.promocode} onChange={this.handleInputChange}/> 
                                              <NavLink to="/" onClick={this.handleApplyPromocode}>Apply</NavLink>
                                              <img id="promoclosed" src={closedSvg} alt="closed" onClick={(e) => this.handleExpandCollapse(e)}/>
                                          </div>
                                      </div>
                                  </div> */}
                    <div className="summarydetail">
                      <div className="grandtotal fontweight600">Total</div>
                      <div className="itemprice fontweight600">{this.getTotalPrice(this.props.cartDetails.data)}</div>
                    </div>
                    {getAuthToken() ? (
                      <>
                        {this.props.cartDetails && this.props.cartDetails.cartCount ? (
                          <button type="submit" className="btncheckout" onClick={this.handleCheckoutClick}>Checkout</button>
                        ) : (
                          <button type="submit" className="btncheckout" disabled={true}>Checkout</button>
                        )}
                      </>
                    ) : (
                      <Login className="btncheckout" btnType="login" elementType="button" currentRoute="/shopping_cart" nextRoute="/shopping_cart">Checkout</Login>
                    )}

                  </div>
                  {/* ))} */}
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    cartDetails: state.cartDetails
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    addToCart: (data) => dispatch(addShoppingCartData(data)),
    handleDecreaseProductQuantity: (data) => dispatch(decreaseProductQuantity(data)),
    deleteShoppingCartData: (data) => dispatch(deleteShoppingCartData(data)),
    getUserShoppingCartData: (data) => dispatch(getUserShoppingCartData(data)),
    updateUserShoppingCartData: (data) => dispatch(updateUserShoppingCartData(data)),
    deleteUserShoppingCartData: (data) => dispatch(deleteUserShoppingCartData(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ShoppingCart);


