import React from 'react';
import { withStyles } from '@material-ui/core/styles';
import Slider from '@material-ui/core/Slider';

export default function CustomeSlider(props) {
  const PrettoSlider = withStyles({
    root: {
      height: 8,
    },
    thumb: {
      height: 24,
      width: 24,
      backgroundColor: (props) => props.thumbbackgroundcolor,
      border: (props) => props.thumbborder,
      marginTop: -8,
      marginLeft: -12,
      '&:focus, &:hover, &$active': {
        boxShadow: 'inherit',
      },
    },
    active: {},
    valueLabel: {
      left: 'calc(-50% + 4px)',
      color: props.valueColor
    },
    track: {
      display: 'none'
      // height: 8,
      // borderRadius: 4,
      // background: `linear-gradient(to right, #FFC300 50%, #4CBB17 50.1% 70%, #FF5733 70.1% 100%)`
    },
    rail: {
      height: 8,
      borderRadius: 4,
      opacity: 1,
      background: props.gradientColorScheme
    },
    mark: {
      height: 0,
      width: 0,
    },
  })(Slider);

  return (
    <div>
      <PrettoSlider
        valueLabelDisplay={props.valueLabelDisplay}
        aria-label="pretto slider"
        value={props.value}
        thumbbackgroundcolor={props.thumbbackgroundcolor}
        thumbborder={props.thumbborder}
        marks={props.marks}
        max={props.max}
        min={props.min}
        slidercolor={props.slidercolor}
      />
    </div>
  );
}
