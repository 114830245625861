import { FormControl, InputLabel, MenuItem, Select } from '@material-ui/core';
import React, { Component } from 'react';
import { connect } from 'react-redux';
import { NavLink } from 'react-router-dom';
// import { Line } from 'react-chartjs-2';
import '../../assets/css/dashboard.css';
// import vitamins from '../../dummy/vitamin.json';
import graphImg from '../../assets/img/graph.png';
import { getTestList, getTestParameter } from '../../Redux/actions/dashboard/dashboard';
import { getUserInfo } from '../../Services/authService';
import DashbaordGraph from './dashboardGraph';
import VitaminGraph from './graph';
import { getTestParameterQuery, getTestResultsQuery } from './HomeQuery';


class Dashboard extends Component {
    constructor(props) {
        super(props);
        this.state = {
            test: '',
            testArr: this.props.testData.testResult.map(val => val.testid),
            SelectedTestInfo: []
        }
    }
    componentDidMount() {
        const variables = {
            where: {
                userid: getUserInfo().id
            }
        }
        this.props.getTestList({
            query: getTestResultsQuery,
            variables
        })
    }
    componentDidUpdate(prevProps) {
        if (
            prevProps !== this.props &&
            prevProps.testData.testResult.length !== this.props.testData.testResult.length
        ) {
            this.setState({
                testArr: this.props.testData.testResult.map(val => val.testid),
            })
        }
        if (
            prevProps !== this.props &&
            prevProps.testData.testParameter !== this.props.testData.testParameter
        ) {
            let arr = []
            this.props.testData.testResult.map((val) => {
                if (this.props.testData.testParameter[0]?.testid === val.testid) {
                    arr.push({ value: val.testparametervalue, date: val.createdon })
                }
            })
            this.setState({
                SelectedTestInfo: arr
            })
        }
    }
    handleTestChange(e) {
        this.setState({ test: e.target.value })
        this.props.getTestParameter({
            query: getTestParameterQuery,
            variables: {
                where: {
                    testid: e.target.value
                }

            }
        })
    }

    render() {
        return (
            <>
                <main className="dashboard">
                    <section className="dashboardwrap">
                        <div className="container">
                            <div className="flex">
                                <div className="header"><h1>Dashboard</h1></div>
                                <div className="form-group dropdown">
                                    <FormControl style={{ width: '15rem', marginTop: '1rem' }} variant="outlined" className="select-dropdown">
                                        <InputLabel id="area-label">Test Name</InputLabel>
                                        <Select
                                            labelId="area-label"
                                            id="area"
                                            name="area"
                                            value={this.state.test}
                                            onChange={(e) => { this.handleTestChange(e) }}
                                            label="Test Name"
                                        >
                                            {this.props.testData.testList.map(test => {
                                                if (this.state.testArr.includes(test.id)) {
                                                    return (
                                                        <MenuItem key={test.id} value={test.id}>
                                                            {test.testname}
                                                        </MenuItem>
                                                    )
                                                }
                                            })}
                                        </Select>
                                    </FormControl>
                                </div>

                                {/* <NavLink className="nav-link" to={`/test_details/${this.props.testData.testParameter[0]?.testid}`}>Vitamin D</NavLink> */}
                                <div className="graph">
                                    <div className="graphmenu">
                                        {/* <h2>Your Vitamin D trend</h2> */}
                                    </div>
                                    <div className="graphchart img-fluid">
                                        <DashbaordGraph
                                            parameters={this.props.testData.testParameter[0]}
                                            data={this.state.SelectedTestInfo} />
                                    </div>
                                    <div>
                                        {/* Hello */}
                                    </div>
                                </div>
                            </div>
                            <br />
                            <br />

                            {/* <div>
                          <table>
                            {vitamins.map((val, index) => (
                                <tr key={index}>
                                <td className="test-table">{val.test}</td>
                                <td className="test-table">{val.age}</td>
                                <td className="test-table">{val.gender}</td>
                                <td className="test-table">{val.sampleType}</td>
                                <td className="test-table">{val.sampleTime}</td>
                                <td className="test-table">{val.measure}</td>
                                <td style={{backgroundColor:'#ffe5b4'}} className="test-table">{val.low}</td>
                                <td style={{backgroundColor:'#d0f0c0'}} className="test-table">{val.optimum}</td>
                                <td style={{backgroundColor:'pink'}} className="test-table">{val.high}</td>
                              </tr>
                            ))}
                          </table>
                        </div> */}
                        </div>
                    </section>
                </main>
            </>
        )
    }
}
const mapStateToProps = (state) => {
    return {
        testData: state.testData
    }
};
const mapDispatchToProps = (dispatch) => {
    return {
        getTestList: (data) => dispatch(getTestList(data)),
        getTestParameter: (data) => dispatch(getTestParameter(data))
    };
};

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard);