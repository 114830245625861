import React, { Component } from 'react';
import { NavLink } from 'react-router-dom';
import "../../assets/css/register-kit.scss"
import { FormControl, RadioGroup, FormControlLabel, Radio, FormLabel, InputAdornment, InputLabel, MenuItem, Select, TextField, FormHelperText } from '@material-ui/core';
import Login from '../../Common/Login/login';
import { getAuthToken, getUserInfo } from '../../Services/authService';
import { connect } from 'react-redux';
import { saveRegisterKit } from '../../Redux/actions/register-kit/registerKit';
import BackdropLoader from '../../Services/loader';
import { getUserData } from '../../Redux/actions/user/user';
import { getUserInfoQuery } from './RegisterKitQuery';
import { checkSampleIdStatusStart, resetSampleIdStatus } from '../../Redux/actions/kit-sampleid-status/kitSampleIdStatus';
import ClearIcon from '@material-ui/icons/Clear';
import CheckIcon from '@material-ui/icons/Check';
import DoneAllIcon from '@material-ui/icons/DoneAll';
import messagePopup from '../../Services/messagePopupService';


class RegisterKit extends Component {
  constructor(props) {
    super(props);
    this.state = {
      sampleId: '',
      email: '',
      phonenumber: '',
      firstname: '',
      lastname: '',
      age: '',
      gender: 'male',
      streetAddress: '',
      buildingName: '',
      city: '',
      area: '',
      landmark: '',
      fieldErrors: {}
    }
  }

  componentDidMount = () => {
    this.props.resetSampleIdStatus();
    if (getAuthToken()) {
      const variables = {
        obj: {
          id: getUserInfo().id
        }
      }
      this.props.getUserData({
        query: getUserInfoQuery,
        variables
      })
    }
  }

  componentDidUpdate = (prevProps) => {
    if (prevProps !== this.props && this.props.user.data) {
      this.setState({
        firstname: this.props.user.data ? this.props.user.data.firstname : '',
        lastname: this.props.user.data ? this.props.user.data.lastname : '',
        email: this.props.user.data ? this.props.user.data.email : '',
        phonenumber: this.props.user.data ? this.props.user.data.phonenumber : '',
      })
    }
  }

  formatedDate = () => {
    const today = new Date();
    let currentdate = today.getDate();
    let month = today.getMonth() + 1;
    let year = today.getFullYear();
    let hours = today.getHours();
    let minutes = today.getMinutes();
    currentdate = currentdate < 10 ? `0${currentdate}` : currentdate;
    month = month < 10 ? `0${month}` : month;
    hours = hours < 10 ? `0${hours}` : hours;
    minutes = minutes < 10 ? `0${minutes}` : minutes;
    return `${year}-${month}-${currentdate}T${hours}:${minutes}`
  }

  handleAgeChange = (evt) => {
    const { name, value } = evt.target;
    if ((value && !isNaN(value) && +value > 0) || value === '') {
      this.setState({ [name]: value });
      this.validateInputErrors(name, value);
    } else {
      // this.setState({[name]: ''});
      this.validateInputErrors(name, '');
    }
  }

  handleInputChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    this.validateInputErrors(name, value);
    if (name === 'sampleId') {
      this.props.resetSampleIdStatus();
    }
  }

  handleSelectChange = (evt) => {
    const { name, value } = evt.target;
    this.setState({ [name]: value });
    this.validateInputErrors(name, value)
  }

  resetForm = () => {
    this.setState({ sampleId: '', streetAddress: '', buildingName: '', city: '', area: '', landmark: '' })
  }

  handleRegisterKit = async (evt) => {
    evt.stopPropagation();
    if (getAuthToken()) {
      const { sampleId, streetAddress, buildingName, city, area, landmark, age, gender } = this.state;
      const userId = getUserInfo().id;
      let tempErrMsg = this.formValidation();
      if (!tempErrMsg) {
        this.props.handleRegisterKitSubmit({
          userId,
          sampleId,
          streetAddress,
          buildingName,
          city,
          area,
          landmark,
          age: +age,
          gender
          // resetForm: this.resetForm
        })
      } else {
        messagePopup('', tempErrMsg, 'error')
      }
    } else {
      window.scrollTo(0, 0)
    }
  }

  handleCheckKitSampleIdStatus = (evt) => {
    if (this.state.sampleId && getAuthToken()) {
      this.props.checkSampleIdStatus({
        sampleId: this.state.sampleId
      })
    }
  }

  formValidation = () => {
    let fieldErrors = {};
    fieldErrors['sampleId'] = this.state.sampleId ? '' : 'Sample Kit Id is required.'
    fieldErrors['age'] = this.state.age ? this.state.age.length > 3 ? 'Please enter a valid age' : '' : 'Age is required.'
    fieldErrors['streetAddress'] = this.state.streetAddress ? '' : 'Street Address is required.';
    fieldErrors['city'] = this.state.city ? '' : 'City is required.';
    fieldErrors['buildingName'] = this.state.buildingName ? '' : 'Building Name is required.';
    fieldErrors['landmark'] = this.state.landmark ? '' : 'landmark is required field.';
    fieldErrors['area'] = this.state.area ? '' : 'Area is required field.';
    this.setState({ fieldErrors })
    return fieldErrors['sampleId']
      ? fieldErrors['sampleId']
      : fieldErrors['age']
        ? fieldErrors['age']
        : fieldErrors['streetAddress']
          ? fieldErrors['streetAddress']
          : fieldErrors['buildingName']
            ? fieldErrors['buildingName']
            : fieldErrors['city']
              ? fieldErrors['city']
              : fieldErrors['area']
                ? fieldErrors['area']
                : fieldErrors['landmark']
                  ? fieldErrors['landmark']
                  : '';
  }

  validateInputErrors = (name, value) => {
    let fieldErrors = { ...this.state.fieldErrors };
    if (name === 'sampleId') {
      fieldErrors['sampleId'] = value ? '' : 'Sample Kit Id is required.'
    }
    if (name === 'age') {
      fieldErrors['age'] = value ? value.length > 3 ? 'Please enter a valid age' : '' : 'Age is required.'
    }
    if (name === 'streetAddress') {
      fieldErrors['streetAddress'] = value ? '' : 'Street Address is required.'
    }
    if (name === 'city') {
      fieldErrors['city'] = value ? '' : 'City is required.'
    }
    if (name === 'buildingName') {
      fieldErrors['buildingName'] = value ? '' : 'Building Name is required.'
    }
    if (name === 'landmark') {
      fieldErrors['landmark'] = value ? '' : 'landmark is required field.'
    }
    if (name === 'area') {
      fieldErrors['area'] = value ? '' : 'Area is required field.'
    }
    this.setState({ fieldErrors })
  }

  render() {
    return (
      <div className="pageWrap" style={{ marginTop: "85px" }}>
        <BackdropLoader open={this.props.registerKit.loading || this.props.user.loading} />
        <main className="securecheckout">
          {/* <!-- breadcumb starts here -->	 */}
          <div className="breadcumb">
            <div className="container">
              <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/register-kit">Register a kit</NavLink></li>
              </ul>
            </div>
          </div>

          {/* <!-- checkout section start here --> */}
          <section className="checkout">
            <div className="container">
              <div className="heading">
                <div className="headshop"><h1>Register a kit</h1></div>
              </div>
              {/* {!getAuthToken() && (
                        <>
                          <div className="logintoacount">
                            <p><Login btnType="login" elementType="NavLink" currentRoute="/register-kit" nextRoute="/register-kit">Login</Login> or <Login btnType="signup" elementType="NavLink" currentRoute="/register-kit" nextRoute="/register-kit">Sign Up</Login></p>
                          </div>
                          <div>
                            <p style={{fontSize: '13px',color: 'red'}}>For Register a kit Login or Signup required.</p>
                          </div>
                        </>
                    )} */}

              {/* <!-- secure shipping details start here --> */}
              <div className="securewrap">
                <div className="securewrapcnt">
                  <div className="shippingdetails">
                    <h2>Kit Information</h2>
                    <div className="customerfrom">
                      <div className="form-group">
                        <TextField
                          label="Sample ID"
                          variant="outlined"
                          className="text-field"
                          id="sampleId"
                          name="sampleId"
                          value={this.state.sampleId}
                          onChange={this.handleInputChange}
                          onBlur={this.handleCheckKitSampleIdStatus}
                          required
                          error={this.state.fieldErrors['sampleId'] ? true : false}
                          helperText={this.state.fieldErrors['sampleId'] ? this.state.fieldErrors['sampleId'] : ''}
                          InputProps={{
                            endAdornment: (
                              <InputAdornment position="end">
                                {this.props.kitSampleIdStatus.status !== null ? this.props.kitSampleIdStatus.status ? <DoneAllIcon style={{ color: 'green' }} /> : <ClearIcon style={{ color: 'red' }} /> : ''}
                              </InputAdornment>
                            )
                          }}
                        />
                        {this.props.kitSampleIdStatus.status !== null ? this.props.kitSampleIdStatus.status ? <p style={{ color: 'green' }}>Tests Name : {this.props.kitSampleIdStatus.msg}</p> : <p>{this.props.kitSampleIdStatus.msg}</p> : ''}

                      </div>

                    </div>
                    <h2>General Information</h2>
                    <div className="customerfrom">
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="First Name"
                          id="firstname"
                          name="firstname"
                          value={this.state.firstname}
                          onChange={this.handleInputChange}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Last Name"
                          id="lastname"
                          name="lastname"
                          value={this.state.lastname}
                          onChange={this.handleInputChange}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Email"
                          id="email"
                          name="email"
                          value={this.state.email}
                          onChange={this.handleInputChange}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="+971 Phone Number"
                          id="phonenumber"
                          name="phonenumber"
                          value={this.state.phonenumber}
                          onChange={this.handleInputChange}
                          disabled
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Age"
                          id="age"
                          name="age"
                          value={this.state.age}
                          onChange={this.handleAgeChange}
                          // type="number"
                          required
                          error={this.state.fieldErrors['age'] ? true : false}
                          helperText={this.state.fieldErrors['age'] ? this.state.fieldErrors['age'] : ''}
                        />
                      </div>
                      <div className="form-group">
                        <FormControl component="fieldset">
                          <FormLabel component="legend">Gender</FormLabel>
                          <RadioGroup row aria-label="gender" name="gender" value={this.state.gender} className="radio-btn" onChange={this.handleInputChange}>
                            <FormControlLabel
                              value="male"
                              control={<Radio style={{ color: 'green' }} />}
                              label="Male"
                            />
                            <FormControlLabel
                              value="female"
                              control={<Radio style={{ color: 'green' }} />}
                              label="Female"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                    <h2>Sample Collection Address</h2>
                    <div className="customerfrom">
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Street Address"
                          id="streetAddress"
                          name="streetAddress"
                          value={this.state.streetAddress}
                          onChange={this.handleInputChange}
                          required
                          error={this.state.fieldErrors['streetAddress'] ? true : false}
                          helperText={this.state.fieldErrors['streetAddress'] ? this.state.fieldErrors['streetAddress'] : ''}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="Building name/number/villa/apartment"
                          id="buildingName"
                          name="buildingName"
                          value={this.state.buildingName}
                          onChange={this.handleInputChange}
                          required
                          error={this.state.fieldErrors['buildingName'] ? true : false}
                          helperText={this.state.fieldErrors['buildingName'] ? this.state.fieldErrors['buildingName'] : ''}
                        />
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="City"
                          id="city"
                          name="city"
                          value={this.state.city}
                          onChange={this.handleInputChange}
                          required
                          error={this.state.fieldErrors['city'] ? true : false}
                          helperText={this.state.fieldErrors['city'] ? this.state.fieldErrors['city'] : ''}
                        />
                      </div>
                      <div className="form-group">
                        <FormControl
                          variant="outlined"
                          className="select-dropdown"
                          required
                          error={this.state.fieldErrors['area'] ? true : false}
                        >
                          <InputLabel id="area-label">Area</InputLabel>
                          <Select
                            labelId="area-label"
                            id="area"
                            name="area"
                            value={this.state.area}
                            onChange={this.handleSelectChange}
                            label="Area"
                          >
                            <MenuItem value="Punjab">Punjab</MenuItem>
                            <MenuItem value="1">One</MenuItem>
                            <MenuItem value="2">Two</MenuItem>
                            <MenuItem value="3">Three</MenuItem>
                          </Select>
                          {this.state.fieldErrors['area'] && (
                            <FormHelperText>{this.state.fieldErrors['area']}</FormHelperText>
                          )}
                        </FormControl>
                      </div>
                      <div className="form-group">
                        <TextField
                          variant="outlined"
                          className="text-field"
                          label="**Nearest Landmark"
                          id="landmark"
                          name="landmark"
                          value={this.state.landmark}
                          onChange={this.handleInputChange}
                          required
                          error={this.state.fieldErrors['landmark'] ? true : false}
                          helperText={this.state.fieldErrors['landmark'] ? this.state.fieldErrors['landmark'] : ''}
                        />
                      </div>

                      {/* <div className="form-group">
                                        <TextField 
                                          variant="outlined" 
                                          className="text-field"
                                          label="Preferred Time" 
                                          id="preferredTime"
                                          name="preferredTime"
                                          value={this.state.preferredTime}
                                          onChange={this.onTimeChange}
                                          type="datetime-local"
                                        />
                                    </div> */}
                      <p>**A landmark helps us in locating your address. Please specify a well-known place near your address like a Mall, Hotel, Gas Station, etc.</p>
                    </div>
                  </div>
                  <button type="submit" className="btncheckout" onClick={this.handleRegisterKit}>Register my kit</button>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    registerKit: state.registerKit,
    user: state.user,
    kitSampleIdStatus: state.kitSampleIdStatus
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleRegisterKitSubmit: (data) => dispatch(saveRegisterKit(data)),
    getUserData: (data) => dispatch(getUserData(data)),
    checkSampleIdStatus: (data) => dispatch(checkSampleIdStatusStart(data)),
    resetSampleIdStatus: () => dispatch(resetSampleIdStatus())
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(RegisterKit);