export const getAppointmentsQuery = `
query allAppointments($obj: JSON){
  allAppointments(where: $obj){
    Appointments{
        acceptstatus
        doctoruserid
        id
        userid
        slotid
        callurl
        callid
        appointmentdoctoruseridmaprel{
          Userdata{
            lastname
            firstname
            email
            phonenumber
          }
        }
        appointmentslotsidmaprel{
          Doctorslots{
            slotsdate
            slotstatus
            slotsendtime
            slotsstarttime
            assignslotstatus
          }
        }
      }
  }
} 
`;

export const getDoctorSlotsQuery = `query($where:JSON){
  allDoctorslots(where:$where){
    Doctorslots{
      slotsdate
      slotsstarttime
      slotsendtime
      userid
      id
      doctorslotsuseridmaprel{
        Userdata{
          firstname
          lastname
          email
        }
      }
    }
  }
}`

export const cancelAppointmentQuery = `
mutation($where:JSON, $data:JSON,$obj:DoctorslotsInput!){
  AppointmentUpsertWithWhere(where:$where, data:$data){
    id
 }
 saveDoctorslots(obj:$obj){
 id
 }
}
`;

export const getAppointmentDetailsQuery = `
query allAppointments($obj: JSON){
  allAppointments(where: $obj){
    Appointments{
        acceptstatus
        doctoruserid
        id
        userid
        slotid
        callurl
        callid
        callpassword
      }
  }
} 
`;