import React, { useState, useEffect } from "react";
import io from "socket.io-client";
import "./chat.css";
import InfoBar from "../ChatMessages/InfoBar/InfoBar";
import ChatInput from "../ChatMessages/ChatInput/ChatInput";
import InputChatMessage from "../ChatMessages/InputChatMessage/InputChatMessages";
import ScrollToBottom from "react-scroll-to-bottom";
import {
  saveChatStart,
  getChatStart,
  removeChatData,
} from "../../Redux/actions/chatHistory/chatHistory";
import { connect } from "react-redux";
import { saveUserChatDataQuery, getUserDoctorChatData } from "./query";
import GroupMessages from "../ChatMessages/InputChatMessage/GroupMessages";
const ENDPOINT = "api.thulsee.com:8450";
let socket;

const Chat = (props) => {
  const [chatMessages, setChatMessages] = useState([]);
  const [name, setName] = useState("");
  const [room, setRoom] = useState("");
  const [users, setUsers] = useState("");
  const [message, setMessage] = useState("");
  const [groupedMessages, setGroupedMessages] = useState([]);

  useEffect(async () => {
    const room = props.room;
    const name = props.name;
    socket = io(ENDPOINT, { transports: ["websocket"] });

    await setRoom(room);
    await setName(name);
    await socket.emit("join", { name, room }, (error) => {
      if (error) {
        alert(error);
      }
    });
  }, [ENDPOINT]);

  useEffect(() => {
    socket.on("message", (message) => {
      if (message && message.user.trim().toLowerCase() === props.name) {
        setChatMessages((chatMessages) => [
          ...chatMessages,
          {
            doctormessage: null,
            usermessage: message.text,
          },
        ]);
      } else {
        setChatMessages((chatMessages) => [
          ...chatMessages,
          {
            doctormessage: message.text,
            usermessage: null,
          },
        ]);
      }
    });

    socket.on("roomData", ({ users }) => {
      setUsers(users);
    });
  }, []);

  useEffect(() => {
    if (props.userDoctorChat.data && props.userDoctorChat.data.chatDetails) {
      const tempData = props.userDoctorChat.data.chatDetails;

      setChatMessages(tempData);
    }
  }, [props.userDoctorChat.data]);

  const sendMessage = (event) => {
    event.preventDefault();

    if (message) {
      socket.emit("sendMessage", message, () => setMessage(""));
      saveChatData(message, name, room);
    }
  };

  const saveChatData = (message, name, room) => {
    const variables = {
      obj: {
        username: name,
        userroomname: room,
        usermessage: message,
      },
    };
    props.saveChatStart({
      query: saveUserChatDataQuery,
      variables,
      //  handleNewClose: this.handleNewClose,
    });
  };

  useEffect(() => {
    props.getChatStart({
      query: getUserDoctorChatData(props.room),
    });
  }, []);

  const handleChatClose = () => {
    setChatMessages([]);
    props.removeChatData();
    props.chatHandleClose();
  };

  const groups =
    chatMessages &&
    chatMessages.reduce((groups, msg) => {
      let date = msg.createdon
        ? msg.createdon.split("T")[0]
        : new Date().toISOString().split("T")[0];
      if (date === new Date().toISOString().split("T")[0]) {
        date = "Today";
      }
      if (!groups[date]) {
        groups[date] = [];
      }
      groups[date].push(msg);

      return groups;
    }, {});


  const handleGroups = () => {
    const groupArrays = Object.keys(groups).map((date) => {
      return {
        date,
        msgs: groups[date],
      };
    });
    setGroupedMessages([...groupArrays]);
  };

  useEffect(() => {
    handleGroups();
  }, [chatMessages]);

  return (
    // className="userChat"
    <div>
      <div className="outerContainer">
        <div className="container">
          <InfoBar
            room={room}
            chatHandleClose={handleChatClose}
            docName={props.chatWithName}
          />
          <ScrollToBottom className="userMessages">
            {groupedMessages &&
              groupedMessages.map((value, index) => (
                <GroupMessages
                  key={index}
                  date={value.date}
                  groupMessages={value.msgs}
                />
              ))}
          </ScrollToBottom>
          {/* <InputChatMessage chatMessages={chatMessages} /> */}
          <ChatInput
            message={message}
            setMessage={setMessage}
            sendMessage={sendMessage}
          />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    userDoctorChat: state.chatHistory,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    saveChatStart: (data) => dispatch(saveChatStart(data)),
    getChatStart: (data) => dispatch(getChatStart(data)),
    removeChatData: () => dispatch(removeChatData()),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Chat);
