import { GET_APPOINTMENT_INVOICE_DETAILS_FAILED, GET_APPOINTMENT_INVOICE_DETAILS_START, GET_APPOINTMENT_INVOICE_DETAILS_SUCCESS, GET_INVOICE_DETAILS_FAILED, GET_INVOICE_DETAILS_START, GET_INVOICE_DETAILS_SUCCESS } from "../actions/types";

const intialData = {
    loading: false,
    data: null,
    error: null
}

export default function (state = intialData, action) {
    switch (action.type) {
        case GET_INVOICE_DETAILS_START:
            return {
                ...state,
                loading: true,
            };
        case GET_INVOICE_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case GET_INVOICE_DETAILS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        case GET_APPOINTMENT_INVOICE_DETAILS_START:
            return {
                ...state,
                loading: true,
            };
        case GET_APPOINTMENT_INVOICE_DETAILS_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
            };
        case GET_APPOINTMENT_INVOICE_DETAILS_FAILED:
            return {
                ...state,
                error: action.payload,
                loading: false,
            };
        default:
            return { ...state };
    }
}