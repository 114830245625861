import { GET_TEST_RESULT_FAILED, GET_TEST_RESULT_START, GET_TEST_RESULT_SUCCESS } from "../actions/types";

const initialData = {
    loading: false,
    data: null,
    error: null
}
export default function (state = initialData, action) {
    switch (action.type) {
        case GET_TEST_RESULT_START:
            return {
                ...state,
                loading: true,
            };
        case GET_TEST_RESULT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            };
        case GET_TEST_RESULT_FAILED:
            return {
                ...state,
                error: action.payload,
                data: null,
                loading: false
            };
        default:
            return { ...state };
    }
}