import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { BACK_TO_LOGIN, LOGIN_FAILED, LOGIN_START, LOGIN_SUCCESS, OTP_PHASE } from '../types';
import history from '../../../history'
import { getCartData, removecartLocal, setAuthToken, setUserInfo } from '../../../Services/authService';

export const backToLogin = () => async (dispatch) => {
  dispatch({
    type: BACK_TO_LOGIN
  })
}

export const loginStart = (data) => async (dispatch) => {
  dispatch({
    type: LOGIN_START
  })
  try {
    const response = await fetchMethod(`/login?email=${data.email}&password=${data.password}&role=${data.role}${data.otp ? `&otp=${data.otp}` : ''}`, null, 'post', false);
    if (data.otp) {
      if (response && response.status === 200) {
        if (response.data && (response.data.statusCode === 423 || response.data.statusCode === 410)) {
          messagePopup('', response.data.displayMessage, 'error');
          dispatch({
            type: LOGIN_FAILED,
            payload: response.data.displayMessage
          })
        }
        else if (response && response.data.statusCode === 401) {
          let msg = `Invalid OTP input
          ${5 - response.data.count} attempts left`
          messagePopup('', msg, 'error');
          dispatch({
            type: LOGIN_FAILED,
            payload: response.data.displayMessage
          })
        }
        else {
          if (response.data.ispassword) {
            let cartData = getCartData() ? getCartData() : [];
            if (cartData && cartData.length) {
              const userId = response.data ? response.data.id : 0;
              const access_token = response.data ? response.data.token : "";
              let productArray = [];
              for (const data of cartData) {
                productArray = [...productArray, {
                  testId: data.id,
                  quantity: +data.quantity
                }]
              }
              const responseShop = await fetchMethod(`/api/Userdata/addProductInCart`, { userId, productArray, access_token }, 'post', false);
              if (response && response.status === 200) {
                removecartLocal();
                dispatch({
                  type: LOGIN_SUCCESS,
                  payload: response.data
                })
                setAuthToken(response.data.token);
                setUserInfo(response.data);
                if (data.nextRoute && data.currentRoute) {
                  if (data.nextRoute === data.currentRoute) {
                    window.location.reload();
                  } else {
                    window.location = `#${data.nextRoute}`;
                  }
                } else {
                  window.location = "/";
                }
              } else {
                messagePopup('', 'Login failed', 'error');
                dispatch({
                  type: LOGIN_FAILED,
                  payload: 'Login failed'
                })
              }
            } else {
              dispatch({
                type: LOGIN_SUCCESS,
                payload: response.data
              })
              setAuthToken(response.data.token);
              setUserInfo(response.data);
              if (data.nextRoute && data.currentRoute) {
                if (data.nextRoute === data.currentRoute) {
                  window.location.reload();
                } else {
                  window.location = `#${data.nextRoute}`;
                  window.location.reload();
                }
              } else {
                window.location = "/";
              }
            }
          } else {
            dispatch({
              type: LOGIN_SUCCESS,
              payload: { email: data.email }
            })
            data.openResetPasswordModal('login');
          }
        }
      } else {
        messagePopup('', 'Login failed', 'error');
        dispatch({
          type: LOGIN_FAILED,
          payload: 'Login failed'
        })
      }
    }
    else {
      if (response.data.statusCode === 200) {
        messagePopup('', "OTP sent to your email!", "success")
        dispatch({
          type: OTP_PHASE,
        })
      }
      else if (response.data.statusCode === 423) {
        messagePopup('', response.data.displayMessage, 'error');
        dispatch({
          type: LOGIN_FAILED,
          payload: 'Login failed'
        })
      }
      else {
        messagePopup('', 'Login failed', 'error');
        dispatch({
          type: LOGIN_FAILED,
          payload: 'Login failed'
        })
      }
    }
  } catch (error) {
    messagePopup('', 'Login failed', 'error');
    dispatch({
      type: LOGIN_FAILED,
      payload: 'Login failed'
    })
  }

};