import React, { Component } from 'react';
import '../../assets/css/createappointment.css';
import trashIcon from '../../assets/imgs/trash-wht.svg';
import plusIcon from '../../assets/imgs/plue-wht.svg';


class CreateAppointments extends Component {
    state = {}

    handleChange = () => {
       
    }

    render(){
        return(
            <div>
                <main className="createappointment">
        <section className="appointmenwrap">
            <div className="container">
                <div className="appointhead">
                    <div className="heading"><h1>Create Appointment</h1></div>
                    <div className="appointcnt">
                        <div className="info">
                            <p>ID: 324SDAD321ASD32</p>
                            <p>John Daniel</p>
                        </div>
                        <div className="actionbtn">
                            <button className="btncancle">Cancle</button>
                            <button className="btnsubmit" type="submit">Create</button>
                        </div>
                    </div>
                </div>
                
                <div className="appointlists">
                    <form onChange={(e) => this.handleChange(e.target.value)}>
                        <div className="form-row">                            
                            <div className="form-group col-md-4 col-sm-12">                                
                                <input type="date" className="form-control" placeholder="admin@gmail.com" id="date" />
                            </div>
                            <div className="form-group col-md-2 col-sm-5">                                
                                <input type="time" className="form-control" placeholder="admin@gmail.com" id="time" />
                            </div>
                            <div className="form-group col-md-2 col-sm-5">                                
                                <input type="time" className="form-control" placeholder="admin@gmail.com" id="time" />
                            </div>
                            <div className="form-group col-md-1 col-sm-2 col-12 text-center">                                
                                <button type="button" className="btndelete"> <img src={trashIcon} alt="trash-icon" /> </button>
                            </div>
                        </div>
                        <div className="divider"></div>
                        
                        <div className="form-row">                            
                            <div className="form-group col-md-4 col-sm-12">                                
                                <input type="date" className="form-control" placeholder="admin@gmail.com" id="date" />
                            </div>
                            <div className="form-group col-md-2 col-sm-5">                                
                                <input type="time" className="form-control" placeholder="admin@gmail.com" id="time" />
                            </div>
                            <div className="form-group col-md-2 col-sm-5">                                
                                <input type="time" className="form-control" placeholder="admin@gmail.com" id="time" />
                            </div>
                            <div className="form-group col-md-1 col-sm-2 col-12 text-center">                                
                                <button type="button" className="btnadd"> <img src={plusIcon} alt="plue-wht-icon" /> </button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </section>
    </main>
            </div>
        )
    }
}


export default CreateAppointments;