import { getCartData, getUserInfo, removecartLocal, setCartData } from '../../../Services/authService';
import { fetchGraphMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { GET_TEST_START, GET_TEST_SUCCESS, GET_TEST_FAILED, ADD_SHOPPING_CART_SUCCESS, GET_SHOPPING_CART_SUCCESS, ADD_SHOPPING_CART_FAILED, ADD_SHOPPING_CART_START, GET_CART_TOTAL_COUNT, GET_SHOPPING_CART_START } from '../types';

let actualShoppingCartData = [];
let allShoppingCartData = [];
export const getUserShoppingCartData = (data) => async dispatch => {
  try {
    dispatch({
      type: GET_SHOPPING_CART_START
    })
    const response1 = await fetchGraphMethod(data.query.getCartQuery, data.variables, true);
    if (response1.data && response1.data.data) {
      const newCartdata = response1.data.data.allCarts.Carts ? response1.data.data.allCarts.Carts : [];
      let cartTest = [];

      allShoppingCartData = newCartdata;
      for (const temp of newCartdata) {
        const testResponse = await fetchGraphMethod(data.query.getTestQuery, { obj: { id: temp.testid } }, true);
        if (testResponse && testResponse.status === 200) {
          if (testResponse.data) {
            const test = testResponse.data.data.allTests.Tests[0];
            cartTest = [...cartTest, { ...test, quantity: temp.quantity, cartId: temp.id }];
          }
        }
      }
      actualShoppingCartData = cartTest;
      dispatch({
        type: GET_SHOPPING_CART_SUCCESS,
        payload: {
          data: cartTest,
          cartCount: cartTest.length
        }
      })
    }
  } catch (error) {
    messagePopup('', 'Get Test Details Falied', 'error');
    dispatch({
      type: GET_TEST_FAILED,
      payload: 'Test List Failed'
    })
  }
};

export const addUserShoppingCartData = (data) => async dispatch => {
  try {
    dispatch({
      type: ADD_SHOPPING_CART_START
    })
    let finalData = {};
    const { testid, quantity, userid } = data.variables;
    const testData = allShoppingCartData.find(item => item.testid === testid);
    if (testData) {
      finalData = {
        id: testData.id,
        testid,
        userid,
        quantity: `1`,
      }
    } else {
      finalData = {
        userid,
        quantity,
        testid
      }
    }
    const response = await fetchGraphMethod(data.query, { obj: { ...finalData } }, true);
    if (response.data && response.data.data && response.data.data.saveCart) {
      let flag = false;
      for (const cart of allShoppingCartData) {
        if (response.data.data.saveCart.id === cart.id) {
          flag = true;
        }
      }
      if (flag) {
        allShoppingCartData = allShoppingCartData.map(item => {
          if (item.id === response.data.data.saveCart.id) {
            item = { ...item, ...response.data.data.saveCart }
          }
          return item;
        });
      } else {
        allShoppingCartData = [{ ...response.data.data.saveCart }, ...allShoppingCartData];
      }
      dispatch({
        type: ADD_SHOPPING_CART_SUCCESS,
        payload: {
          cartCount: !testData ? data.cuurentcartCount + 1 : data.cuurentcartCount, data: allShoppingCartData.map(val => {
            return { id: val.testid }
          })
        }
      })
    } else {
      messagePopup('', 'Add To Shopping Cart Failed', 'error')
      dispatch({
        type: ADD_SHOPPING_CART_FAILED,
        payload: 'Add To Shopping Cart Failed'
      })
    }
  } catch (error) {
    messagePopup('', 'Add To Shopping Cart Failed', 'error')
    dispatch({
      type: ADD_SHOPPING_CART_FAILED,
      payload: 'Add To Shopping Cart Failed'
    })
  }
};

export const updateUserShoppingCartData = (data) => async dispatch => {
  try {
    dispatch({
      type: ADD_SHOPPING_CART_START
    })
    const { quantity, id } = data.variables.obj;
    const response = await fetchGraphMethod(data.query, data.variables, true);
    if (response.data && response.data.data && response.data.data.saveCart) {
      let tempdata = [...actualShoppingCartData];
      tempdata = tempdata.map(item => {
        if (item.cartId === id) {
          item.quantity = quantity;
        }
        return item;
      })
      actualShoppingCartData = tempdata;
      dispatch({
        type: GET_SHOPPING_CART_SUCCESS,
        payload: {
          data: actualShoppingCartData,
          cartCount: actualShoppingCartData.length
        }
      })
    } else {
      messagePopup('', 'Add To Shopping Cart Failed', 'error')
      dispatch({
        type: ADD_SHOPPING_CART_FAILED,
        payload: 'Add To Shopping Cart Failed'
      })
    }
  } catch (error) {
    messagePopup('', 'Add To Shopping Cart Failed', 'error')
    dispatch({
      type: ADD_SHOPPING_CART_FAILED,
      payload: 'Add To Shopping Cart Failed'
    })
  }
};

export const deleteUserShoppingCartData = (data) => async dispatch => {
  try {
    dispatch({
      type: ADD_SHOPPING_CART_START
    })
    const { id } = data.variables.obj;
    const response = await fetchGraphMethod(data.query, data.variables, true);
    if (response.data && response.data.data && response.data.data.saveCart) {
      let tempdata = [...actualShoppingCartData];
      tempdata = tempdata.filter(item => item.cartId !== id);
      allShoppingCartData = allShoppingCartData.filter(item => item.id !== response.data.data.saveCart.id)
      actualShoppingCartData = tempdata;
      dispatch({
        type: GET_SHOPPING_CART_SUCCESS,
        payload: {
          data: actualShoppingCartData,
          cartCount: actualShoppingCartData.length
        }
      })
    } else {
      messagePopup('', 'Add To Shopping Cart Failed', 'error')
      dispatch({
        type: ADD_SHOPPING_CART_FAILED,
        payload: 'Add To Shopping Cart Failed'
      })
    }
  } catch (error) {
    messagePopup('', 'Add To Shopping Cart Failed', 'error')
    dispatch({
      type: ADD_SHOPPING_CART_FAILED,
      payload: 'Add To Shopping Cart Failed'
    })
  }
};

// export const addUserAllShoppingCartData = (data) => async dispatch => {
//   try {
//     let cartData = getCartData() ? getCartData() : [];
//     const userid = getUserInfo() ? getUserInfo().id : 0;
//     let successFlag = [];
//     dispatch({
//       type: ADD_SHOPPING_CART_START
//     }) 
//     for (const item of cartData) {
//       const response = await fetchGraphMethod(data.query.saveCartQuery,{obj: {
//         testid: item.id,
//         quantity: `${item.quantity}`,
//         userid: userid
//       }},true);
//       if(response.data && response.data.data && response.data.data.saveCart){
//         successFlag = [...successFlag,true];
//       } else {
//         successFlag = [...successFlag,false];
//       }
//     }
//     if(!successFlag.includes(false)) {
//        removecartLocal();
//        const response1 = await fetchGraphMethod(data.query.getCartQuery,{obj: {
//         userid: userid,
//         active: 1
//        }},true);
//       if(response1.data && response1.data.data) {
//        const cartTotalCount = response1.data.data.allCarts.totalCount ? response1.data.data.allCarts.totalCount : 0;
//        const cartTotalData = response1.data.data.allCarts.Carts ? response1.data.data.allCarts.Carts : 0;
//        let cartTest = [];
//        allShoppingCartData = cartTotalData;
//        for (const temp of cartTotalData) {
//            const testResponse = await fetchGraphMethod(data.query.getTestQuery,{obj: {id: temp.testid}},false);
//            if(testResponse && testResponse.status === 200){
//              if(testResponse.data) {
//                const test = testResponse.data.data.allTests.Tests[0];
//                cartTest = [{...test,quantity: temp.quantity,cartId: temp.id},...cartTest];
//              }
//            }
//        }
//        actualShoppingCartData = cartTest;
//        dispatch({
//          type: GET_CART_TOTAL_COUNT,
//          payload: {cartCount: cartTotalCount,data: cartTest}
//        }) 
//       }
//     } else {
//       dispatch({
//         type: ADD_SHOPPING_CART_FAILED,
//         payload: 'Add To Shopping Cart Failed'
//       })
//     }
//   } catch (error) {
//     dispatch({
//       type: ADD_SHOPPING_CART_FAILED,
//       payload: 'Add To Shopping Cart Failed'
//     })
//   }
// };

export const getShoppingCartData = () => async dispatch => {
  let cartData = getCartData() ? getCartData() : [];
  dispatch({
    type: GET_SHOPPING_CART_SUCCESS,
    payload: {
      data: cartData,
      cartCount: cartData.length
    }
  })
};

export const addShoppingCartData = (data) => dispatch => {
  let cartData = getCartData() ? getCartData() : [];
  let filteredData = cartData.find(item => item.id === data.id);
  if (filteredData) {
    const quantity = filteredData.quantity ? filteredData.quantity + (data.quantity ? +data.quantity : 1) : 1;
    cartData = cartData.map(item => {
      if (item.id === data.id) {
        item.quantity = quantity;
      }
      return item;
    })
  } else {
    cartData = [...cartData, { ...data, quantity: data.quantity ? +data.quantity : 1 }]
  }
  setCartData(cartData);
  dispatch({
    type: ADD_SHOPPING_CART_SUCCESS,
    payload: {
      data: cartData,
      cartCount: cartData.length
    }
  })
};

export const decreaseProductQuantity = (data) => dispatch => {
  let cartData = getCartData() ? getCartData() : [];
  let filteredData = cartData.find(item => item.id === data.id);
  if (filteredData) {
    const quantity = filteredData.quantity ? filteredData.quantity - 1 : 1;
    cartData = cartData.map(item => {
      if (item.id === data.id) {
        item.quantity = quantity;
      }
      return item;
    })
  }
  setCartData(cartData);
  dispatch({
    type: ADD_SHOPPING_CART_SUCCESS,
    payload: {
      data: cartData,
      cartCount: cartData.length
    }
  })
};

export const deleteShoppingCartData = (id) => dispatch => {
  let cartData = getCartData() ? getCartData() : [];
  let filteredData = cartData.filter(item => item.id !== id);
  setCartData(filteredData);
  dispatch({
    type: ADD_SHOPPING_CART_SUCCESS,
    payload: {
      data: filteredData,
      cartCount: filteredData.length
    }
  })
};