import { getTestNameQuery } from "../../../Pages/Home/HomeQuery"
import { fetchGraphMethod } from "../../../Services/httpService"
import messagePopup from "../../../Services/messagePopupService"
import { GET_TEST_LIST_FAILED, GET_TEST_LIST_START, GET_TEST_LIST_SUCCESS, GET_TEST_PARAMETER_FAILED, GET_TEST_PARAMETER_START, GET_TEST_PARAMETER_SUCCESS } from "../types"

export const getTestList = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_TEST_LIST_START
        })
        const response = await fetchGraphMethod(data.query, data.variables, true)
        const testList = await fetchGraphMethod(getTestNameQuery, {}, true)
        
        if (response && response.status === 200 && testList && testList.status === 200) {
            dispatch({
                type: GET_TEST_LIST_SUCCESS,
                payload: {
                    testResult: response.data.data.allReporttestresults.Reporttestresults,
                    testList: testList.data.data.allTests.Tests
                }
            })
        }
        else {
            dispatch({
                type: GET_TEST_LIST_FAILED,
                payload: 'get test list failed'
            })
            messagePopup('', 'get test list failed', 'error')
        }

    } catch (error) {
        dispatch({
            type: GET_TEST_LIST_FAILED,
            payload: 'get test list failed'
        })
        messagePopup('', 'get test list failed', 'error')
    }
}

export const getTestParameter = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_TEST_PARAMETER_START
        })
        const response = await fetchGraphMethod(data.query, data.variables, true)
        if (response && response.status === 200) {
            dispatch({
                type: GET_TEST_PARAMETER_SUCCESS,
                payload: response.data.data.allTestparameters.Testparameters
            })
        }
        else {
            dispatch({
                type: GET_TEST_PARAMETER_FAILED,
                payload: 'get test parameter failed'
            })
            messagePopup('', 'get test parameter failed', 'error')
        }

    } catch (error) {
        dispatch({
            type: GET_TEST_PARAMETER_FAILED,
            payload: 'get test parameter failed'
        })
        messagePopup('', 'get test parameter failed', 'error')
    }
}
