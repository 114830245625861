import { PATIENTSTEST } from '../actions/types';

const patientData = {
    items: {}
}


export default function(state = patientData, action) {
    switch(action.type) {
        case PATIENTSTEST:
            return {
                ...state,
                payload: PATIENTSTEST
            };

            default: 
                return state;
    }
}