import React, { Component } from 'react';
import {NavLink} from "react-router-dom";


class Notfound extends Component {
  render() {
    return (


      <div className= "pageWrap" style={{marginTop:"85px"}}>
          {/*         <div>Not Found</div>*/}
          <main className="otherPage">
              <div className="breadcumb">
                  <div className="container">
                      <ul>
                          <li><NavLink to="/">Home</NavLink></li>
                          <li>  Not Found</li>
                      </ul>
                  </div>
              </div>

              <section className="checkout">
                  <div className="container">
                      <div className="heading">
                          <div className="headshop"><h1>Not Found</h1></div>
                      </div>
                  </div>
              </section>
          </main>
      </div>
    );
  }
}

export default Notfound;