import React from 'react';
import './chatInput.css';

const ChatInput = ({ setMessage, sendMessage, message }) => (
  <div className="chat">
    <form className="form">
      <input
        className="input"
        type="text"
        placeholder="Type a message..."
        value={message}
        onChange={({ target: { value } }) => setMessage(value)}
        onKeyPress={event => event.key === 'Enter' ? sendMessage(event) : null}
      />
      <button className="sendButton" onClick={e => sendMessage(e)}>Send</button>
    </form>
  </div>

)

export default ChatInput;