import { UPDATE_ACCOUNT_FAILED, UPDATE_ACCOUNT_START, UPDATE_ACCOUNT_SUCCESS } from "../actions/types";

const initialData = {
   data: null,
   error: null,
   loading: false
}

export default function(state = initialData, action) {
    switch(action.type) {
        case UPDATE_ACCOUNT_START: 
            return {
                ...state,
                loading: true
            }
        case UPDATE_ACCOUNT_SUCCESS: 
            return {
                ...state,
                error: null,
                data: action.payload,
                loading: false
            }
        case UPDATE_ACCOUNT_FAILED: 
            return {
                ...state,
                data: null,
                error: action.payload,
                loading: false
            }
        default: 
            return {
                ...state
            }
    }
}