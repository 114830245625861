import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { RESET_PASSWORD_FAILED, RESET_PASSWORD_START, RESET_PASSWORD_SUCCESS } from '../types';
import history from '../../../history'
import { getCartData, removecartLocal, setAuthToken, setUserInfo } from '../../../Services/authService';

export const resetPasswordStart = (data) => async (dispatch) => {
    dispatch({
        type: RESET_PASSWORD_START
    })
    try {
        const {email,oldPassword,newPassword} = data.apiData;
        const {nextRoute,currentRoute,...restData} = data.previousData
        const response = await fetchMethod(`/api/Userdata/resetPassword`,{email,oldPassword,newPassword},'post',false);
        if(response && response.status === 200){
          if(response.data && response.data.response && response.data.response.statusCode === 200){
              let cartData = getCartData() ? getCartData() : [];
              if(cartData && cartData.length) {
                const userId = response.data ? response.data.id : 0;
                let productArray = [];
                for (const data of cartData) {
                  productArray = [...productArray,{
                    testId: data.id,
                    quantity: +data.quantity
                  }]
                }
                const responseShop = await fetchMethod(`/api/Userdata/addProductInCart`,{userId,productArray},'post',false);
                if(response && response.status === 200){
                  removecartLocal();
                  dispatch({
                    type: RESET_PASSWORD_SUCCESS,
                    payload: 'Password updated succssfully'
                  })
                  setAuthToken(restData.token);
                  setUserInfo({...restData,ispassword: 1});
                  if(nextRoute && currentRoute) {
                      if(nextRoute === currentRoute){
                        window.location.reload();
                      } else {
                        window.location = `#${data.nextRoute}`;
                      }
                  } else {
                    window.location = "/";
                  }
                } else {
                  messagePopup('', 'Reset Password failed', 'error');
                  dispatch({
                    type: RESET_PASSWORD_FAILED,
                    payload: 'Reset Password failed'
                  })
                }
              } else {
                dispatch({
                  type: RESET_PASSWORD_SUCCESS,
                  payload: 'Password updated succssfully'
                })
                setAuthToken(restData.token);
                setUserInfo({...restData,ispassword: 1});
                if(nextRoute && currentRoute) {
                    if(nextRoute === currentRoute){
                      window.location.reload();
                    } else {
                      window.location = `#${data.nextRoute}`;
                    }
                } else {
                  window.location = "/";
                }
              }
            
          } else if(response.data && response.data.response && response.data.response.statusCode === 201){
            messagePopup('', response.data.response.msg, 'error');
            dispatch({
              type: RESET_PASSWORD_FAILED,
              payload: response.data.response.msg
            })
          } else {
            messagePopup('', 'Reset Password failed', 'error');
            dispatch({
              type: RESET_PASSWORD_FAILED,
              payload: 'Reset Password failed'
            })
          }
        } else {
          messagePopup('', 'Reset Password failed', 'error');
          dispatch({
            type: RESET_PASSWORD_FAILED,
            payload: 'Reset Password failed'
          })
        }
      } catch (error) {
        messagePopup('', 'Reset Password failed', 'error');
        dispatch({
            type: RESET_PASSWORD_FAILED,
            payload: 'Reset Password failed'
        })
      }
    
};


export const resetForgotPasswordStart = (data) => async (dispatch) => {
  dispatch({
      type: RESET_PASSWORD_START
  })
  try {
      const {email,oldPassword,newPassword,openLoginModal,clearResetPassword} = data;
      const response = await fetchMethod(`/api/Userdata/resetPassword`,{email,oldPassword,newPassword},'post',false);
      if(response && response.status === 200){
        if(response.data && response.data.response && response.data.response.statusCode === 200){
          messagePopup('', response.data.response.msg, 'success');
          dispatch({
              type: RESET_PASSWORD_SUCCESS,
              payload: response.data.response.msg
          })
          openLoginModal();
          clearResetPassword();
        } else if(response.data && response.data.response && response.data.response.statusCode === 201){
          messagePopup('', 'Invalid Current Password', 'error');
          dispatch({
            type: RESET_PASSWORD_FAILED,
            payload: 'Invalid Current Password'
          })
        } else {
          messagePopup('', 'Reset Password failed', 'error');
          dispatch({
            type: RESET_PASSWORD_FAILED,
            payload: 'Reset Password failed'
          })
        }
      } else {
        messagePopup('', 'Reset Password failed', 'error');
        dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: 'Reset Password failed'
        })
      }
    } catch (error) {
      messagePopup('', 'Reset Password failed', 'error');
      dispatch({
          type: RESET_PASSWORD_FAILED,
          payload: 'Reset Password failed'
      })
    }
};