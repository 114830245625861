import { unixTimestampToDate } from "../../../Services/dateTimeFormatter";
import { fetchGraphMethod, fetchMethod } from "../../../Services/httpService";
import messagePopup from "../../../Services/messagePopupService";
import {
  BOOK_APPOINTMENT_FAILED,
  BOOK_APPOINTMENT_START,
  GET_APPOINTMENTS_FAILED,
  GET_APPOINTMENTS_START,
  GET_APPOINTMENTS_SUCCESS,
  REQUEST_APPOINTMENT_FAILED,
  REQUEST_APPOINTMENT_START,
  CANCEL_APPOINTMENT_START,
  CANCEL_APPOINTMENT_SUCCESS,
  CANCEL_APPOINTMENT_FAILED,
  GET_DOCTOR_SLOTS_START,
  GET_DOCTOR_SLOTS_SUCCESS,
  GET_DOCTOR_SLOTS_FAILED,
  REDIRECT_TO_PAYMENT_PORTAL,
  GET_LATEST_MESSAGE_START,
  GET_LATEST_MESSAGE_SUCCESS,
  GET_LATEST_MESSAGE_FAILED,
  SHOW_ERROR_TEXT,
  HIDE_ERROR_TEXT
} from "../types";

const getSeconds = (time, type) => {
  if (type === 'hour') {
    return (time * 3600)
  }
  else if (type === 'minute') {
    return (time * 60)
  }

}

export const getDoctorSlotsStart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_DOCTOR_SLOTS_START,
    });
    const response = await fetchGraphMethod(data.query, data.variables, true);
    if (response && response.status == 200 && response.data) {
      if (response.data.data.allDoctorslots.Doctorslots.length === 0) {
        dispatch({
          type: SHOW_ERROR_TEXT
        })
      }
      if (response.data.data.allDoctorslots.Doctorslots.length > 0) {
        dispatch({
          type: HIDE_ERROR_TEXT
        })
      }
      let sortedArr = response.data.data.allDoctorslots.Doctorslots.sort((a, b) => {
        let firstarr = a.slotsstarttime.split(':');
        let secondarr = b.slotsstarttime.split(':');
        let firstVal = getSeconds(parseInt(firstarr[0]), 'hour') + getSeconds(parseInt(firstarr[1]), 'minute')
        let secondVal = getSeconds(parseInt(secondarr[0]), 'hour') + getSeconds(parseInt(secondarr[1]), 'minute')
        return (firstVal - secondVal)
      })
      dispatch({
        type: GET_DOCTOR_SLOTS_SUCCESS,
        payload: sortedArr
      })
    }
    else {

      dispatch({
        type: GET_DOCTOR_SLOTS_FAILED,
        payload: "Doctor Slots Couldn't be fetched!",

      })
    }
  } catch (error) {
    messagePopup('', 'Get Doctor Slots Failed', 'error')
    dispatch({
      type: GET_DOCTOR_SLOTS_FAILED,
      payload: "Doctor Slots Couldn't be fetched!",

    })
  }
}
export const redirectToPaymentPortal = () => ({ type: REDIRECT_TO_PAYMENT_PORTAL })
export const getAppointmentsStart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_APPOINTMENTS_START,
    });
    const response = await fetchGraphMethod(data.query, data.variables, true);
    if (response && response.data && response.status === 200) {
      const allAppointments = response.data.data.allAppointments.Appointments
        ? response.data.data.allAppointments.Appointments
        : [];
      let finalAppointment = [];
      let requestAppointmentFlag = true;
      for (const appointment of allAppointments) {
        let {
          appointmentdoctoruseridmaprel,
          appointmentslotsidmaprel,
          ...details
        } = { ...appointment };
        // if (
        //   details.acceptstatus === "pending" ||
        //   details.acceptstatus === "accepted"
        //  ||
        // details.acceptstatus === "cancel"
        // ) {
        //   requestAppointmentFlag = false;
        // }
        let doctorInfo = null;
        let slotInfo = null;
        const slot = appointmentslotsidmaprel
          ? appointmentslotsidmaprel.Doctorslots[0]
            ? appointmentslotsidmaprel.Doctorslots[0]
            : null
          : null;
        const doctor = appointmentdoctoruseridmaprel
          ? appointmentdoctoruseridmaprel.Userdata[0]
            ? appointmentdoctoruseridmaprel.Userdata[0]
            : null
          : null;
        if (slot) {
          slotInfo = { ...slot };
        }
        if (doctor) {
          doctorInfo = { ...doctor };
        }
        finalAppointment = [
          ...finalAppointment,
          { details, doctorInfo, slotInfo },
        ];
      }
      dispatch({
        type: GET_APPOINTMENTS_SUCCESS,
        payload: { data: finalAppointment, requestAppointmentFlag },
      });
    } else {
      messagePopup("", "Get Appointments Failed", "error");
      dispatch({
        type: GET_APPOINTMENTS_FAILED,
        payload: "Get Appointments Failed",
      });
    }
  } catch (error) {
    messagePopup("", "Get Appointments Failed", "error");
    dispatch({
      type: GET_APPOINTMENTS_FAILED,
      payload: "Get Appointments Failed",
    });
  }
};

export const cancelAppointmentsStart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: CANCEL_APPOINTMENT_START,
    });
    const { query, variables, allAppointments } = data;
    const response = await fetchGraphMethod(data.query, data.variables, true);
    if (response && response.status === 200) {
      if (
        response.data &&
        response.status === 200
      ) {
        dispatch({
          type: CANCEL_APPOINTMENT_SUCCESS
        });
        messagePopup("", 'Appointment Cancelled successfully', "success");
        allAppointments();
      }
    } else {
      messagePopup("", "Cancel Appointment Failed", "error");
      dispatch({
        type: CANCEL_APPOINTMENT_FAILED,
        payload: "Cancel Appointment Failed",
      });
    }
  } catch (error) {
    messagePopup("", "Cancel Appointment Failed", "error");
    dispatch({
      type: CANCEL_APPOINTMENT_FAILED,
      payload: "Cancel Appointment Failed",
    });
  }
};

export const requestAppointmentsStart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_APPOINTMENT_START,
    });
    const { userId, createdOn, allAppointments } = data;
    const response = await fetchMethod(
      "/api/Appointments/requestForAppointment",
      { userId, createdOn },
      "post",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 201
      ) {
        messagePopup("", response.data.response.Message, "error");
        dispatch({
          type: REQUEST_APPOINTMENT_FAILED,
          payload: response.data.response.Message,
        });
      }
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        messagePopup("", response.data.response.Message, "success");
        allAppointments();
      }
    } else {
      messagePopup("", "Request Appointment Failed", "error");
      dispatch({
        type: REQUEST_APPOINTMENT_FAILED,
        payload: "Request Appointment Failed",
      });
    }
  } catch (error) {
    messagePopup("", "Request Appointment Failed", "error");
    dispatch({
      type: REQUEST_APPOINTMENT_FAILED,
      payload: "Request Appointment Failed",
    });
  }
};

export const bookAppointmentsStart = (data) => async (dispatch) => {
  console.log("i am book")
  try {
    dispatch({
      type: BOOK_APPOINTMENT_START,
    });
    const {
      sessionId,
      allAppointments
    } = data;
    const response = await fetchMethod(
      "/api/Appointments/bookAppointment",
      { sessionId },
      "post",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 201
      ) {
        messagePopup("", response.data.response.Message, "error");
        dispatch({
          type: BOOK_APPOINTMENT_FAILED,
          payload: response.data.response.Message,
        });
      }
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        messagePopup("", response.data.response.Message, "success");
        allAppointments();
      }
    } else {
      messagePopup("", "Book Appointment Failed", "error");
      dispatch({
        type: BOOK_APPOINTMENT_FAILED,
        payload: "Book Appointment Failed",
      });
    }
  } catch (error) {
    messagePopup("", "Book Appointment Failed", "error");
    dispatch({
      type: BOOK_APPOINTMENT_FAILED,
      payload: "Book Appointment Failed",
    });
  }
};

export const reRequestAppointmentsStart = (data) => async (dispatch) => {
  try {
    dispatch({
      type: REQUEST_APPOINTMENT_START,
    });
    const { allAppointments, ...restData } = data;
    const response = await fetchMethod(
      "/api/Appointments/reRequestAppointment",
      restData,
      "post",
      true
    );
    if (response && response.status === 200) {
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 201
      ) {
        messagePopup("", response.data.response.Message, "error");
        dispatch({
          type: REQUEST_APPOINTMENT_FAILED,
          payload: response.data.response.Message,
        });
      }
      if (
        response.data &&
        response.data.response &&
        response.data.response.status === 200
      ) {
        messagePopup("", response.data.response.Message, "success");
        allAppointments();
      }
    } else {
      messagePopup("", "Request Appointment Failed", "error");
      dispatch({
        type: REQUEST_APPOINTMENT_FAILED,
        payload: "Request Appointment Failed",
      });
    }
  } catch (error) {
    messagePopup("", "Request Appointment Failed", "error");
    dispatch({
      type: REQUEST_APPOINTMENT_FAILED,
      payload: "Request Appointment Failed",
    });
  }
};
export const getLatestmessageDate = (data) => async (dispatch) => {
  try {
    dispatch({
      type: GET_LATEST_MESSAGE_START,
    })
    const response = await fetchMethod("/api/Chathistorydata/fetchLatestChat", { array: data }, 'post', true)
    if (response.status === 200 && response.data.response.status === 200 && response.data.response.data) {
      dispatch({
        type: GET_LATEST_MESSAGE_SUCCESS,
        payload: response.data.response.data
      })
    }
    else {
      dispatch({
        type: GET_LATEST_MESSAGE_FAILED,
        payload: 'ERROR'
      })
    }

  } catch (error) {
    dispatch({
      type: GET_LATEST_MESSAGE_FAILED,
      payload: 'ERROR'
    })
  }
}
