import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { SIGNUP_FAILED, SIGNUP_START, SIGNUP_SUCCESS } from '../types';
import history from '../../../history'

export const signUpStart = (data) => async (dispatch) => {
    dispatch({
        type: SIGNUP_START
    })
    try {
        const response = await fetchMethod(`/api/Userdata/createUser`,{
          firstName: data.firstName,
          lastName: data.lastName,
          email: data.email,
          role: data.role,
          phoneNumber: data.phoneNumber,
          // gender
        },'post',false);
        if(response && response.data && response.data.response){
          if(response.data.response.status === 201){
            messagePopup('', response.data.response.Message, 'error');
            dispatch({
                type: SIGNUP_FAILED,
                payload: response.data.response.Message
            })
          }
          if(response.data.response.status === 200){
            dispatch({
                type: SIGNUP_SUCCESS,
                payload: 'Temporary Password has been sent to your email'
            })
            messagePopup('Signed Up Successfully', 'Temporary Password has been sent to your email', 'success');
            data.clearSignup();
            data.openLoginModal();
          }
          
        } else {
            messagePopup('', 'SignUp failed', 'error');
            dispatch({
                type: SIGNUP_FAILED,
                payload: 'SignUp  failed'
            })
        }
      } catch (error) {
        messagePopup('', 'SignUp failed', 'error');
        dispatch({
            type: SIGNUP_FAILED,
            payload: 'SignUp  failed'
        })
      }
};