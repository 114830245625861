export const saveUserChatDataQuery = `
  mutation saveChathistorydata($obj:ChathistorydataInput!){
    saveChathistorydata(obj:$obj){
      id
    }
  }
  `;
export const getUserDoctorChatData = (roomId) => `
{
    allChathistorydata(where:{ or: [{ doctorroomname: "${roomId}" }, { userroomname: "${roomId}" }] }){
      Chathistorydata{
        usermessage
        doctormessage
        createdon
      }
    }
  }
  `;