import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import {GET_CATEGORY_TESTS_START, GET_CATEGORY_TESTS_FAILED, GET_CATEGORY_TESTS_SUCCESS } from '../types';


export const getcategoryBasedTestList = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_CATEGORY_TESTS_START
        })
        const response = await fetchMethod(`/getCategoryBasedTest?categoryName=${data.category}`,null,'get',false)
        if(response && response.status === 200){
            if(response.data && response.data.status === 200) {
                const allTests = response.data.data;
                dispatch({
                    type: GET_CATEGORY_TESTS_SUCCESS,
                    payload: allTests
                })
            } else{
                messagePopup('','Get Test List Failed','error');
                dispatch({
                    type: GET_CATEGORY_TESTS_FAILED,
                    payload: 'Get Test List Failed'
                })
            }
        } else {
            messagePopup('','Get Test List Failed','error');
            dispatch({
                type: GET_CATEGORY_TESTS_FAILED,
                payload: 'Get Test List Failed'
            }) 
        }
    } catch (error) {
        messagePopup('','Get Test List Failed','error');
        dispatch({
            type: GET_CATEGORY_TESTS_FAILED,
            payload: 'Test List Failed'
        })
    }
};
