import React, { Component } from "react";
import "../../assets/css/product-details.scss";
import microscopeSvg from "../../assets/img/microscope.svg";
import indexfingerSvg from "../../assets/img/indexfinger.svg";
import kitLgSvg from "../../assets/img/kit-lg.png";
import flaskSvg from "../../assets/img/flask.svg";
import freeShippingSvg from "../../assets/img/free-shipping.svg";
import securitySvg from "../../assets/img/security.svg";
import kitPng from "../../assets/img/kit.png";
import messageSvg from "../../assets/img/message.svg";
import iconFeatherArrowLeftSvg from "../../assets/img/icon-feather-arrow-left.svg";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import { Carousel } from "react-responsive-carousel";
import { NavLink } from "react-router-dom";
import {
  getTest,
  getTestHardcoded,
} from "../../Redux/actions/Tests/testDetails";
import { connect } from "react-redux";
import messagePopup from "../../Services/messagePopupService";
import BackdropLoader from "../../Services/loader";
import { getTestListQuery } from "../Home/HomeQuery";
import { getTestList } from "../../Redux/actions/Tests/tests";
import { getAuthToken, getUserInfo } from "../../Services/authService";
import {
  getAllShoppingCartsQuery,
  saveShoppingCartDataQuery,
} from "../Shopping-Cart/ShoppingCartQuery";
import {
  addShoppingCartData,
  addUserShoppingCartData,
  getUserShoppingCartData,
} from "../../Redux/actions/Shopping-Cart/actions";
import Login from "../../../src/Common/Login/login";

class ProductDetail extends Component {
  constructor(props) {
    super(props);
    this.state = {
      quantity: "1",
      queryParam: "",
    };
  }

  initialCall = () => {
    this.props.handleGetTestList();
    // if(getAuthToken()) {
    //     const variables= {
    //       obj: {
    //         active: 1,
    //         userid: getUserInfo()?.id
    //       }
    //     }
    //     this.props.getUserShoppingCartData({
    //       query: {
    //         getCartQuery: getAllShoppingCartsQuery,
    //         getTestQuery: getTestListQuery
    //       },
    //       variables
    //     })
    // }
    const testId = this.props.match
      ? this.props.match.params
        ? +this.props.match.params.id
        : 0
      : 0;
    if (testId) {
      this.props.getTestDetails({ testId });
    } else {
      messagePopup("", "Test Id is invalid", "error");
    }
  };

  componentDidMount = () => {
    const myParam = this.props.location
      ? this.props.location.search
        ? new URLSearchParams(this.props.location.search).get("from")
        : ""
      : "";
    this.setState({ queryParam: myParam });
    this.initialCall();
  };
  getFinalBreadcrumb = (flag) => {
    switch (flag) {
      case "men":
        return {
          label: "Men's health test",
          link: "/men_health_tests",
        };
      case "women":
        return {
          label: "Women's health test",
          link: "/women_health_tests",
        };
      case "wellness":
        return {
          label: "Wellness tests",
          link: "/wellness_tests",
        };

      default:
        return {
          label: "Shop All Tests",
          link: "/shop_all_test",
        };
    }
  };

  componentDidUpdate(prevProps) {
    if (prevProps.match.params.id !== this.props.match.params.id) {
      this.initialCall();
    }
  }

  handleAddToCart = (data, isQuantity) => {
    if (getAuthToken()) {
      if (!this.props.cartDetails.data.map(val => val.id).includes(data.id)) {
        const variables = {
          testid: data.id,
          quantity: isQuantity ? this.state.quantity : "1",
          userid: getUserInfo().id,
        };
        messagePopup('', 'Item added Successfully', 'success')
        this.props.addUserShoppingCartData({
          query: saveShoppingCartDataQuery,
          variables,
          cuurentcartCount: this.props.cartDetails.cartCount,
        });
      }
      else {
        messagePopup('', 'test added already', 'error')
      }
    } else {
      this.props.addToCart({
        ...data,
        quantity: isQuantity ? this.state.quantity : "1",
      });
    }
  };

  dropDownChange = (e) => {
    this.setState({ quantity: e.target.value });
  };

  scrollDown = () => {
    window.scrollTo({
      top: 0
    });
  };

  render() {
    return (
      <div className="pageWrap" style={{ marginTop: "85px" }}>
        <BackdropLoader
          open={
            this.props.testDetails.loading || this.props.cartDetails.loading
          }
        />
        <section className="productdetails" id="productdetails">
          {/* <!-- breadcumb starts here -->	 */}
          <section className="breadcumb" style={{ marginBottom: "0" }}>
            <div className="container">
              <ul>
                <li>
                  <NavLink to="/">Home</NavLink>
                </li>
                <li>
                  <NavLink
                    to={`${this.getFinalBreadcrumb(this.state.queryParam).link
                      }`}
                  >
                    {this.getFinalBreadcrumb(this.state.queryParam).label}
                  </NavLink>
                </li>
                <li>
                  <NavLink to="/test_details/3">
                    {this.props.testDetails.data
                      ? this.props.testDetails.data.testname
                      : "Vitamin B12"}
                  </NavLink>
                </li>
              </ul>
            </div>
          </section>

          {/* <!-- product highlight banner starts here --> */}
          <section className="producthighlight">
            <div className="container">
              <div className="row">
                <div className="col-md-6 col-lg-7">
                  <div className="productdtls">
                    <h1>
                      {this.props.testDetails.data
                        ? this.props.testDetails.data.testname
                        : ""}
                    </h1>
                    <p>
                      {this.props.testDetails.data
                        ? this.props.testDetails.data.longdescription
                          ? this.props.testDetails.data.longdescription
                            .split("\n")
                            .map((item, index) => (
                              <span key={index}>
                                {item} <br />
                              </span>
                            ))
                          : ""
                        : ""}
                    </p>
                    <div className="testdetail">
                      <div className="testdetailimg">
                        <img src={microscopeSvg} alt="microscope" />
                      </div>
                      <div className="quesanw">
                        <div className="testques">What is tested?</div>
                        <div className="testans">
                          {this.props.testDetails.data
                            ? this.props.testDetails.data.whattest
                            : ""}
                        </div>
                      </div>
                    </div>
                    <div className="testdetail">
                      {this.props.testDetails.data &&
                        this.props.testDetails.data.howtested &&
                        this.props.testDetails.data.howtested
                          .toLowerCase()
                          .includes("finger prick") && (
                          <div className="testdetailimg">
                            <img src={indexfingerSvg} alt="indexfinger" />
                          </div>
                        )}

                      <div className="quesanw">
                        <div className="testques">How is it tested? </div>
                        <div className="testans">
                          {this.props.testDetails.data
                            ? this.props.testDetails.data.howtested
                            : ""}
                        </div>
                      </div>
                    </div>

                    <div className="productprice">
                      {this.props.testDetails.data
                        ? `AED ${this.props.testDetails.data.price}`
                        : ""}
                    </div>
                    <div className="cart">
                      <ul>
                        {/* <li>
                                                <div className="select-dropdown">
                                                    <select onChange={this.dropDownChange}>
                                                        <option value="1">1</option>
                                                        <option value="2">2</option>
                                                        <option value="3">3</option>
                                                    </select>
                                                </div>
                                            </li> */}
                        {getAuthToken() ?
                          <li>
                            <button onClick={() => this.handleAddToCart(this.props.testDetails.data, true)}>Add to cart</button>
                          </li> :
                          <Login btnType="login" >
                            <li>
                              <button onClick={() => this.handleAddToCart(this.props.testDetails.data, true)}>Add to cart</button>
                            </li>
                          </Login>
                        }
                      </ul>
                    </div>
                  </div>
                </div>

                <div className="col-md-6 col-lg-5">
                  <div className="productslide">
                    <Carousel
                      showThumbs={false}
                      autoPlay={true}
                      infiniteLoop={true}
                      showStatus={false}
                    >
                      <div>
                        {this.props.testDetails.data && this.props.testDetails.data.filepath
                          ? <img
                            src={this.props.testDetails.data.filepath}
                            alt="product-images"
                            width="370"
                            height="317"
                          /> :
                          <img
                            src={kitLgSvg}
                            alt="product-imagees"
                            width="370"
                            height="317"
                          />
                        }
                      </div>
                      <div>
                        {this.props.testDetails.data && this.props.testDetails.data.filepath
                          ? <img
                            src={this.props.testDetails.data.filepath}
                            alt="product-images"
                            width="370"
                            height="317"
                          /> : <img
                            src={kitLgSvg}
                            alt="product-imagees"
                            width="370"
                            height="317"
                          />
                        }
                      </div>
                      <div>
                        {this.props.testDetails.data && this.props.testDetails.data.filepath
                          ? <img
                            src={this.props.testDetails.data.filepath}
                            alt="product-images"
                            width="370"
                            height="317"
                          /> : <img
                            src={kitLgSvg}
                            alt="product-imagees"
                            width="370"
                            height="317"
                          />
                        }
                      </div>
                    </Carousel>
                  </div>
                </div>
              </div>
            </div>
          </section>

          <section className="shipping">
            <div className="container">
              <h1 style={{ fontSize: '30px', fontWeight: '600', marginBottom: '30px' }}>Whats included</h1>
              <div className="row">
                <div className="col-md-4 col-sm-12">
                  <div className="shippingwrap">
                    <div className="shippingimg">
                      <img src={flaskSvg} alt="" />
                    </div>
                    {/* <h2>Complete test kit with everything you need to collect your sample at home</h2> */}
                    <p>
                      Complete test kit with everything you need to collect your sample at home
                    </p>
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="shippingwrap">
                    <div className="shippingimg">
                      <img src={freeShippingSvg} alt="" />
                    </div>
                    {/* <h2>Free shipping</h2> */}
                    <p>Free shipping</p>
                    {/* <p>
                      We provide free shipping both ways for every kit
                      purchased.
                    </p> */}
                  </div>
                </div>
                <div className="col-md-4 col-sm-12">
                  <div className="shippingwrap">
                    <div className="shippingimg">
                      <img src={securitySvg} alt="" />
                    </div>
                    {/* <h2>Physician reviewed results</h2> */}
                    <p>Physician reviewed results</p>
                    {/* <p>
                      Licensed physicians will review your results should they
                      deviate from normal ranges.
                    </p> */}
                  </div>
                </div>
              </div>
            </div>
          </section>
          {/* {this.props.testDetails.data &&
            this.props.testDetails.data.whatincluded && (
              <section className="testinfo" style={{ paddingBottom: "10px" }}>
                <div className="container">
                  <div className="testinfowrap">
                    <h3>Whats Included</h3>
                    <p>Add images</p>
                    {this.props.testDetails.data.whatincluded
                      .split("\n")
                      .map((item, index) => (
                        <p key={index} style={{ marginTop: "0" }}>
                          {item}
                        </p>
                      ))}
                  </div>
                </div>
              </section>
            )} */}

          {this.props.testDetails.data &&
            this.props.testDetails.data.understandtestlevel && (
              <section className="testinfo">
                <div className="container">
                  <div className="testinfowrap">
                    <h3>
                      Why should I understand my{" "}
                      {this.props.testDetails.data.testname} levels
                    </h3>
                    <p>{this.props.testDetails.data.understandtestlevel}</p>
                  </div>
                </div>
              </section>
            )}

          {this.props.testDetails.data &&
            this.props.testDetails.data.symptomsoftest && (
              <section className="symptoms">
                <div className="symptonswrap">
                  <h3>
                    What are some of the symptoms if you have too much or too
                    little {this.props.testDetails.data.testname}?
                  </h3>
                  <ul>
                    {this.props.testDetails.data.symptomsoftest
                      .split("\n")
                      .map((item, index) => (
                        <li key={index}>{item}</li>
                      ))}
                  </ul>
                </div>
              </section>
            )}

          {this.props.testDetails.data && this.props.testDetails.data.id === 3 && (
            <section className="testinfo" style={{ paddingTop: "10px" }}>
              <div className="container">
                <div className="testinfowrap">
                  <h3 style={{ textDecoration: "underline" }}>
                    Note I took out the header
                  </h3>
                  <p>
                    Your doctor may suggest to have your cholesterol checked if
                    you have a family history of high cholesterol
                  </p>
                </div>
              </div>
            </section>
          )}

          <section className="productlisting">
            <div className="container">
              <h2>Understand more about your health</h2>
              <div className="productitems">
                {this.props.tests.data &&
                  this.props.tests.data.slice(0, 4).map((item, index) => (
                    <div className="productbox" key={index}>
                      <div className="productimg">
                        <NavLink onClick={this.scrollDown} to={`/test_details/${item.id}`}>
                          {item.filepath ?
                            <img src={item.filepath} alt="productimage" /> :
                            <img src={kitPng} alt="productimage" />
                          }
                        </NavLink>
                      </div>
                      <div className="productname">
                        <NavLink onClick={this.scrollDown} to={`/test_details/${item.id}`}>
                          {item.testname}
                        </NavLink>
                      </div>
                      <div className="productprice">AED {item.price}</div>
                      <div className="productdetail">{item.description}</div>
                      {getAuthToken() ?
                        <button onClick={() => this.handleAddToCart(item, false)}>Add to cart <img src={iconFeatherArrowLeftSvg} alt="" /></button> :
                        <Login btnType="login" >
                          <button onClick={() => this.handleAddToCart(item, false)}>Add to cart <img src={iconFeatherArrowLeftSvg} alt="" /></button>
                        </Login>
                      }
                    </div>
                  ))}
              </div>
            </div>
          </section>

          <section className="contactus">
            <div className="container">
              <div className="contactwrap">
                <img src={messageSvg} alt="message" />
                <p>If you have any further questions contact us on</p>
                <NavLink to="mailto:info@thulsee.com">info@thulsee.com</NavLink>
              </div>
            </div>
          </section>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    testDetails: state.testDetails,
    tests: state.tests,
    cartDetails: state.cartDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getTestDetails: (data) => dispatch(getTest(data)),
    handleGetTestList: () => dispatch(getTestList()),
    addToCart: (data) => dispatch(addShoppingCartData(data)),
    addUserShoppingCartData: (data) => dispatch(addUserShoppingCartData(data)),
    getUserShoppingCartData: (data) => dispatch(getUserShoppingCartData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ProductDetail);
