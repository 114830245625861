//prod
export const API_URL = "https://api.thulsee.com:8450"; //"http://104.45.198.20:8450";
//export const API_URL = "http://localhost:8450" ;
export const GRAPHQL_URL = API_URL + "/graphql";
 

export const URLDATA = {
  url: API_URL + "/graphql",
  queryFile: ""
};
export const Image_GETURL = API_URL + "/api/containers/images/download/";

export const mobileNumRegex = new RegExp("^[0-9]{10}$"); // new RegExp("^[0-9]{8,10}$");
export const postCodeRegex = new RegExp("^[0-9]{4}$");
export const emailRegex = new RegExp(
  /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
);

export const nameRegex = new RegExp("^[a-zA-Z ]*$") // {1,40}
