import { fetchGraphMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { GET_USER_INFO_FAILED, GET_USER_INFO_START, GET_USER_INFO_SUCCESS } from '../types';


export const getUserData = (data) => async (dispatch) => {
    dispatch({
        type: GET_USER_INFO_START
    })
    try {
        const response = await fetchGraphMethod(data.query,data.variables,true);
        if(response && response.status === 200){
            if(response.data) {
                const userdata = response.data.data.allUserdata.Userdata[0];
                dispatch({
                    type: GET_USER_INFO_SUCCESS,
                    payload: userdata
                })
            } else{
                messagePopup('','Get User Info Faile','error');
                dispatch({
                    type: GET_USER_INFO_FAILED,
                    payload: 'Get User Info Failed'
                })
            }
        } else {
            messagePopup('','Get User Info Failed','error');
            dispatch({
                type: GET_USER_INFO_FAILED,
                payload: 'Get User Info Failed'
            }) 
        }
    } catch (error) {
       messagePopup('','Get User Info Failed','error');
       dispatch({
           type: GET_USER_INFO_FAILED,
           payload: 'Get User Info Failed'
       })
    }
};