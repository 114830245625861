export const saveShoppingCartDataQuery = `
mutation saveCart($obj: CartInput!){
  saveCart(obj: $obj){
    quantity
    testid
    id
    userid
  }
} 
`;

export const getAllShoppingCartsQuery = `
query allCarts($obj: JSON){
    allCarts(where: $obj){
        totalCount
        Carts{
            quantity
            testid
            id
            userid
            carttestidmaprel{
              Tests{
                testname
                price
              }
            }
    }
  }
} 
`

export const getAllShoppingCartDetailsQuery = `
query allCarts($obj: JSON){
    allCarts(where: $obj){
        totalCount
        Carts{
          quantity
          testid
          id
          userid
          carttestidmaprel{
            Tests{
              description
              id
              price
              testname
              active
            }
          }
        }
  }
} 
`

