import history from '../../../history';
import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { SAVE_REGISTER_KIT_FAILED, SAVE_REGISTER_KIT_START, SAVE_REGISTER_KIT_SUCCESS } from '../types';


export const saveRegisterKit = (data) => async (dispatch) => {
    dispatch({
        type: SAVE_REGISTER_KIT_START
    })
    try {
        
        const response = await fetchMethod(`/api/Userdata/RegisterKit`,data,'post',true);
        
        if(response && response.data && response.status === 200){
           if(response.data.response && response.data.response.status === 201){
            messagePopup('',response.data.response.msg,'error');
            dispatch({
                type: SAVE_REGISTER_KIT_FAILED,
                payload: response.data.response.msg
            })  
           }
           if(response.data.response && response.data.response.status === 200){
            messagePopup('',response.data.response.msg,'success');
            dispatch({
                type: SAVE_REGISTER_KIT_SUCCESS,
                payload: response.data.response.msg
            }) 
            history.push('/') 
            // resetForm();
           }
        } else {
            messagePopup('','Register Kit Failed','error');
            dispatch({
                type: SAVE_REGISTER_KIT_FAILED,
                payload: 'Register Kit Failed'
            }) 
        }
    } catch (error) {
       messagePopup('','Register Kit Failed','error');
       dispatch({
           type: SAVE_REGISTER_KIT_FAILED,
           payload: 'Register Kit Failed'
       })
    }
};