import React from "react";
import "./inputChatMessages.css";
import ReactEmoji from "react-emoji";

const MessageRow = ({ message }) => {
  return message.usermessage ? (
    <div className="showMsg">
      <div className="messageContainer justifyEnd">
        <h4 className="sentText pr-10"></h4>
        <div className="messageBox backgroundBlue">
          <h4 className="messageText colorWhite">
            {ReactEmoji.emojify(message.usermessage)}
          </h4>
        </div>
      </div>
      <p className="dispayDate">
        {message.createdon === undefined
          ? new Date()
              .toLocaleString()
              .split(",")[1]
              .split(" ")[1]
              .slice(0, -3) +
            " " +
            new Date().toLocaleString().split(",")[1].split(" ")[2]
          : new Date(message.createdon)
              .toLocaleString()
              .split(",")[1]
              .split(" ")[1]
              .slice(0, -3) +
            " " +
            new Date(message.createdon)
              .toLocaleString()
              .split(",")[1]
              .split(" ")[2]}
      </p>
    </div>
  ) : (
    <div className="showMsg">
      <div className="messageContainer justifyStart">
        <div className="messageBox backgroundLight">
          <h4 className="messageText colorDark">
            {ReactEmoji.emojify(
              message.doctormessage ? message.doctormessage : ""
            )}
          </h4>
        </div>
      </div>
      <p className="dispayDateleft">
        {message.createdon === undefined
          ? new Date()
              .toLocaleString()
              .split(",")[1]
              .split(" ")[1]
              .slice(0, -3) +
            " " +
            new Date().toLocaleString().split(",")[1].split(" ")[2]
          : new Date(message.createdon)
              .toLocaleString()
              .split(",")[1]
              .split(" ")[1]
              .slice(0, -3) +
            " " +
            new Date(message.createdon)
              .toLocaleString()
              .split(",")[1]
              .split(" ")[2]}
      </p>
    </div>
  );
};

export default MessageRow;
