import React, { Component } from 'react';
import {NavLink} from "react-router-dom";


class Cookie extends Component {
  render() {
    return (
      <div className="pageWrap" style={{ marginTop: "85px" }}>
        {/* <div style={{marginTop:"200px"}}><h1>Terms</h1></div>*/}
        <main className="otherPage">
          <div className="breadcumb">
            <div className="container">
              <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/Cookie">Cookie</NavLink></li>
              </ul>
            </div>
          </div>

          <section className="checkout">
            <div className="container">
              <div className="heading">
                <div className="headshop">
                  <h1>Thulsee Cookie Policy</h1><br></br>
                  <h3>What are cookies?</h3><br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li>Cookies are small text files placed on your device (such as your computer, tablet or mobile phone) when you visit our Website. We use cookies in order to offer you a more tailored experience in the future, by understanding and remembering your particular browsing preferences.</li>
                    <li>Cookies help us provide you with the best possible experience. When you re-visit our Website, cookies allow our Website to recognise you, remember your preferences and tailor your use of our Website. Information provided by cookies can help us to analyse your use of our Website and help us to provide you with a better user experience.</li>
                    <li>Cookies are referred to as either "session" or "persistent" cookies, depending on how long they are used for: 
                        <ul style={{ liststyleposition: 'outside' }}><li>Session cookies only last for the duration of your online session and disappear from your device when you close your browser. Session cookies are not stored on the hard drive of your device.</li>
                        <li>Persistent cookies are stored on the hard drive of your device after the browser has been closed and last until you delete them or they reach their expiry date. Persistent cookies are activated each time you visit the site where the cookie was generated.</li>
                        </ul>
                    </li>
                    <li>For more information, please see <a href="https://www.allaboutcookies.org.">www.allaboutcookies.org.</a></li>
                  </ul><br></br>
                  <h3>What types of cookies do we use?</h3><br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li>The types of cookies that may be used on our Website fall into one of four categories as follows:
                    <ul style={{ liststyleposition: 'outside' }}>
                        <li>'Necessary' cookies. These cookies are essential for the operation of our Website and enable you to access certain features. Without these cookies, we would be unable to provide you with the online services you request, for example, remembering your shopping basket and logging into your account. These cookies do not track where you have been on the internet and do not gather information about you that could be used for marketing purposes.</li>
                        <li>Functional cookies. Functional cookies are used to recognise you when you return to our Website. They also remember your preferences (such as your username, language and region) on our Website and enable us to personalise our content for you. The information collected by these cookies is usually anonymised, so we cannot identify you personally. Functional cookies do not track your internet usage or gather information which could be used for selling advertising, but they do help with serving advertising.</li>
                        <li>Analytical cookies. Analytical cookies are used to monitor the performance of our Website. For example, they allow us to recognise and count the number of visitors and to see how visitors move around our Website when they are using it. The information provided by these cookies allows us to analyse patterns of user behaviour and we use that information to enhance user experience or identify areas of our Website which may require maintenance. All of the information collected by these cookies is anonymous and is only used for statistical purposes.</li>
                        <li>Marketing cookies. These cookies may be placed on your device by us or by trusted third parties. They remember that you have visited our Website and use that information to provide you with advertising which is tailored to your interests. This is often called online behavioural advertising (OBA) and involves the monitoring of interests based upon web browsing history. Your web browsing history can be used to infer things about you (for example, your age, gender etc.), and this information may also be used to make advertising on websites more relevant to you. Although behavioural advertising cookies can track your activity around the internet they cannot identify you personally, even if you are signed in to our Website. Without these cookies, online advertisements you encounter will be less relevant to you and your interests.</li>
                    </ul>
                    </li>
                    <li>Our emails and Website pages may contain a web beacon to track whether you open an email and if you click on any of the links in the email or Website page. We may use this information to help us analyse the types of topics that you are interested in, and to let our advertisers know how many of our anonymised, aggregated users click on their advertisements. When you delete the email, the web beacon will be deleted.</li>
                    <li>When you browse our Website some cookies may be set by third parties, over which we have no control. When you visit a page with embedded third party content, the service provider may set their own cookies on your device. You may also encounter third party cookies resulting from our use of third party services, which help us monitor traffic on our Website. We do not control the use of these cookies and you should refer to the relevant third party websites for more information.</li><br></br>
                  </ul>
                  <h3>How can I manage or disable cookies?</h3><br></br>
                  
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li>There are a number of ways for you to manage cookies. Your browser settings should allow you to accept or reject cookies and you can set your browser to prompt you before accepting a cookie.</li>
                    <li>You can delete any cookies that have been installed in the cookie folder of your browser. The various browsers provide different procedures to manage your settings. Most browsers will have a help feature or menu that will tell you how to delete or disable cookies. You can also delete similar data used by browser add-ons by changing the settings of your add-on or visiting the manufacturer's website.</li>
                    <li>Please be aware that if you set your browser to disable cookies entirely it may affect the way our Website operates and you may not be able to use certain features of our Website.</li><br></br>
                  </ul>
                    <h1>Contact us</h1><br></br>
                    <p>If you have any queries relating to this Cookie Policy please contact us at <a href="mailto:hello@thulsee.com"> hello@thulsee.com.</a></p>            
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Cookie;