import React, { Component } from 'react';
import '../../assets/css/tests-detail.css'
import testList from '../../dummy/testDetails.json';


class TestDetails extends Component {
    state = {
        textColor: false
    }

    render(){
        return(
            <div>
                <main className="testdetails">
                    <section className="testdetailwrap">
                        <div className="container">
                            <div className="row">
                                <div className="col-12">
                                    <div className="detailscnt">
                                        <div className="heading">
                                            <h1>Tests Details</h1>
                                            <p>Sample ID: 324SDAD321ASD32</p>
                                        </div>
                                        
                                        <div className="accordion" id="faqs">
                                            <div className="card">
                                                <div className="card-header" id="h1" data-toggle="collapse" data-target="#q1" aria-expanded="true">
                                                    <div className="head"><p>Indoor & Outdoor Allergy</p></div>
                                                    <div className="resultpiority"><p>High Reactivity</p></div>
                                                </div>
                                                <div id="q1" className="collapse show" aria-labelledby="h1" data-parent="#faqs">
                                                    <div className="card-body">
                                                        <div className="table-responsive">
                                                            <table className="table table-hover">
                                                                <thead>
                                                                    <tr>
                                                                        <th scope="col">Parameters</th>
                                                                        <th scope="col">Value</th>
                                                                        <th scope="col">Range</th>
                                                                        <th scope="col">Comments</th>
                                                                    </tr>
                                                                </thead>

                                                                {testList.map((test,index) => (
                                                                <tbody key={index}>
                                                                    <tr>
                                                                        <td>{test.Parameters}</td>
                                                                        <td>{test.values}</td>
                                                                        <td>{test.range}</td>
                                                                        <td>{test.comments}</td>
                                                                    </tr>
                                                                    {/* <tr>
                                                                        <td>Beech Tree</td>
                                                                        <td className="txtred">12</td>
                                                                        <td>0 - 23</td>
                                                                        <td>--</td>
                                                                    </tr> */}
                                                                </tbody>
                                                                ))}
                                                            </table>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            <div className="card">
                                                <div className="card-header collapsed" id="h2" data-toggle="collapse" data-target="#q2" aria-expanded="false">
                                                    <div className="head"><p>Food Sensitivity</p></div>
                                                    <div className="resultpiority"><p>High Reactivity</p></div>
                                                </div>
                                                <div id="q2" className="collapse" aria-labelledby="h2" data-parent="#faqs">
                                                    <div className="card-body">
                                                        <p>Lorem ipsum dolor sit amet, consetetur sadipscing elitr, sed diam nonumy eirmod tempor invidunt ut labore et dolore magna aliquyam erat, sed diam voluptua. At vero eos et accusam et justo duo dolores et ea rebum.</p>
                                                    </div>
                                                </div>
                                            </div>     
                                        </div> 

                                    </div> 
                                </div> 
                            </div>
                        </div> 
                    </section> 
                </main>
            </div>
        )
    }
}


export default TestDetails;