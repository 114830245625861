import { Component } from "react";
import "./css/home.scss";
import { NavLink } from "react-router-dom";
import Banner from "./img/main_banner_updated.png";
import SwiperCore, { Navigation } from "swiper";
import { Swiper, SwiperSlide } from "swiper/react";
import "swiper/swiper.scss";
import CartArrow from "./img/cart_arrow.svg";
import { Button } from "@material-ui/core";
import Art1 from "./img/artifact1.svg";
import Art2 from "./img/artifact2.svg";
import Art3 from "./img/artifact3.svg";
import WhyDoThis from "./img/why_do_this.png";
import LinkArrow from "./img/linkArrow.png";
import PRODUCT_DETAILS from "../../Common/SharedData/productDetails";
import BackdropLoader from "../../Services/loader";
import { connect } from "react-redux";
import { getTestList } from "../../Redux/actions/Tests/tests";
import { getTestListQuery } from "./HomeQuery";
import productImg from "../../assets/img/kit.png";
import {
  addShoppingCartData,
  addUserShoppingCartData,
  getUserShoppingCartData,
} from "../../Redux/actions/Shopping-Cart/actions";
import { getAuthToken, getUserInfo } from "../../Services/authService";
import {
  getAllShoppingCartsQuery,
  saveShoppingCartDataQuery,
} from "../Shopping-Cart/ShoppingCartQuery";
import Login from "../../../src/Common/Login/login"
import Tooltip from "@material-ui/core/Tooltip";
import swal from "sweetalert";
import messagePopup from "../../Services/messagePopupService";
import doctorImages from "./img/doctor_image.png";
import labImage from "./img/lab_images.png"

SwiperCore.use([Navigation]);

class Home extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }
  componentDidMount = () => {
    this.props.handleGetTestList();
    if (getAuthToken()) {
      const variables = {
        obj: {
          active: 1,
          userid: getUserInfo()?.id,
        },
      };
      this.props.getUserShoppingCartData({
        query: {
          getCartQuery: getAllShoppingCartsQuery,
          getTestQuery: getTestListQuery,
        },
        variables,
      });
    }
  };

  handleAddToCart = (data) => {
      if (getAuthToken()) {
        if(!this.props.cartDetails.data.map(val=>val.id).includes(data.id)){
        const variables = {
          testid: data.id,
          quantity: "1",
          userid: getUserInfo().id,
        };
        messagePopup('','Item added Successfully','success')
        this.props.addUserShoppingCartData({
          query: saveShoppingCartDataQuery,
          variables,
          cuurentcartCount: this.props.cartDetails.cartCount,
        });
      }
      else{
        messagePopup('','test added already','error')
      }
      } else {
        this.props.addToCart({ ...data, quantity: 1 });
      }
    
  };

  scrollDown = () => {
    window.scrollTo({
        top: 0
    });
  };
  
  render() {
    return (
      <div>
        <BackdropLoader open={this.props.tests.loading} />
        {/* Main Banner */}
        <div className="banner">
          <div className="container">
            <h1>Take the next step to better health</h1>
            {/* <p>Our at home health tests are convenient and easy to use, purchase your test kit, get it delivered to your doorstep and receive your results on your device within days.</p> */}
            <p>
              Our at home health tests are convenient and easy to schedule.
              Order your health tests, a professional will collect your sample
              and receive your results on your device within days.
            </p>
            <img src={Banner} alt="main banner" />
          </div>
        </div>

        {/* Products */}
        <div className="shop">
          <div className="container">
            <h2>Shop for your health test</h2>
            <Swiper
              spaceBetween={20}
              slidesPerView={4}
              navigation
              breakpoints={{
                0: { slidesPerView: 1 },
                640: { slidesPerView: 2 },
                992: { slidesPerView: 3 },
                1200: { slidesPerView: 4 },
              }}
            >
              {this.props.tests.data &&
                this.props.tests.data.map((item) => (
                  <SwiperSlide key={item.id}>
                    <li onClick={this.scrollDown}>
                    <NavLink to={`/test_details/${item.id}`}>
                      <div className="kit">
                        {item.filepath ?
                         <img src={item.filepath} alt="kit name" />:
                         <img src={productImg} alt="kit name" /> }
                    
                      </div>
                      <div className="desc">
                        <Tooltip title={item.testname} placement="bottom">
                          <div className="title">{item.testname}</div>
                        </Tooltip>
                        <div className="price">
                          <span>AED {item.price}</span>
                        </div>
                        <Tooltip title={item.description} placement="bottom">
                            <div className="note">
                              {item.description}
                            </div>
                        </Tooltip>
                      </div>
                    </NavLink>
                    </li>
                    { getAuthToken() ? 
                    <div className="cart">
                    <Button onClick={() => this.handleAddToCart(item)}>Add to cart <img src={CartArrow} alt="cart icon" /></Button>
                    </div>
                    :
                    <Login btnType="login" >
                    <div className="cart">
                    <Button onClick={() => this.handleAddToCart(item)}>Add to cart <img src={CartArrow} alt="cart icon" /></Button>
                    </div>
                    </Login>
  }
                  </SwiperSlide>
                ))}
            </Swiper>
          </div>
        </div>

        {/* How It Works */}
        <div className="howWorks">
          <div className="container">
            <h3>How it works</h3>

            <div className="flex">
              <div className="col">
                <div className="art">
                  <img src={Art1} alt="Order Online" />
                </div>
                <div className="title">Order your health test</div>
                {/* <p>
                  The test kit will be delivered to your doorstep with free
                  shipping to you and to the lab
                </p> */}
                <p>
                  The health tests will be selected with guidance of a
                  healthcare professional and sent to our certified labs for
                  testing.
                </p>
              </div>

              <div className="col">
                <div className="art">
                  <img src={Art2} alt="Order Online" />
                </div>
                <div className="title">At home sample collection</div>
                {/* <p>
                  The health test has 3 simple sample collection steps. Once
                  done login to arrange for your sample to be collected.
                </p> */}
                <p>
                  Your sample will be collected by a trained Thulsee healthcare
                  professional in the comfort of your home
                </p>
              </div>

              <div className="col">
                <div className="art">
                  <img src={Art3} alt="Order Online" />
                </div>
                <div className="title">Get your results</div>
                <p>
                  Easy to understand results will be provided on our secure
                  platform to your device
                </p>
              </div>
            </div>
          </div>
        </div>

        {/* Why doO this */}
        <div className="whyDoThis">
          <div className="container">
            <div className="flex">
              <div className="column">
                <h4>Why do this</h4>
                <p>
                  Our aim is to drive proactive awareness about your health. We
                  want to give you insight with what's happening with your body.
                  Easy to understand results that highlight your current state
                  of being.
                </p>
              </div>
              <div className="column">
                <div className="img">
                  <img src={WhyDoThis} alt="Why Do This" />
                </div>
              </div>
            </div>
          </div>
        </div>
       {/* Add new requirement    */}
         <div className="accerditedLabs">
          <div className="container">
            <div className="flex">
              <h5>Meet our healthcare professionals on the Thulsee platform</h5>
              <p className="text-center" onClick={this.scrollDown}><img style={{"margin":"0 auto"}} src={doctorImages} alt="Link Arrow" /></p>
              <p className="text-center mb-0" >Dr. Jenny Labib</p>
              <p className="text-center">General Practioner</p>
            </div>
          </div>
        </div>
       {/* end requirement    */}
        {/* Highly Accedited labs */}
        <div className="doctorLabs">
          <div className="container">
            <div className="flex">
              <h5>We use highly accredited labs in the region</h5>
              <p>
                Our laboratory partners are accredited with CAP and ISO
                accreditation. They are reputable and offer best in class
                quality. We ensure that your data is safe and protected by using
                anonymous ID’s with our lab partners.
              </p>
              <p onClick={this.scrollDown}>
                <NavLink to="/privacy">
                  <strong>Learn More</strong>
                  <span>
                    <img src={LinkArrow} alt="Link Arrow" />
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="31.101"
                      height="24"
                      viewBox="0 0 31.101 24"
                    >
                      <g
                        id="Ellipse_46"
                        data-name="Ellipse 46"
                        fill="rgba(255,255,255,0)"
                        stroke="#22ab4b"
                        strokeWidth="1"
                      >
                        <circle cx="12" cy="12" r="12" stroke="none" />
                        <circle cx="12" cy="12" r="11.5" fill="none" />
                      </g>
                    </svg>
                  </span>
                </NavLink>
              </p>
              <p className="text-center"><img src={labImage} alt="Link Arrow" /></p>
            </div>
          </div>
        </div>
      </div>
    );
  }
}
const mapStateToProps = (state) => {
  return {
    tests: state.tests,
    cartDetails: state.cartDetails,
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    handleGetTestList: () => dispatch(getTestList()),
    addToCart: (data) => dispatch(addShoppingCartData(data)),
    addUserShoppingCartData: (data) => dispatch(addUserShoppingCartData(data)),
    getUserShoppingCartData: (data) => dispatch(getUserShoppingCartData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Home);
