import React from "react";
import {NavLink} from 'react-router-dom';
import SetNewPassword from '../ChangePassword/setNewPassword';

const ProductsDropdown = ({ current, prev }) => {
  return (
    <div className="products-dropdown-el dropdown-el" data-current={current} data-prev={prev}>
      <div data-prevent-distortion>
        <div className="dropdown-section">
          <ul className="info-section">
                <li><NavLink to="/shop_all_test">Shop all tests</NavLink></li>
                <li><NavLink to="/wellness_tests">Wellness tests</NavLink></li>
                <li><NavLink to="/women_health_tests">Women's health tests</NavLink></li>
                <li><NavLink to="/men_health_tests">Men's health tests</NavLink></li>
                {/* <li><SetNewPassword /></li> */}
          </ul>
        </div>
      </div>
    </div>
  );
};

const DevelopersDropdown = ({ current, prev }) => {
  return (
    <div className="products-dropdown-el dropdown-el" data-current={current} data-prev={prev}>
      <div data-prevent-distortion>
        <div className="dropdown-section">
            <ul className="info-section">
                <li><NavLink to="/about">About us</NavLink></li>
                <li><NavLink to="/how_it_works">How it Works</NavLink></li>
                {/* <li><NavLink to="/shopping_cart">Shopping Cart</NavLink></li> */}
                {/* <li><NavLink to="/Appointment">Appointment</NavLink></li> */}
                {/* <li><NavLink to="/science">The science</NavLink></li> */}
                {/* <li><NavLink to="/faq">FAQ's </NavLink></li> */}
                <li><NavLink to="/contact">Contact us</NavLink></li>
                {/* <li><NavLink to="/legal">Legal</NavLink></li> */}
            </ul>
          </div>
      </div>
    </div>
  );
};


export { ProductsDropdown, DevelopersDropdown};
