import React from 'react';
import { Line } from 'react-chartjs-2';

const colorScheme = {
  optimal: `#4CBB17`,
  high: '#FF5733',
  low: '#FFC300',
  subOptimal: '#ECFFDC',
  edge: '#22ab4b',
  rangeColorWhite: 'rgba(255, 255, 255,1)',
  rangeColorGreen: 'rgba(226,245,234,1)',
}
export default class DashbaordGraph extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      maxValue: 1,
      optimalLow: 0.3,
      optimalHigh: 0.4
    }

  }
  componentDidUpdate(prevProps) {
    if (prevProps !== this.props &&
      prevProps.parameters !== this.props.paramters || prevProps.data !== this.props.data) {
      let testValue = this.props.parameters.optimumvalue.split('-')
      this.setState({
        maxValue: Math.max(...this.props.data.map(val => val.value)) < parseInt(this.props.parameters.highvalue) ? parseInt(this.props.parameters.highvalue) : Math.max(...this.props.data.map(val => val.value)),
        optimalLow: parseFloat(testValue[0]),
        optimalHigh: parseFloat(testValue[1])
      })
    }
  }
  beforeDraw = (chart) => {
    const ctx = chart.ctx;
    const canvas = chart.canvas;
    const chartArea = chart.chartArea;
    const maxValue = this.state.maxValue
    const highRange = this.state.optimalHigh / maxValue;
    const lowRange = this.state.optimalLow / maxValue;


    var gradientBack = canvas.getContext("2d").createLinearGradient(0, 529, 0, 0);
    gradientBack.addColorStop(0, colorScheme.rangeColorWhite);
    gradientBack.addColorStop(lowRange, colorScheme.rangeColorWhite);
    gradientBack.addColorStop(lowRange, colorScheme.edge);   // solid edge
    gradientBack.addColorStop(lowRange + 0.001, colorScheme.edge); //solid edge
    gradientBack.addColorStop(lowRange + 0.002, colorScheme.rangeColorGreen);  //green
    gradientBack.addColorStop(highRange - 0.002, colorScheme.rangeColorGreen);  //green 
    gradientBack.addColorStop(highRange - 0.001, colorScheme.edge); // solid edge
    gradientBack.addColorStop(highRange, colorScheme.edge);     //solid edge
    gradientBack.addColorStop(highRange, colorScheme.rangeColorWhite);
    gradientBack.addColorStop(1, colorScheme.rangeColorWhite);

    ctx.fillStyle = gradientBack;
    ctx.fillRect(chartArea.left, chartArea.bottom,
      chartArea.right - chartArea.left, chartArea.top - chartArea.bottom);
  }
  render() {
    const plugins = [{
      beforeDraw: this.beforeDraw

    }];
    return (
      <div>
        <Line
          data={{
            labels: [...this.props.data.map(val => new Date(val.date).toDateString())],
            datasets: [
              {
                id: 1,
                label: '',
                data: [...this.props.data.map(val => val.value)],
                radius: 8,
                fill: false,
                borderColor: 'black',
                pointBackgroundColor: [...this.props.data.map(val => {
                  let testValue = this.props.parameters.optimumvalue.split('-')
                  if (val.value >= parseFloat(testValue[0]) && val.value <= parseFloat(testValue[1])) {
                    return colorScheme.optimal
                  }
                  else if (val.value >= parseFloat(this.props.parameters.highvalue)) {
                    return colorScheme.high
                  }
                  else if (val.value <= parseFloat(this.props.parameters.lowvalue)) {
                    return colorScheme.low
                  }
                  else if (val.value < parseFloat(testValue[0]) && val.value > parseFloat(this.props.parameters.lowvalue) ||
                    (val.value > parseFloat(testValue[1]) && val.value < parseFloat(this.props.parameters.highvalue))) {
                    return colorScheme.subOptimal
                  }
                })],
                tension: 0.1
              }
            ],
          }}
          options={{
            responsive: true,
            legend: {
              display: false,
              position: 'right'
            },
            title: {
              display: false,
              text: 'Chart.js Line Chart'
            },
            tooltips: {
              mode: 'label',
            },
            hover: {
              mode: 'nearest',
              intersect: true
            },
            scales: {
              xAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: 'Date'
                }
              }],
              yAxes: [{
                display: true,
                scaleLabel: {
                  display: true,
                  labelString: `${this.props.parameters?.measure ? this.props.parameters?.measure : ''}`
                },
                ticks: {
                  min: 0,
                  max: this.state.maxValue,
                  autoSkip: false,
                }
              }]
            }
          }}
          plugins={plugins}
        />
      </div>
    );
  }
}
