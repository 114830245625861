import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { GET_KIT_SAMPLE_ID_STATUS_FAILED, GET_KIT_SAMPLE_ID_STATUS_INITIAL, GET_KIT_SAMPLE_ID_STATUS_SUCCESS } from '../types';

export const checkSampleIdStatusStart = (data) => async dispatch => {
    try {
        const response = await fetchMethod(`/api/Userdata/checkSampleKit`,data,'post',true);
        
        if(response && response.data && response.status === 200) {
           if(response.data.response && response.data.response.status === 201){
            dispatch({
                type: GET_KIT_SAMPLE_ID_STATUS_SUCCESS,
                payload: {
                    status: false,
                    data: null,
                    msg: response.data.response.msg
                }
            }) 
           }
           if(response.data.response && response.data.response.status === 200){
            dispatch({
                type: GET_KIT_SAMPLE_ID_STATUS_SUCCESS,
                payload: {
                    status: true,
                    data: response.data.response.data,
                    msg: response.data.response.data.map(item => item.testName).join(',')
                }
            }) 
           }
        } else {
            messagePopup('','Checking Sample Kit Status Failed','error');
            dispatch({
                type: GET_KIT_SAMPLE_ID_STATUS_FAILED,
                payload: 'Checking Sample Kit Status Failed'
            })
        }
    } catch (error) {
       messagePopup('','Checking Sample Kit Status Failed','error');
       dispatch({
           type: GET_KIT_SAMPLE_ID_STATUS_FAILED,
           payload: 'Checking Sample Kit Status Failed'
       })
    }
}

export const resetSampleIdStatus = () => async dispatch => {
    dispatch({
        type: GET_KIT_SAMPLE_ID_STATUS_INITIAL
    }) 
}

