import { GET_ORDER_HISTORY_FAILED, GET_ORDER_HISTORY_START, GET_ORDER_HISTORY_SUCCESS } from "../actions/types";

const initialData = {
   data: null,
   error: null,
   loading: false
}

export default function(state = initialData, action) {
    switch(action.type) {
        case GET_ORDER_HISTORY_START: 
            return {
                ...state,
                loading: true
            }
        case GET_ORDER_HISTORY_SUCCESS: 
            return {
                ...state,
                error: null,
                data: action.payload,
                loading: false
            }
        case GET_ORDER_HISTORY_FAILED: 
            return {
                ...state,
                data: null,
                error: action.payload,
                loading: false
            }
        default: 
            return {
                ...state
            }
    }
}