import React from 'react';
import '../../assets/css/howitworks.scss';
import howItWorks from '../../assets/img/howitworktrackimg.svg';
import hometestkit from '../../assets/img/hometestkit.png';
import sampleCollected from '../../assets/img/sample-colllected.svg';
import healthResult from '../../assets/img/healthresult1.png';
import {NavLink} from 'react-router-dom';
class How extends React.Component{
    render(){
        return(
            <div  className= "pageWrap" style={{marginTop:'100px'}}>
              
              {/* <!-- main how it work page starts here --> */}
                <main className="howitworks">
                    {/* <!-- breadcumb starts here -->	 */}
                    <div className="breadcumb">
                        <div className="container">
                            <ul>
                                <li><NavLink to='/'>Home</NavLink></li>
                                <li><NavLink to='/how_it_works'>How It Works</NavLink></li>
                            </ul>
                        </div>
                    </div>

                    {/* <!-- How it works starts here -->	 */}
                    <section className="kitwork">
                        <div className="container">
                            <div className="kitworkwrap">
                                <div className="kitworktext">
                                    <h1>How it works</h1>
                                    <h2>At home tests delivered to your doorstep, with insights and tracking</h2>
                                </div>
                                <div className="kitworkimg">
                                    <img src={howItWorks} alt="howitworktrackimg"/>
                                </div>
                            </div>
                        </div>
                    </section>

                    {/* <!-- works instruction starts here -->	 */}
                    <section className="kitinstruction">
                        <div className="container">
                            <div className="kitinstructionwrap">
                                <div className="kitinstructionimg">
                                    <img src={hometestkit} alt="hometestkit"/>
                                </div>
                                <div className="kitinstructiontxt">
                                    <p>Order your at home test kit. Collect the sample in the comfort of your home. Our expert nurses will perform the sample extraction at the ease and comfort of your home, at a suitable time for you. No need to wait in long lines, We come to you!</p>
                                </div>

                                <div className="kitinstructiontxt">
                                    <p>The samples that you have collected at home are tested at our highly accredited network of labs. These are the same labs that the physicians and health providers currently use. We ensure that your data remains protected. We abide by some of the highest privacy standards globally. Thulsee has been HIPAA accredited by an independent third party, giving you peace of mind.</p>
                                </div>
                                <div className="kitinstructionimg">
                                    <img src={sampleCollected} alt="sample-colllected"/>
                                </div>                    

                                <div className="kitinstructionimg">
                                    <img src={healthResult} alt="healthresult"/>
                                </div>
                                <div className="kitinstructiontxt">
                                    <p>Your results will be reviewed by a physician before it's released directly to you via the Thulsee platform. You're welcome to book an appointment and chat with your health professional at anytime.</p>
                                </div>
                            </div>
                        </div>
                    </section>
                </main>	


            </div>
        )
    }
}

export default How;