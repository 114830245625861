import { SAVE_CHECKOUT_FAILED, SAVE_CHECKOUT_START, SAVE_CHECKOUT_SUCCESS } from '../actions/types';

const patientData = {
    loading: false,
    data: null,
    error: null
}

export default function(state = patientData, action) {
    switch(action.type) {
        case SAVE_CHECKOUT_START:
            return {
                ...state,
                loading: true,
            };
        case SAVE_CHECKOUT_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            };
        case SAVE_CHECKOUT_FAILED:
            return {
                ...state,
                error: action.payload,
                data: null,
                loading: false
            };
        default: 
            return {...state};
    }
}