import { SIGNUP_FAILED, SIGNUP_START, SIGNUP_SUCCESS } from '../../actions/types';

const initialData = {
    loading: false,
    data: null,
    error: null
}

export default function(state = initialData, action) {
    switch(action.type) {
        case SIGNUP_START:
            return {
                ...state,
                loading: true,
            };
        case SIGNUP_SUCCESS:
            return {
                ...state,
                data: action.payload,
                loading: false,
                error: null
            };
        case SIGNUP_FAILED:
            return {
                ...state,
                error: action.payload,
                data: null,
                loading: false
            };
        default: 
            return {...state};
    }
}