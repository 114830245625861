import React, { Component } from 'react';
import {NavLink} from "react-router-dom";


class Privacy extends Component {
  render() {
    return (
      <div className="pageWrap" style={{ marginTop: "85px" }}>
        {/* <div style={{marginTop:"200px"}}><h1>Privacy</h1></div>*/}
        <main className="otherPage">
          <div className="breadcumb">
            <div className="container">
              <ul>
                <li><NavLink to="/">Home</NavLink></li>
                <li><NavLink to="/privacy">Privacy</NavLink></li>
              </ul>
            </div>
          </div>

          <section className="checkout">
            <div className="container">
              <div className="heading">
                <div className="headshop"><h1><center>THULSEE NOTICE OF PRIVACY PRACTICES</center> </h1><br></br><br></br>
                  <p>THIS NOTICE OF PRIVACY PRACTICES DESCRIBES HOW MEDICAL INFORMATION ABOUT YOU MAY BE USED AND DISCLOSED AND HOW YOU CAN GET ACCESS TO THIS INFORMATION. <b>Please review it carefully.</b>
                  </p><br></br>
                  <h6><b>Last Updated On 15-07-2022</b></h6><br></br>
                  <h1>Overview</h1><br></br>
                  <p>Your access to, and use of, the <b>Thulsee</b> services which includes this website <a href="https://thulsee.com">www.thulsee.com</a> and the information, community, products and services (collectively, the "<b>Thulsee</b> Services”) that we provide to you are subject to the <b>Thulsee</b> Services Terms of Use and this Privacy Policy.</p><br></br>
                  <p><b>Thulsee</b> has created this Privacy Policy to explain what information we gather from you when you visit a <b>Thulsee</b> Services, how we may use this information, the security approaches we use to protect your information, and how you can access and request modification of certain information that we may store about you. </p><br></br>
                  <p>Please note that this Privacy Policy applies only to information collected via the <b>Thulsee</b> Services. It does not apply to information you disclose to <b>Thulsee</b> through other means. This Privacy Policy is incorporated and made part of the <b>Thulsee</b> Services Terms of Use.</p><br></br><br></br>
                  <h1 style={{ fontsize: '20px' }}>Your Acceptance of This Privacy Policy and Changes to it</h1><br></br>
                  <p>By accessing, viewing or otherwise using any Thulsee Service, you consent to the collection and use of your information by <b>Thulsee</b> in accordance with this Privacy Policy. If you do not agree to this Privacy Policy, you may not use any <b>Thulsee</b> Service.</p><br></br>
                  <p>Thulsee reserves the right to change, modify, add or remove portions of this Privacy Policy at any time, without prior notice. Changes take effect on the date that appears on the revised Privacy Policy.</p><br></br>
                  <p>If you use the <b>Thulsee</b> Service following a change in this Privacy Policy, your use will be understood to signal that you accept and agreed to be bound by the changes. Accordingly, we urge you to review this Privacy Policy frequently for changes.</p><br></br>
                  <h1 style={{ fontsize: '20px' }}>What Information Do We Collect?</h1><br></br>
                  <p>We collect personally identifiable information (“Personal Information”) and other non- individually identifiable information from you when you register on the <b>Thulsee</b> Services, respond to communication such as email, or otherwise use the <b>Thulsee</b> Services in any manner. </p><br></br>
                  <p>For purposes of these services, please note that Personal Information includes information you provide about yourself or other family members on whose behalf you are using the Thulsee Services. “You” and “your” includes you and these family members.</p><br></br>
                  <p>To participate in certain features of the <b>Thulsee</b> Services, you will be required to provide such Personal Information. For example, when registering as a user on any Thulsee Service, we may ask you for your name, email address, mailing address, phone number, credit card information (if additional services are added that require fees) or other information, such as date of birth, sex at birth, ethnicity, zip code, relevant personal medical history and family history, which enables us to provide the Services. You may, however, visit portions of the <b>Thulsee</b> Services without providing any Personal Information.</p><br></br>
                  <p>We may collect and use mobile device identifiers, IP addresses and session identifiers to analyze trends, to administer the <b>Thulsee</b> Services, to track user activities, to infer user interests, and to otherwise induce, deduce, and gather information about individual users and market segments.</p><br></br>
                  <p>We use “cookies'' to enhance your experience and gather information about visitors and visits to the <b>Thulsee</b> Services. Please refer to the “Do we use ‘cookies’?” section below for information about cookies and how we use them.</p><br></br>
                  <p>We may also collect and store certain other personally non-identifiable information. This information is collected passively using various technologies and cannot presently be used to specifically identify you.</p><br></br>
                  <h1 style={{ fontsize: '20px' }}>How do we use your information?</h1><br></br>
                  <p>We may use the Personal Information and other data we collect from you when you register, respond to a survey or marketing communication, access or view the <b>Thulsee</b> Services, or use certain other <b>Thulsee</b> Service features in the following ways:</p><br></br>
                  <ul>
                    <li>To provide any legitimate business service or product.</li><br></br>
                    <li>To personalize your <b>Thulsee</b> Service and to allow us to deliver the type of content and product offerings in which you are most interested.</li><br></br>
                    <li>To allow us to better service you in responding to your customer service requests.</li><br></br>
                    <li>To validate your identity.</li><br></br>
                    <li>To administer a promotion, or other <b>Thulsee</b> Service feature.</li><br></br>
                    <li>To troubleshoot problems with the <b>Thulsee</b> Services, or any services, as requested.</li><br></br>
                    <li>To enforce the <b>Thulsee</b>  Terms of Use and to detect and protect against error, fraud and other unauthorized or illegal activities.</li><br></br>
                    <li>To attempt to contact you regarding product safety or recall issues.</li><br></br>
                  </ul>
                  <p>We may also de-identify and aggregate your Personal Information for purposes such as research.</p><br></br>
                  <p>“Aggregated Data” is information that has been combined with that of other users and analyzed or evaluated as a whole, such that no specific individual may be reasonably identified.</p><br></br>
                  <p>“De-identified Information” is information that has been stripped of your identifiers, such as your name, contact information, and other identifying data, such that you cannot reasonably be identified as an individual.</p><br></br>
                  <p>You understand and agree that if you are using any <b>Thulsee</b> Service from a country outside the United States and provide Personal Information to the <b>Thulsee</b> Service, you will be authorizing and consenting to the transfer of Personal Information about yourself to the United States. </p><br></br>
                  <p>You understand that the privacy laws of the United States may be different from and not as comprehensive or protective as those in your country, and you agree that the transfer of your Personal Information to the United States occurs with your consent. Personal Information collected on the <b>Thulsee</b> Service may be stored and processed in the United States or abroad.</p><br></br>
                  <h1 style={{ fontsize: '20px' }}>How We Communicate With You?</h1><br></br>
                  <p>When you send email or other communications to <b>Thulsee</b>, we may retain those communications in order to process your inquiries, respond to your requests, and improve our Services and related products and services. When you send and receive messages we may collect and maintain information associated with those messages.</p><br></br>
                  <p>We will also send you strictly service-related announcements on a few occasions when it is necessary to do so. For instance, if our Services are temporarily suspended for maintenance, we might send you an email. Generally, you may not opt-out of these communications, which are not promotional in nature. If you do not wish to receive them, you have the option to deactivate your account.</p><br></br>
                  <p>Based upon the Personal Information you provide us, we may send you a welcome email to verify your username and password. We will communicate with you in response to your inquiries, to provide the services you request and to manage your account. We will communicate with you by email or telephone, in accordance with your preference. You may contact customer service at   <a href="mailto:hello@thulsee.com"> hello@thulsee.com.</a></p><br></br>
                  <p>You acknowledge that by providing us with your telephone number, cell phone number, and emails, we may use that information to send you information that you or your provider request on products and services offered by us or one of our partners. If we do, out of respect for your privacy, we will provide an option not to receive these types of communications. Please review the <b> Terms of Use </b> for more information on the ways in which we may communicate with you.</p><br></br>
                  <h1 style={{ fontsize: '20px' }}>The Information We Disclose to Outside Parties</h1><br></br>
                  <p>We may disclose your Personal Information and other data to third parties, as follows:</p><br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li>To our subsidiaries or affiliates, strategic partners and to third parties we engage to provide services on our behalf, such as website hosting, order processing, delivery, etc. Our agreements with these third parties require them to protect this data and prohibit these parties from using your Personal Information for any other purpose.</li><br></br>
                    <li>In the event of the sale or transfer of Thulsee, of one or more of our business units or of some or all of our assets, or in the context of some other business acquisition transaction.</li><br></br>
                    <li>In response to a subpoena or court order, or a request from a law enforcement or other government agency; to establish or exercise our legal rights, or to defend against claims; or to protect the safety or security of the public or of users of the Thulsee Services.</li><br></br>
                  </ul>
                  <p>We provide additional notice and ask for your consent if we wish to share your information with our commonly owned entities in a materially different way than discussed in this Privacy Policy.</p><br></br>
                  <h1>Your Rights</h1>  <br></br>
                  <h6>You have the right to: </h6> <br></br>
                  <li>Get a copy of your electronic medical record</li>   <br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                  <li style={{ textDecoration: 'underline' }}>You can ask to see or get an electronic or paper copy of your medical record and other health information we have about you. Ask us how to do this. </li>
                    <li style={{ textDecoration: 'underline' }}>We will provide a copy or a summary of your health information, usually within 30 days of your request. We may charge a reasonable, cost-based fee.</li>
                  </ul>
                  <li>Correct your electronic medical record</li><br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li style={{ textDecoration: 'underline' }}>You can ask us to correct health information about you that you think is incorrect or incomplete. Ask us how to do this.</li>
                    <li style={{ textDecoration: 'underline' }}>We may say “no” to your request, but we’ll tell you why in writing within 60 days.</li>
                  </ul>
                  <li>Request confidential communication</li><br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li style={{ textDecoration: 'underline' }}>You can ask us to contact you in a specific way (for example, home or office phone) or to send mail to a different address.</li>
                    <li style={{ textDecoration: 'underline' }}>We will say “yes” to all reasonable requests.</li>
                  </ul>
                  <li>Ask us to limit the information we share</li> <br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li style={{ textDecoration: 'underline' }}>You can ask us not to use or share certain health information for treatment, payment, or our operations. We are not required to agree to your request, and we may say “no” if it would affect your care.</li>
                    <li style={{ textDecoration: 'underline' }}>If you pay for a service or health care item out-of-pocket in full, you can ask us not to share that information for the purpose of payment or our operations with your health insurer. We will say “yes” unless a law requires us to share that information.</li>
                  </ul>
                  <li>Get a list of those with whom we’ve shared your information</li> <br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li style={{ textDecoration: 'underline' }}>You can ask for a list (accounting) of the times we’ve shared your health information for six years prior to the date you ask, who we shared it with, and why.</li>
                    <li style={{ textDecoration: 'underline' }}>We will include all the disclosures except for those about treatment, payment, and health care operations, and certain other disclosures (such as any you asked us to make). We’ll provide one accounting a year for free but will charge a reasonable, cost-based fee if you ask for another one within 12 months.</li>
                  </ul>
                  <li>Get a copy of this privacy notice</li> <br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li style={{ textDecoration: 'underline' }}>You can ask for a paper copy of this notice at any time, even if you have agreed to receive the notice electronically. We will provide you with a paper copy promptly.</li>
                  </ul>
                  <li>File a complaint if you believe your privacy rights have been violated</li> <br></br>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li style={{ textDecoration: 'underline' }}>You can complain if you feel we have violated your rights by contacting us using the information on page 1.</li>
                    <li style={{ textDecoration: 'underline' }}>You    can    file    a    complaint    with  the  U.S.  Department  of  Health  and  Human  Services  Office  for  Civil  Rights  by  sending  a letter  to  200  Independence  Avenue,  S.W.,  Washington,  D.C. 20201, calling 1-877-696-6775, or visiting <b>www.hhs.gov/ocr/privacy/hipaa/complaints/.</b></li>
                    <li style={{ textDecoration: 'underline' }}>We will not retaliate against you for filing a complaint.</li>
                  </ul><br></br>
                  <p>You can exercise your rights by sending us an email on <a href="mailto:hello@thulsee.com">hello@thulsee.com. </a></p>
                  <h1 style={{ fontSize: '30px' }}>Opt-Out Choices</h1><br></br>
                  <p>To “opt-out” of any of the following send an email to  <a href="mailto:hello@thulsee.com">hello@thulsee.com. </a></p>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li style={{ textDecoration: 'underline' }}>	any consents previously given to us, </li>
                    <li style={{ textDecoration: 'underline' }}>receiving communications from us, or </li>
                    <li style={{ textDecoration: 'underline' }}>	having Personal Information disclosed to third parties, </li>
                  </ul>
                  <p>Notwithstanding this right to request to cease receiving communications from us and to have us not share Personal Information with third parties, we retain the right to collect, maintain and process information provided by and collected about you on and through the Services, and disclose such information to comply with its reasonable business and legal obligations.</p>
                  <h1 style={{ fontSize: '30px' }}>Do we use “cookies”?</h1><br></br>
                  <p>Cookies are small files that a site or its service provider transfers to your computer’s hard drive through your Web browser or mobile device that enables the site’s or service provider’s systems to recognize your browser and capture and remember certain information. Cookies help us in many ways to make your use of the <b>Thulsee</b> Services more enjoyable and meaningful, such as understanding usage patterns and improving functionality of the <b>Thulsee</b> Services. For instance, we use cookies to help us understand your preferences based on previous or current <b>Thulsee</b> Services activity, which enables us to provide you with improved services. We also use cookies to help us compile aggregate data about <b>Thulsee</b> Services traffic and <b>Thulsee</b> Services interaction so that we can offer better <b>Thulsee</b> Services experiences and tools in the future. We may contract with third-party service providers to assist us in better understanding our <b>Thulsee</b> Service visitors.</p>
                  <p>If you are using our website, you can choose to have your computer warn you each time a cookie is being sent, or you can choose to turn off all cookies. You do this through your browser settings. If you are accessing our mobile app, you can choose to change your permissions and settings on your mobile device. Each browser and device is a little different, so look at your browser or devices Help or Settings menu to learn the correct way to modify them. If you turn cookies off or disable certain permissions, you won’t have access to many features that make your <b>Thulsee</b>  Service more efficient and some of our services will not function properly.</p>
                  <h1 style={{ fontSize: '30px' }}>“Do Not Track”</h1><br></br>
                  <p>We do not track our customers over time and across third party websites to provide targeted advertising and therefore do not respond to Do Not Track (DNT) signals. However, some third party sites do keep track of your browsing activities when they serve you content, which enables them to tailor what they present to you. If you are visiting such sites, you need to set the DNT signal on your browser so that third parties (particularly advertisers) know you do not want to be tracked.</p>
                  <h1 style={{ fontSize: '30px' }}>Safeguarding Your Personal Information</h1><br></br>
                  <p>Thulsee follows generally accepted industry security standards to safeguard and help prevent unauthorized access and maintain data security of Personal Information. However, no commercial method of information transfer over the Internet or electronic data storage is known to be 100% secure. As a result, we cannot guarantee the absolute security of any Personal Information submitted to or otherwise collected during your use of any <b>Thulsee</b> Service. Accordingly, you understand and agree that you transmit all data, including Personal Information, to us at your own risk.</p>
                  <h1 style={{ fontSize: '30px' }}>Privacy and Third Party Links</h1><br></br>
                  <p>This Privacy Policy applies solely to information collected by the <b>Thulsee</b> Service through our website located at <a href="https://thulsee.com"> www.Thulsee.com</a> and the mobile application(s) we make available. In an attempt to provide you with increased value, we may include third party links on the <b>Thulsee</b> Services. This Privacy Policy does not apply to the third party sites and services that are accessible through these links and we suggest that you contact the operator of the third party service to obtain details about their privacy policies. We have no responsibility or liability for the content and activities of these linked sites or services. Nonetheless, we seek to protect the integrity of the <b>Thulsee</b> Services and welcome any feedback about these linked sites (including if a specific link does not work).</p>
                  <h1 style={{ fontSize: '30px' }}>Exclusions</h1><br></br>
                  <p>This Privacy Policy shall not apply to any unsolicited information you provide to us through the <b>Thulsee</b> Services or through any other means. This includes, but is not limited to, any ideas for new products or modifications to existing products, and other unsolicited submissions (collectively, “Unsolicited Information”). All Unsolicited Information shall be deemed to be non-confidential and we shall be free to reproduce, use, disclose, and distribute such Unsolicited Information to others without limitation or attribution.</p>
                  <h1 style={{ fontSize: '30px' }}>Children’s Privacy</h1><br></br>
                  <p>The <b>Thulsee</b> Service is intended only for use by adults, either for themselves or on behalf of their minor children. We do not knowingly collect information directly from children under the age of 13. If you have reason to believe that a child under the age of 13 has provided Personal Information to us through the Thulsee Service, please contact us, and we will endeavor to delete that information from our databases.</p>
                  <h1 style={{ fontSize: '30px' }}> Retention of Your Personal Information</h1><br></br>
                  <p>We will store the Personal Information you provide for as long as necessary based on our Retention Policy:</p>
                  <ul style={{ liststyleposition: 'outside' }}>
                    <li style={{ textDecoration: 'underline' }}>to carry out the purpose(s) for which we collected it, or</li>
                    <li style={{ textDecoration: 'underline' }}> to comply with applicable laws, contracts, or other rules or regulations</li>
                  </ul>
                  <h1 style={{ fontSize: '30px' }}> Questions and Feedback</h1><br></br>
                  <p>We welcome your questions, comments, and concerns about the <b>Thulsee</b> Services. Please send us any and all feedback pertaining to the <b>Thulsee</b> Services to   <a href="mailto:hello@thulsee.com"> hello@thulsee.com.</a></p>
                  <p>The information contained in this Privacy Policy is subject to change without notice.</p>
                </div>
              </div>
            </div>
          </section>
        </main>
      </div>
    );
  }
}

export default Privacy;