import PRODUCT_DETAILS from '../../../Common/SharedData/productDetails';
import { fetchGraphMethod, fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { GET_TEST_START,GET_TEST_SUCCESS, GET_TEST_FAILED } from '../types';

export const getTest = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_TEST_START
        })
        const response = await fetchMethod(`/singleTestDetails?testId=${data.testId}`,null,'get',false)
        // const response = await fetchGraphMethod(data.query,data.variables,false); 
        
        if(response && response.status === 200){
            if(response.data && response.data.status === 200) {
                const test = response.data.data[0];
                dispatch({
                    type: GET_TEST_SUCCESS,
                    payload: test
                })
            } else{
                messagePopup('','Get Test Details Falied','error');
                dispatch({
                    type: GET_TEST_FAILED,
                    payload: 'Get Test Details Falied'
                })
            }
        } else {
            messagePopup('','Get Test Details Falied','error');
            dispatch({
                type: GET_TEST_FAILED,
                payload: 'Get Test Details Falied'
            }) 
        }
    } catch (error) {
        messagePopup('','Get Test Details Falied','error');
        dispatch({
            type: GET_TEST_FAILED,
            payload: 'Test List Failed'
        })
    }
};

export const getTestHardcoded = (data) => async dispatch => {
    try {
        const productDetails = [...PRODUCT_DETAILS];
        const product = productDetails.find(item => item.id === data.id);
        
        dispatch({
            type: GET_TEST_SUCCESS,
            payload: product
        })
    } catch (error) {
        messagePopup('','Get Test Details Falied','error');
        dispatch({
            type: GET_TEST_FAILED,
            payload: 'Test List Failed'
        })
    }
};