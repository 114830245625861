import React, { Component } from 'react';
import '../../assets/css/contactus.scss';
import { FormGroup, TextField } from '@material-ui/core';
import {NavLink} from 'react-router-dom';
// import '../../assets/css/contactus.css';
import { emailRegex, nameRegex } from '../../Config';
import messagePopup from '../../Services/messagePopupService';
import { connect } from 'react-redux';
import { contactusStart } from '../../Redux/actions/contactus/contactus';
import BackdropLoader from '../../Services/loader';

class Contact extends Component {
  constructor(props){
    super(props);
    this.state = {
      name: '',
      email: '',
      subject: '',
      message: '',
      fieldErrors: {}
    }
  }

  handleInputChange = (event) => {
    const {name,value} = event.target;
    this.setState({[name]: value})
    this.validateInputErrors(name,value)
  }
  clearQuery = () => {
    this.setState({
      name: '',
      email: '',
      subject: '',
      message: ''
    })
  }

  handleSubmit = (evt) => {
    // evt.stopPropagation();
    // evt.preventDefault();
    const tempMsg = this.formValidation();
    if(!tempMsg){
      const {name,email,subject,message} = this.state;
      this.props.contactusStart({
        name,
        email,
        subject,
        message,
      })
      this.clearQuery()
    } else {
      messagePopup('', tempMsg ,'error')
    }
  }

  formValidation = () => {
    let fieldErrors = {};
    fieldErrors['name'] = this.state.name ? nameRegex.test(this.state.name) ? this.state.name.length > 40 ? 'Name must be between 1 to 40 Characters' : '' : "Please Enter a valid Name" : 'Name is required';
    fieldErrors['email'] = this.state.email ? emailRegex.test(this.state.email) ? "" : "Please Enter a valid Email" : 'Email is required';
    fieldErrors['subject'] = this.state.subject ? '' : 'Subject is required';
    fieldErrors['message'] = this.state.message ? this.state.message.length > 255 ? 'Message must be between 1 to 255 Characters' : '' : 'Message is required';
    this.setState({fieldErrors})
    return fieldErrors['name'] 
           ? fieldErrors['name'] 
           : fieldErrors['email'] 
           ? fieldErrors['email']
           : fieldErrors['subject'] 
           ? fieldErrors['subject']
           : fieldErrors['message'] 
           ? fieldErrors['message'] 
           : '';
  }

  validateInputErrors = (name,value) => {
    let fieldErrors = {...this.state.fieldErrors};
    if(name === 'name'){
      fieldErrors['name'] = value ? nameRegex.test(value) ? value.length > 40 ? 'Name must be between 1 to 40 Characters' : '' : "Please Enter a valid Name" : 'Name is required';
    }
    if(name === 'email'){
      fieldErrors['email'] = value ? emailRegex.test(value) ? "" : "Please Enter a valid Email" : 'Email is required';
    }
    if(name === 'subject'){
      fieldErrors['subject'] = value ? '' : 'Subject is required';
    }
    if(name === 'message'){
      fieldErrors['message'] = value ? value.length > 255 ? 'Message must be between 1 to 255 Characters' : ''  : 'Message is required';
    }
    this.setState({fieldErrors})
  }

  render() {
    return (
        <div className= "pageWrap" style={{marginTop: '85px'}}>
          <main className="contactPage">
                  {/* <!-- contact info starts here --> */}
                  <section className="contact">
                      {/* <!-- breadcumb starts here -->	 */}
                      <div className="breadcumb">
                          <div className="container">
                              <ul>
                                  <li><NavLink to="/">Home</NavLink></li>
                                  <li><NavLink to="/contact">Contact Us</NavLink></li>
                              </ul>
                          </div>
                      </div>            
                      <div className="contacttext">
                          <div className="container">
                              <h1>Contact us</h1>
                              <p>If you have queries about your account , delivery or results contact us on <a href="mailto:info@thulsee.com">Info@thulsee.com</a></p>
                          </div>
                      </div>
                  </section>
                  <BackdropLoader open={this.props.contactus.loading} />
                  {/* <!-- contact form starts here --> */}
                  <section className="contactform">
                      <div className="container">
                          <div className="contactwrap">
                              <h2>Submit your query below</h2>
                              <FormGroup>                        
                                  <div className="form-group">
                                      <TextField 
                                        id="name" 
                                        label="Name" 
                                        variant="outlined" 
                                        className="text-field"
                                        value={this.state.name}
                                        name="name"
                                        onChange={this.handleInputChange}
                                        required
                                        error={this.state.fieldErrors['name'] ? true : false}
                                        helperText={this.state.fieldErrors['name'] ? this.state.fieldErrors['name'] : ''}
                                      />
                                  </div>
                                  
                                  <div className="form-group">
                                      <TextField 
                                        label="Email" 
                                        variant="outlined" 
                                        className="text-field"
                                        id="email" 
                                        value={this.state.email}
                                        name="email"
                                        onChange={this.handleInputChange}
                                        required
                                        error={this.state.fieldErrors['email'] ? true : false}
                                        helperText={this.state.fieldErrors['email'] ? this.state.fieldErrors['email'] : ''}
                                      />
                                  </div>                        
                                  <div className="form-group">
                                      <TextField 
                                        label="Subject" 
                                        variant="outlined" 
                                        className="text-field"
                                        id="subject" 
                                        value={this.state.subject}
                                        name="subject"
                                        onChange={this.handleInputChange}
                                        required
                                        error={this.state.fieldErrors['subject'] ? true : false}
                                        helperText={this.state.fieldErrors['subject'] ? this.state.fieldErrors['subject'] : ''}
                                      /> 
                                  </div>
                                  <div className="form-group">
                                      <TextField 
                                        label="Message" 
                                        variant="outlined" 
                                        className="text-field"
                                        id="message" 
                                        multiline
                                        rows={3}
                                        rowsMax={4}
                                        value={this.state.message}
                                        name="message"
                                        onChange={this.handleInputChange}
                                        required
                                        error={this.state.fieldErrors['message'] ? true : false}
                                        helperText={this.state.fieldErrors['message'] ? this.state.fieldErrors['message'] : ''}
                                      />
                                  </div>                        
                                  <button type="submit" className="formsubmit" onClick={this.handleSubmit}>Submit</button>
                              </FormGroup> 
                          </div>
                      </div>
                  </section>
              </main>
        </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
      contactus: state.contactus,
  };
}; 

const mapDispatchToProps = (dispatch) => {
  return {
      contactusStart: (data) => dispatch(contactusStart(data)),
  };
};

export default connect(mapStateToProps,mapDispatchToProps)(Contact);