import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { FORGOT_PASSWORD_FAILED, FORGOT_PASSWORD_START, FORGOT_PASSWORD_SUCCESS } from '../types';
import history from '../../../history'
import { setAuthToken, setUserInfo } from '../../../Services/authService';

export const forgotPasswordStart = (data) => async (dispatch) => {
    dispatch({
        type: FORGOT_PASSWORD_START
    })
    try {
        const {email,openResetPasswordModal} = data
        const response = await fetchMethod(`/api/Userdata/sendMail`,{email,role: 'USER'},'post',false);
        if(response && response.status === 200){
          if(response.data && response.data.response && response.data.response.status && response.data.response.status === 201){
            messagePopup('', response.data.response.message, 'error');
            dispatch({
              type: FORGOT_PASSWORD_FAILED,
              payload: response.data.response.message
            }) 
          } else {
            messagePopup('', response.data.response.message, 'success');
            dispatch({
              type: FORGOT_PASSWORD_SUCCESS,
              payload: {email}
            })   
            openResetPasswordModal('forgotPassword')
          }
          
        } else {
          messagePopup('', 'Forgot Password failed', 'error');
          dispatch({
            type: FORGOT_PASSWORD_FAILED,
            payload: 'Forgot Password failed'
          })
        }
      } catch (error) {
        messagePopup('', 'Forgot Password failed', 'error');
        dispatch({
            type: FORGOT_PASSWORD_FAILED,
            payload: 'Forgot Password failed'
        })
      }
    
};