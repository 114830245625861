import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import { connect } from "react-redux";
import swal from 'sweetalert';
import "../../assets/css/appointment.scss";
import Dialog from "@material-ui/core/Dialog";
import DialogActions from "@material-ui/core/DialogActions";
import DialogContent from "@material-ui/core/DialogContent";
import DialogTitle from "@material-ui/core/DialogTitle";
import conversationSvg from "../../assets/img/conversation.svg";
import clockSvg from "../../assets/img/clock.svg";
import calendarSvg from "../../assets/img/calendar.svg";
import micSvg from "../../assets/img/mic.svg";
import playBtnSvg from "../../assets/img/play-button.svg";
import conversationGreySvg from "../../assets/img/conversation-grey.svg";
import micGreySvg from "../../assets/img/mic-grey.svg";
import playBtnGreySvg from "../../assets/img/play-button-grey.svg";
import { Button, Checkbox, FormControl, FormControlLabel, InputLabel, MenuItem, Select, Typography } from "@material-ui/core";
import {
  bookAppointmentsStart,
  getAppointmentsStart,
  requestAppointmentsStart,
  reRequestAppointmentsStart,
  cancelAppointmentsStart,
  getDoctorSlotsStart,
  redirectToPaymentPortal,
  getLatestmessageDate
} from "../../Redux/actions/appointment/appointments";
import { getUserInfo } from "../../Services/authService";
import { getAppointmentsQuery, cancelAppointmentQuery, getDoctorSlotsQuery } from "./AppointmentQuery";
import BackdropLoader from "../../Services/loader";
import FiberManualRecordIcon from "@material-ui/icons/FiberManualRecord";
import "../../assets/css/register-kit.scss"
import {
  currentDateDbFormate,
  ddmmyyDateFormat,
  getColonSlotFormatedDate,
  getColonSlotFormatedTime,
  getSlotLocalDateTime,
} from "../../Services/dateTimeFormatter";
import messagePopup from "../../Services/messagePopupService";
import Chat from "../Chat/Chat";
import StripeCheckout from "react-stripe-checkout";
import { loadStripe } from '@stripe/stripe-js';
import { API_URL } from "../../Config";
import { getAuthToken } from '../../Services/authService';
import Login from '../../Common/Login/login';
import { REDIRECT_TO_PAYMENT_PORTAL } from "../../Redux/actions/types";
import { fetchGraphMethod } from "../../Services/httpService";



class Appointment extends Component {
  constructor(props) {
    super(props);
    this.state = {
      stocked: false,
      appointmentData: null,
      requestAppointmentFlag: true,
      user: getUserInfo(),
      today: getColonSlotFormatedDate(new Date()),
      filteredAppointments: null,
      chatOpen: false,
      appointmentDate: getColonSlotFormatedDate(new Date()),
      appointmentModal: false,
      // availabelSlots:this.props.appointments.doctorSlots,
      timeslotforpatient: '',
      accessId: window.location.href.split("Appointment/")[1],
      slotInfo: '',
      sortType: 'sortByDate',
      callWindow:false

    };
  }

  componentDidMount = async () => {
    console.log(" require('dotenv').config()",process.env)
    if (window.location.href.split("Appointment/")[1] != 0) {
      await this.allAppointments();
      if (parseInt(window.location.href.split("Appointment/")[1]) != 1) {
        let sessionId = window.location.href.split("successStripe?session_id=")[1]
        this.props.bookAppointmentsStart({
          sessionId: sessionId,
          allAppointments: this.allAppointments,
        });
        messagePopup('', 'Appointment Booked Successfully', 'success')
        this.props.history.push('/Appointment/1')
      }
    }
  };

  allAppointments = async () => {
    await this.props.getAppointmentsStart({
      query: getAppointmentsQuery,
      variables: {
        obj: {
          userid: getUserInfo()?.id,
          active: 1,
        },
      },
    });
  };

  componentDidUpdate = (prevProps) => {
    if (
      prevProps !== this.props &&
      prevProps.appointments.data !== this.props.appointments.data
    ) {
      this.setState({
        appointmentData: this.props.appointments.data.data,
        requestAppointmentFlag:
          this.props.appointments.data.requestAppointmentFlag,
      });
    }
    if (prevProps !== this.props &&
      prevProps.appointments.latestMessage !== this.props.appointments.latestMessage) {
      let latestMessageArr = this.props.appointments.latestMessage
      let arr = this.props.appointments.data.data.map((val, index) => {
        return { ...val, latestMessageDate: latestMessageArr[index] }
      })
      console.log("jjjjjjjjj",arr)
      this.setState({
        appointmentData: arr
      })
    }
  };

  handleRequestAppointment = () => {
    if (this.state.accessId != 0) {
      console.log("ljdkfhdjkg")
      // this.props.requestAppointmentsStart({
      //   userId: this.state.user.id,
      //   createdOn: currentDateDbFormate(),
      //   allAppointments: this.allAppointments,
      // });
      this.setState({ appointmentModal: true })
      this.props.getDoctorSlotsStart({
        query: getDoctorSlotsQuery,
        variables: {
          where: {
            slotsdate: this.state.appointmentDate,
            active: 1,
            slotfor: "doctor",
            slotstatus: 0,
            assignslotstatus: 0
          }
        }
      })

    } else {
      messagePopup("Sorry ! Registeraton is required for book appointment.", "", "warning");
    }
  };
  // showraisepopup = () => {

  //   swal({
  //     title: "Are you sure you want to raise the appointment request?",
  //     text: "",
  //     icon: "warning",
  //     buttons: {
  //       'No': true,
  //       'Yes': {
  //         value: 'request for appointment'
  //       }
  //     }
  //   })
  //     .then((value) => {
  //       switch (value) {
  //         case 'request for appointment':
  //           this.handleRequestAppointment()
  //           break;
  //         default:
  //           break;
  //       }
  //     });
  // }
  createCheckoutSession = (data) => {
    let appointmentDetails = [{
      ...data,
      userId: this.state.user.id,
      // appointmentId: data.id,
      updatedOn: currentDateDbFormate(),
      amount: "15",
      slotsstarttime: data.startTime,
      slotsendtime: data.endTime
    }]

    return fetch(`${API_URL}/api/Payments/checkoutPayment`, {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify({
        amount: parseInt('13'),
        emailId: getUserInfo().email,
        productDetails: appointmentDetails,
        typeOfPayment: "Book"
      }),
    })
      .then((data) => data.json())
      .then((data) => {
        return data.response;
      });
  }


  showraisecancelpopup = (e, appointmentValues) => {

    swal({
      title: "Are you sure?",
      text: "you want to Cancel this appointment?",
      icon: "warning",
      dangerMode: true,
      buttons: {
        cancel: true,
        confirm: true,
      },
    }).then((willCancel) => {
      if (willCancel == true) {
        this.props.cancelAppointmentsStart({
          query: cancelAppointmentQuery,
          variables: {
            where: {
              id: appointmentValues.details.id
            },
            data: {
              acceptstatus: null,
              slotid: null,
              active: 0
            },
            obj: {
              id: appointmentValues.details.slotid,
              slotstatus: 0,
              assignslotstatus: 0
            }
          },
          allAppointments: this.allAppointments,
        });
      }
    })
  }

  handleBookAppointment = (data) => {
    if (this.state.slotInfo !== '') {
      this.props.redirectToPaymentPortal();
      this.setState({
        appointmentModal: false
      })
      let stripeKey = "pk_test_51J2E68AFP91qVNcj0nO4DLKCy2Ditt17mCvxsYQ7UvuW2UNVspZzVYdQREdg32SyCgIRRzOPGmWVjx3ADHbBhCYk00zYYD02SC"
      const stripResult = loadStripe(stripeKey).then(async (stripe) => {
        stripe?.redirectToCheckout({
          sessionId: await this.createCheckoutSession(data),
        });
      })
    }
    else {
      messagePopup('', 'Time slot field cannot be empty', 'error')
    }
  };

  handleReRequestAppointment = (data) => {
    this.props.reRequestAppointmentsStart({
      userId: this.state.user.id,
      appointmentId: data.id,
      doctorId: data.doctoruserid,
      updatedOn: currentDateDbFormate(),
      allAppointments: this.allAppointments,
    });
  };

  handleChange = (e) => {
    this.setState({
      stocked: e.target.checked,
    });
  };

  videoCallHandel = (
    e,
    videoCallUrl,
    appointmentId,
    appointmentDate,
    appointmentStartTime,
    appointmentEndTime
  ) => {
    if (
      appointmentDate != "" &&
      appointmentStartTime != "" &&
      appointmentEndTime != ""
    ) {
      const appointmentStartDateAndTime = new Date(
        `${appointmentDate} ${appointmentStartTime}`
      );
      const appointmentEndDateAndTime = new Date(
        `${appointmentDate} ${appointmentEndTime}`
      );
      const currentDateAndTime = new Date();
      var startTime = appointmentStartDateAndTime - currentDateAndTime;
      var endTime = appointmentEndDateAndTime - currentDateAndTime;
      this.setState({
        callWindow:true
      })
      if (startTime / 36e5 <= 0 && endTime / 36e5 >= 0) {
        window.open(`https://thulsee.com/#/callJoin/${appointmentId}/${this.state.user.id}`, "Google", "width=500,height=500");
      } else {
        if (startTime / 36e5 < 0 && endTime / 36e5 < 0) {
          messagePopup("Sorry ! your call time is end", "", "warning");
        } else {
          if (startTime / 36e5 > 0 && endTime / 36e5 > 0) {
            messagePopup("Sorry ! your call not started", "", "warning");
          }
        }
      }
    } else {
      messagePopup("Sorry ! do not have time slot", "", "warning");
    }
  };

  chatHandel = (appointmentId, doctorname) => {
    // let chatUrl = `http://localhost:3001/Appointment#/chat?name=User_${appointmentId}&room=${appointmentId}`
    // window.open(chatUrl)
    this.setState({
      chatOpen: true,
      roomId: `${appointmentId}`,
      roomName: `user_${appointmentId}`,
      doctorName: doctorname,
    });
  };

  chatHandleClose = () => {
    this.setState({
      chatOpen: false,
    });
  };


  handleClose = () => {
    this.setState({ appointmentModal: false })
  }
  handleDateChange = (e) => {
    this.setState({
      appointmentDate: e.target.value
    })
    this.props.getDoctorSlotsStart({
      query: getDoctorSlotsQuery,
      variables: {
        where: {
          slotsdate: e.target.value,
          active: 1,
          slotfor: "doctor",
          slotstatus: 0,
          assignslotstatus: 0
        }
      }
    })

  }
  handleTimeChange = (e) => {
    let slotData;
    if (e.target.value === '') {
      slotData = e.target.value
    }
    else {
      slotData = JSON.parse(e.target.value)
    }

    this.setState({
      slotInfo: slotData

    })
  }
  handleSelectChange = (val) => {
    if (val === "sortByLatestMessage") {
      const data = this.state.appointmentData.map(val => val.details.id)
      this.props.getLatestmessageDate(data)
    }
    this.setState({ sortType: val })
  }
  sortByDate = (data) => {
    return data.sort((a, b) => {
      const firstVal = new Date(`${(a.slotInfo?.slotsdate).split('T')[0]}T${(a.slotInfo.slotsstarttime).trim()}Z`).getTime()
      const secondVal = new Date(`${(b.slotInfo?.slotsdate).split('T')[0]}T${(b.slotInfo.slotsstarttime).trim()}Z`).getTime()
      return secondVal - firstVal
    })
  }
  sortByLatestmessage = (data) => {
    const appointmentWithMessages = []
    const noMessageArray = []
    for (const val of data) {
      if (val.latestMessageDate && val.latestMessageDate.date !== null) {
        appointmentWithMessages.push(val)
      }
      else {
        noMessageArray.push(val)
      }
    }
    appointmentWithMessages.sort((a, b) => {
      const firstVal = new Date(a.latestMessageDate.date).getTime()
      const secondVal = new Date(b.latestMessageDate.date).getTime()
      return secondVal - firstVal
    })
    return [...appointmentWithMessages, ...noMessageArray]
  }

  render() {
    let appointmentData = []
    this.state.appointmentData && this.state.appointmentData.map(item => (
      item.details.acceptstatus === "cancel" || item.details.acceptstatus === "Cancelled" || item.details.acceptstatus === "pending" || item.details.acceptstatus === "accepted" || item.details.acceptstatus === "rejected" ?
        [] : appointmentData.push(item)
    ))
    const content = (
      <div className="appointitems">
        {appointmentData && (this.state.sortType === 'sortByDate' || !this.props.appointments.latestMessage.length > 0 ? this.sortByDate(appointmentData) : this.sortByLatestmessage(appointmentData)).map((val, index) => (
          <div className="itemsbox" key={index}>
            <div className="time">
              {" "}
              <img src={clockSvg} alt="clock" />
              {console.log("iiiiiiiiii",val)}
              {val.details.acceptstatus !== "pending" &&
                val.details.acceptstatus !== "rejected"
                //  &&
                // val.details.acceptstatus !== "cancel"
                ? val.slotInfo?.slotsstarttime
                  ? (getColonSlotFormatedTime(getSlotLocalDateTime(
                    val.slotInfo?.slotsdate,
                    val.slotInfo?.slotsstarttime,
                    // val.slotInfo?.slotsendtime,
                    "time",
                  )) + " " + "To" + " " + getColonSlotFormatedTime(getSlotLocalDateTime(
                    val.slotInfo?.slotsdate,
                    val.slotInfo?.slotsendtime,
                    "time"
                  ))
                  )
                  : ""
                : ""}
            </div>
            <div className="date">
              <img src={calendarSvg} alt="calendar" />
              {val.details.acceptstatus !== "pending" &&
                val.details.acceptstatus !== "rejected"
                //  &&
                // val.details.acceptstatus !== "cancel"
                ? val.slotInfo?.slotsstarttime
                  ? ddmmyyDateFormat(getSlotLocalDateTime(
                    val.slotInfo?.slotsdate,
                    val.slotInfo?.slotsstarttime,
                    "date",
                  ))
                  : ""
                : ""}
            </div>
            <div className="desc">
              {val.details.acceptstatus === "pending" && (
                <h2 style={{ color: "#e6af4ade" }}>Pending</h2>
              )}
              {val.details.acceptstatus === "rejected" && (
                <h2 className="inactive">Rejected</h2>
              )}
              {/* {val.details.acceptstatus === "cancel" && (
                  <h2 className="inactive">Cancelled</h2>
                )} */}
              {(val.details.acceptstatus === "accepted" ||
                val.details.acceptstatus === "booked") && (
                  <h2>
                    {val.doctorInfo.firstname
                      ? val.doctorInfo.lastname
                        ? val.doctorInfo.firstname +
                        " " +
                        val.doctorInfo.lastname
                        : val.doctorInfo.firstname
                      : ""}
                  </h2>
                )}
              {/* <p>{val.name}</p> */}
            </div>
            {console.log("jakdfhjkdhgd",val.details)}
            {(val.details.acceptstatus === "booked" ||
              val.details.acceptstatus === "pending" ||
              val.details.acceptstatus === "rejected") && (
                <div className="action">
                  {val.details.acceptstatus === "booked" ? (
                    <>
                      <NavLink to="#">
                        <img
                          src={conversationSvg}
                          alt="conversation"
                          onClick={() => {
                            this.chatHandel(
                              val.details.id,
                              val.doctorInfo.firstname
                            );
                          }}
                        />
                      </NavLink>
                      <NavLink to="#">
                          <img
                            src={playBtnSvg}
                            alt="play-button"
                            onClick={(e) => {
                              this.videoCallHandel(
                                e,
                                val.details.callurl,
                                val.details.id                                ,
                                val.slotInfo?.slotsdate != ""
                                  ? val.slotInfo?.slotsdate.split("T")[0]
                                  : val.slotInfo?.slotsdate,
                                getSlotLocalDateTime(
                                  val.slotInfo?.slotsdate,
                                  val.slotInfo?.slotsstarttime,
                                  "time"
                                ),
                                getSlotLocalDateTime(
                                  val.slotInfo?.slotsdate,
                                  val.slotInfo?.slotsendtime,
                                  "time"
                                )
                              );
                            }}
                          />
                        </NavLink>
                      {/* <NavLink to="#">
                        <img src={micSvg} alt="mic" />
                      </NavLink> */}
                    </>
                  ) : (
                    <>
                      <NavLink to="#">
                        <img src={conversationGreySvg} alt="play-button" />
                      </NavLink>
                      {/* <NavLink to="#">
                        <img src={micGreySvg} alt="mic" />
                      </NavLink> */}
                      <NavLink to="#">
                        <img src={playBtnGreySvg} alt="conversation" />
                      </NavLink>
                    </>
                  )}
                </div>
              )}
            {/* {val.details.acceptstatus === "accepted" && val.slotInfo.slotsdate >= getColonSlotFormatedDate(new Date()) ? (
                <div className="action" >
                  <NavLink
                    to="#"
                    onClick={() => { this.handleBookAppointment(val.details) }}
                    style={{ color: "#22ab4b", textDecoration: "none", }}
                  >
                    Book Appointment
                  </NavLink>
                </div>) : (
                <div className="action" >
                  <NavLink
                    to="#"
                    onClick={() => { this.handleBookAppointment(val.details) }}
                    style={{ color: "#22ab4b", textDecoration: "none", display: "none" }}
                  >
                    Book Appointment
                  </NavLink>
                </div>
              )
                // <div className="action">
                //     <NavLink
                //       to="#"
                //       style={{ color: "#22ab4b", textDecoration: "none" }}
                //     >
                //       Book Appointment
                //   </NavLink>
                // </div>
              } */}
            {/* {val.details.acceptstatus === "pending" || val.details.acceptstatus === "accepted" ? (
                <div className="action" >
                  <NavLink
                    to="#"
                    onClick={(e) => { this.showraisecancelpopup(e, val) }}
                    style={{ color: "#22ab4b", textDecoration: "none", }}
                  >
                    Cancel Appointment
                  </NavLink>
                </div>) :
                (<div className="action" >
                  <NavLink
                    to="#"
                    style={{ color: "#22ab4b", textDecoration: "none", }}
                  >
                    {val.details.acceptstatus === "booked" || val.details.acceptstatus === "cancel" ? '' : "Appointment Cancelled"}
                  </NavLink>
                </div>)
              } */}
            {/* {val.details.acceptstatus === "cancel" && (
                <div className="action">
                  <NavLink
                    to="#"
                    style={{ color: "#22ab4b", textDecoration: "none" }}
                    onClick={() => this.handleReRequestAppointment(val.details)}
                  >
                    Re-Request Appointment
                  </NavLink>
                </div>
              )} */}
          </div>
        ))
        }
      </div>
    );

    return (
      <div>
        <main className="appointments">
          <BackdropLoader open={this.props.appointments.loading} />
          <section className="appointwrap">
            <div className="container">
              <div className="appointmentcnt">
                <div className="viewbox">
                  <div className="heading">
                    <h1>Appointments</h1>
                  </div>
                  <div className="sorting">
                    {/* <div className="form-check">
                      <FormControlLabel
                        onChange={this.handleChange}
                        control={<Checkbox style={{ color: "gray" }} />}
                        label="Hide Unconfirmed"
                        labelPlacement="end"
                        disabled
                      />
                    </div> */}
                    {/* <div className="datefield">
                      <input
                        type="date"
                        className="form-control"
                        id="date"
                        defaultValue={this.state.today}
                        disabled={this.state.accessId === 0 ? true : false}
                      />
                      <i className="moz-cover-clear"></i>
                    </div> */}
                    {/* <div className="btnarchieve">
                      <NavLink to="#" style={{
                        color: "gray",
                        border: "1px solid gray"
                      }}>View Archived</NavLink>
                    </div> */}
                    <div
                      className="btnarchieve"
                      style={{ paddingLeft: "10px" }}
                    >
                      {this.state.requestAppointmentFlag ? (
                        <NavLink to="#" onClick={this.handleRequestAppointment}>
                          Request Appointment
                        </NavLink>
                      ) : (
                        <NavLink
                          // onClick={this.showraisepopup}
                          to="#"
                          style={{
                            color: "grey",
                            border: "1px solid grey",
                          }}
                        >
                          Request Appointment
                        </NavLink>
                      )}

                    </div>
                    <div classname="" style={{ marginLeft: '20px' }}>
                      <FormControl variant="outlined" style={{ marginLeft: '20px', width: '100%' }} className="select-dropdown">
                        <Select
                          id="sort"
                          name="sort"
                          style={{ width: '10rem', height: '40px', backgroundColor: 'white' }}
                          value={this.state.sortType}
                          onChange={(e) => { this.handleSelectChange(e.target.value) }}
                        >
                          <MenuItem value={'sortByDate'}>
                            Sort By Date
                          </MenuItem>
                          <MenuItem value={'sortByLatestMessage'}>
                            Sort By Latest Message
                          </MenuItem>
                        </Select>
                      </FormControl>
                    </div>
                  </div>
                </div>

                {!getAuthToken() && (
                  <section className="checkout">
                    <div className="container">
                      <>
                        <div className="logintoacount">
                          <p><Login btnType="login" elementType="NavLink" currentRoute="/Appointment/0" nextRoute="/Appointment/1">Login</Login> or <Login btnType="signup" elementType="NavLink" currentRoute="/Appointment/0" nextRoute="/Appointment/1">Sign Up</Login></p>
                        </div>
                        <div>
                          <p style={{ fontSize: '13px', color: 'red' }}>For Book the Appointment Login or Signup required.</p>
                        </div>
                      </>
                    </div>
                  </section>
                )}
                {this.state.appointmentData && this.state.appointmentData.length === 0 ? <div className="noappointments"> <center>no appointments yet</center> </div> : <div>{content}</div>}
              </div>
            </div>
          </section>
        </main>
        <Dialog
          open={this.state.appointmentModal}
          onClose={this.handleClose}

        >
          <DialogTitle id='customized-dialog-title'><Typography variant="h4" style={{ color: '#22ab4b' }}>Book New Appointment</Typography> </DialogTitle>
          <DialogContent>
            <div>
              <label><strong> Select Appointment Date</strong></label>
              <div className="datefield">
                <input
                  type="date"
                  min={getColonSlotFormatedDate(new Date())}
                  className="form-control"
                  id="date"
                  value={this.state.appointmentDate}
                  disabled={this.state.accessId === 0 ? true : false}
                  onChange={(e) => {
                    this.handleDateChange(e)
                  }}
                />
                <i className="moz-cover-clear"></i>
              </div>
            </div>
            <div style={{ marginTop: '20px' }}>
              <label><strong> Select Time Slots</strong></label>
              <div>
                <FormControl variant="outlined" style={{ width: '100%' }} className="select-dropdown">
                  <Select
                    id="timeslot"
                    name="timeslot"

                    onChange={this.handleTimeChange}
                  >
                    <MenuItem value=''>
                      <em>None</em>
                    </MenuItem>
                    {this.props.appointments.doctorSlots
                      ? this.props.appointments.doctorSlots.map((item, index) => {
                        if (((new Date(`${(item.slotsdate).split('T')[0]}T${(item.slotsstarttime).trim()}Z`) - new Date()) / 36e5 > 0)) {
                          return (
                            <MenuItem value={`{
                        "slotId":${item.id},
                        "doctorId":"${item.userid}",
                        "startTime":"${getColonSlotFormatedTime(getSlotLocalDateTime(item.slotsdate, item.slotsstarttime, 'time'))}",
                        "endTime":"${getColonSlotFormatedTime(getSlotLocalDateTime(item.slotsdate, item.slotsendtime, 'time'))}",
                        "date":"${item.slotsdate}",
                        "doctorMail":"${item.doctorslotsuseridmaprel.Userdata[0].email}"
                        }`} key={index}>
                              {getColonSlotFormatedTime(getSlotLocalDateTime(item.slotsdate, item.slotsstarttime, 'time')) + " - " + getColonSlotFormatedTime(getSlotLocalDateTime(item.slotsdate, item.slotsendtime, 'time'))
                                + ` (Dr. ${item.doctorslotsuseridmaprel.Userdata[0].firstname} ${item.doctorslotsuseridmaprel.Userdata[0].lastname})`}
                            </MenuItem>
                          )
                        }
                      })
                      : []}
                  </Select>
                </FormControl>
                {this.props.appointments.showErrorText ? <Typography style={{ marginTop: 4, color: 'red' }} variant='body2'>No slots available on selected date</Typography> : ''}
              </div>
            </div>
          </DialogContent>
          <DialogActions>
            <Button variant="contained" style={{ backgroundColor: 'white', color: '#28a745' }} onClick={this.handleClose}>Close</Button>
            <Button variant="contained" style={{ backgroundColor: '#28a745', color: 'white' }}
              onClick={() => { this.handleBookAppointment(this.state.slotInfo) }}
            >Book Now</Button>
          </DialogActions>

        </Dialog>
        <Dialog
          open={this.state.chatOpen}
          onClose={this.chatHandleClose}
          aria-labelledby="form-dialog-title"
          className="chatModalBox"
        >
          {/* <DialogTitle id="form-dialog-title">Subscribe</DialogTitle> */}
          <DialogContent>
            <Chat
              room={this.state.roomId}
              name={this.state.roomName}
              chatHandleClose={this.chatHandleClose}
              chatWithName={this.state.doctorName}
            />
          </DialogContent>
          {/* <DialogActions>
          <Button onClick={handleClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleClose} color="primary">
            Subscribe
          </Button>
        </DialogActions> */}

        </Dialog>       
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    appointments: state.appointments,
  };
};

const mapDispatchToProps = (dispatch) => {
  return {
    getAppointmentsStart: (data) => dispatch(getAppointmentsStart(data)),
    requestAppointmentsStart: (data) =>
      dispatch(requestAppointmentsStart(data)),
    bookAppointmentsStart: (data) => dispatch(bookAppointmentsStart(data)),
    reRequestAppointmentsStart: (data) =>
      dispatch(reRequestAppointmentsStart(data)),
    cancelAppointmentsStart: (data) =>
      dispatch(cancelAppointmentsStart(data)),
    getDoctorSlotsStart: (data) => dispatch(getDoctorSlotsStart(data)),
    redirectToPaymentPortal: () => dispatch(redirectToPaymentPortal()),
    getLatestmessageDate: (data) => dispatch(getLatestmessageDate(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Appointment);
