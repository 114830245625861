export const getTestsReportQuery = `
query allReports($obj: JSON){
  allReports(where: $obj){
    Reports{
        userid
        active
        id
        sampleid
        samplekitid
        createdon
        reportsampleidmaprel(where: {active: 1}){
          Sampledetails{
            active
            testid
            createdon
            sampledetailstestidmaprel(where: {active: 1}){
              Tests{
                active
                testname
                id
              }
            }
          }
        }
    }
  }
} 
`;
export const getTestResultQuery = `query($where:JSON){
  allReporttestresults(where:$where){
    Reporttestresults{
      testparametervalue
      testid
      createdon
      reporttestresultreportidmaprel{
        Reports{
          reportsampleidmaprel{
            Sampledetails{
              createdon
            }
          }
        }
      }
      
    }
  }
}`