import React, { Component } from 'react';
import {NavLink} from "react-router-dom";


class Science extends Component {
  render() {
    return (
        <div className= "pageWrap" style={{marginTop:"85px"}}>
          {/* <div style={{marginTop:"200px"}}><h1>Science</h1></div>*/}
          <main className="otherPage">
            <div className="breadcumb">
              <div className="container">
                <ul>
                  <li><NavLink to="/">Home</NavLink></li>
                  <li><NavLink to="/science">Science</NavLink></li>
                </ul>
              </div>
            </div>

            <section className="checkout">
              <div className="container">
                <div className="heading">
                  <div className="headshop"><h1>Science</h1></div>
                </div>
              </div>
            </section>
          </main>
        </div>
    );
  }
}

export default Science;