import { combineReducers } from 'redux';
import shoppingCart from './ShoppingCart';
import PatientsTests from './tests';
import appointmentsReducer from './appointment';
import registerKitReducer from './registerKit';
import userReducer from './user';
import kitSampleIdStatusReducer from './kitSampleIdStatus';
import testsReducer from './testsList';
import testDetailsReducer from './testDetails';
import checkoutReducer from './checkout';
import myAccountReducer from './myAccount';
import shippingAddressReducer from './shippingAddress';
import billingAddressReducer from './billingAddress'
import orderHistoryReducer from './orderHistory';
import authReducer from './auth/index';
import testsResultReducer from './testsResult';
import contactusReducer from './contactus';
import categoryBasedTestReducer from "./categoryBasedTest"
import chatReducer from './chatHistory';
import dashboard from './dashboard';
import testResult from './testResult';
import invoicetemplate from './invoicetemplate';
import appointmentHistory from './appointmentHistory';

export default combineReducers({
  auth: authReducer,
  cartDetails: shoppingCart,
  patients: PatientsTests,
  appointments: appointmentsReducer,
  registerKit: registerKitReducer,
  user: userReducer,
  kitSampleIdStatus: kitSampleIdStatusReducer,
  tests: testsReducer,
  testDetails: testDetailsReducer,
  checkout: checkoutReducer,
  myAccount: myAccountReducer,
  shippingAddress: shippingAddressReducer,
  billingAddress: billingAddressReducer,
  orderHistory: orderHistoryReducer,
  testsResult: testsResultReducer,
  contactus: contactusReducer,
  categoryBasedTests: categoryBasedTestReducer,
  chatHistory: chatReducer,
  testData: dashboard,
  result: testResult,
  invoiceData:invoicetemplate,
});