import { getTestParameterQuery } from "../../../Pages/Home/HomeQuery"
import { fetchGraphMethod } from "../../../Services/httpService"
import messagePopup from "../../../Services/messagePopupService"
import { GET_TEST_RESULT_FAILED, GET_TEST_RESULT_START, GET_TEST_RESULT_SUCCESS } from "../types"

export const getTestResult = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_TEST_RESULT_START
        })
        const response = await fetchGraphMethod(data.query, data.variables, true)

        const testParameter = await fetchGraphMethod(getTestParameterQuery, {
            where: {
                testid: response.data.data.allReporttestresults.Reporttestresults[0].testid
            }
        }, true)

        if (response.status === 200 && testParameter.status === 200 && response.data.data && testParameter.data.data) {
            dispatch({
                type: GET_TEST_RESULT_SUCCESS,
                payload: {
                    testResult: response.data.data.allReporttestresults.Reporttestresults[0],
                    testParameter: testParameter.data.data.allTestparameters.Testparameters[0]

                }
            })
        }
        else {
            dispatch({
                type: GET_TEST_RESULT_FAILED
            })
            messagePopup('', 'Get test Result failed', 'error')
        }

    } catch (error) {
        dispatch({
            type: GET_TEST_RESULT_FAILED
        })
        messagePopup('', 'Get test Result failed', 'error')
    }

}