import { getUserInfo } from '../../../Services/authService';
import { fetchGraphMethod, fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { GET_SHOPPING_CART_SUCCESS, SAVE_CHECKOUT_FAILED, SAVE_CHECKOUT_START, SAVE_CHECKOUT_SUCCESS } from '../types';
import history from '../../../history'

export const saveCheckoutPaynow = (data) => async (dispatch) => {
    dispatch({
        type: SAVE_CHECKOUT_START
    })
    try {
        const response = await fetchMethod(`/api/Userdata/saveOrderDetail`,data.apiData,'post',true);
        if(response && response.data && response.status === 200){
           if(response.data.response && response.data.response.status === 201){
            messagePopup('',response.data.response.msg,'error');
            dispatch({
                type: SAVE_CHECKOUT_FAILED,
                payload: response.data.response.msg
            })  
           }
           if(response.data.response && response.data.response.status === 200){
              for (const item of data.actualData) {
                const response1 = await fetchGraphMethod(data.query,{obj: {
                    testid: item.id,
                    userid: getUserInfo().id,
                    id: item.cartId,
                    active: 0
                }},true);
                if(response1.data && response1.data.data && response1.data.data.saveCart){
                    dispatch({
                        type: GET_SHOPPING_CART_SUCCESS,
                        payload: {
                            data: null,
                            cartCount: 0
                        }
                    })
                }
              }
            
            messagePopup('',response.data.response.msg,'success');
            dispatch({
                type: SAVE_CHECKOUT_SUCCESS,
                payload: response.data.response.msg
            });
            history.push('/') 
           }
        } else {
            messagePopup('','Save Shipping Address Failed','error');
            dispatch({
                type: SAVE_CHECKOUT_FAILED,
                payload: 'Save Shipping Address Failed'
            }) 
        }
    } catch (error) {
       messagePopup('','Save Shipping Address Failed','error');
       dispatch({
           type: SAVE_CHECKOUT_FAILED,
           payload: 'Save Shipping Address Failed'
       })
    }
};