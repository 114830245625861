import { GET_SHOPPING_CART_START, GET_SHOPPING_CART_SUCCESS, GET_SHOPPING_CART_FAILED, ADD_SHOPPING_CART_START, ADD_SHOPPING_CART_SUCCESS, ADD_SHOPPING_CART_FAILED, GET_CART_TOTAL_COUNT } from '../actions/types';

const initialState = {
  cartCount: 0,
  data: null,
  error: null,
  loading: false
};

export default function(state = initialState, action) {
  switch (action.type) {
    case GET_SHOPPING_CART_START:
      return {
        ...state,
        loading: true,
      };
    case GET_SHOPPING_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        cartCount: action.payload.cartCount,
        error: null
      };
    case GET_SHOPPING_CART_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        cartCount: 0,
        error: action.payload
      };
    case ADD_SHOPPING_CART_START:
      return {
        ...state,
        loading: true,
      };
    case ADD_SHOPPING_CART_SUCCESS:
      return {
        ...state,
        loading: false,
        data: action.payload.data,
        cartCount: action.payload.cartCount,
        error: null
      };
    case ADD_SHOPPING_CART_FAILED:
      return {
        ...state,
        loading: false,
        data: null,
        cartCount: 0,
        error: action.payload
      };
    case GET_CART_TOTAL_COUNT:
        return {
          ...state,
          // loading: false,
          data: action.payload.data,
          cartCount:  action.payload.cartCount,
          error: null
    };
    default:
      return {...state};
  }
}