import { GET_TEST_LIST_FAILED, GET_TEST_LIST_START, GET_TEST_LIST_SUCCESS, GET_TEST_PARAMETER_FAILED, GET_TEST_PARAMETER_START, GET_TEST_PARAMETER_SUCCESS } from "../actions/types";

const initialData = {
    loading: false,
    testList: [],
    testResult: [],
    testParameter: [],
    data: null,
    error: null
}

export default function (state = initialData, action) {
    switch (action.type) {
        case GET_TEST_LIST_START:
            return {
                ...state,
                loading: true,
            };
        case GET_TEST_LIST_SUCCESS:
            return {
                ...state,
                testResult: action.payload.testResult,
                testList: action.payload.testList,
                loading: false,
                error: null
            };
        case GET_TEST_LIST_FAILED:
            return {
                ...state,
                error: action.payload,
                data: null,
                loading: false
            };
        case GET_TEST_PARAMETER_START:
            return {
                ...state,
                loading: true,
            }
        case GET_TEST_PARAMETER_SUCCESS:
            return {
                ...state,
                testParameter: action.payload,
                loading: false,
                error: null
            }
        case GET_TEST_PARAMETER_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        default:
            return { ...state };
    }
}