import React, { Component } from "react";
import { ZoomMtg } from '@zoomus/websdk'
 import { KJUR } from "jsrsasign";
 import { getUserInfo } from '../../Services/authService';
 import {fetchGraphMethod, fetchMethod} from '../../Services/httpService'
 import {getAppointmentDetailsQuery} from "./AppointmentQuery"
 require('dotenv').config()

var leaveUrl = 'https://thulsee.com/'
class JoinCall extends Component {
    constructor(props) {
        super(props);
        this.state = {
            meetingId:window.location.href.split("callJoin/")[1],
        }
    }
    componentDidMount(){
       this.showZoomDiv()
        ZoomMtg.setZoomJSLib('https://source.zoom.us/2.17.0/lib', '/av')
        ZoomMtg.preLoadWasm()
        ZoomMtg.prepareWebSDK()
        ZoomMtg.i18n.load("en-US");
        ZoomMtg.i18n.reload("en-US");
        this.getSignature()
    };
    showZoomDiv = () =>{
        document.getElementById('zmmtg-root').style.display = 'block'
    }

     getSignature = async(e) => {
        console.log("khjjkfd",this.state.meetingId)
        let joinAppointment = window.location.href.split("callJoin/")[1]?.split("/")||[]
        console.log("ldkjkfd",joinAppointment)
        const response = await fetchMethod(
            `/getMeetingDetails?appointmentId=${joinAppointment[0]}&userId=${joinAppointment[1]}`,
             {},
            "post",
            false
          );
          console.log("response",response.data.data)
        const meetingDetails= response?.data?.data?.appointmentDetails|| []
        const callJoinUser = response?.data?.data?.userData|| []
        const iat = Math.round(new Date().getTime() / 1000) - 30;
        const exp = iat + 60 * 60 * 2;
        const oHeader = { alg: "HS256", typ: "JWT" };
        const oPayload = {
            sdkKey: process.env.REACT_APP_APP_KEY,
            mn: meetingDetails ? meetingDetails[0].callid : 0,
            role: 0,
            iat: iat,
            exp: exp,
            appKey: process.env.REACT_APP_APP_KEY,
            tokenExp: iat + 60 * 60 * 2,
        };
        const sHeader = JSON.stringify(oHeader);
        const sPayload = JSON.stringify(oPayload);
        const sdkSecret=process.env.REACT_APP_APP_SECRET_KEY
        const signature = KJUR.jws.JWS.sign("HS256", sHeader, sPayload, sdkSecret);
        this.initialMeeting(signature,meetingDetails,callJoinUser)
      }
    initialMeeting = (signature,meetingDetails,userDetails) =>{
        ZoomMtg.init({
            leaveUrl: leaveUrl,
            disableInvite: true,
            success: (success) => {
                console.log("kjfdhkj",success,userDetails,userDetails?userDetails[0].firstName + " " + userDetails[0].lastName : "")
                ZoomMtg.join({
                    signature: signature,
                    meetingNumber: meetingDetails ? meetingDetails[0].callid : 0,
                    userName: userDetails?userDetails[0].firstName + " " + userDetails[0].lastName : "",
                    sdkKey:process.env.REACT_APP_APP_KEY,
                    userEmail:getUserInfo()?.email || "",
                    passWord: meetingDetails ? meetingDetails[0].callPassword : 0,
                    tk: "",
                    success: (success2) => {
                        console.log(success2);
                    },
                    error: (error) => {
                        console.log(error);
                        document.getElementById("zmmtg-root")?.setAttribute("style", "display: none");
                    },
                });
            },
            error: (error) => {
                document.getElementById("zmmtg-root")?.setAttribute("style", "display: none");
                console.log(error);
            },
        });       
    }
    render(){
        return(
        <div className="zoom">Zoom</div>
        )

    }
}

export default JoinCall