import { Message } from "@material-ui/icons";
import { fetchGraphMethod } from "../../../Services/httpService"
import messagePopup from "../../../Services/messagePopupService";
import { GET_APPOINTMENT_INVOICE_DETAILS_FAILED, GET_APPOINTMENT_INVOICE_DETAILS_START, GET_APPOINTMENT_INVOICE_DETAILS_SUCCESS, GET_INVOICE_DETAILS_FAILED, GET_INVOICE_DETAILS_START, GET_INVOICE_DETAILS_SUCCESS } from "../types"


export const getInvoiceDetails = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_INVOICE_DETAILS_START
        })
        const response = await fetchGraphMethod(data.query, data.variables, true);
        if (response.status === 200 && response.data.data) {
            dispatch({
                type: GET_INVOICE_DETAILS_SUCCESS,
                payload: response.data.data.allPayments.Payments[0]
            })
        }
        else {
            dispatch({
                type: GET_INVOICE_DETAILS_FAILED
            })
            messagePopup('', 'Get invoice details failed', 'error')
        }

    } catch (error) {
        dispatch({
            type: GET_INVOICE_DETAILS_FAILED
        })
        messagePopup('', 'Get invoice details failed', 'error')

    }
}

export const getAppointmentInvoiceDetails = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_APPOINTMENT_INVOICE_DETAILS_START
        })
        const response = await fetchGraphMethod(data.query, data.variables, true)
        if (response.status === 200 && response.data.data) {
            dispatch({
                type: GET_APPOINTMENT_INVOICE_DETAILS_SUCCESS,
                payload: response.data.data.allPayments.Payments[0]
            })
        }
        else {
            dispatch({
                type: GET_APPOINTMENT_INVOICE_DETAILS_FAILED,
                payload: "GET APPOINTMENT INVOICE DETAILS FAILED"
            })
            messagePopup('', 'Get appointment details failed.')
        }
    } catch (error) {
        dispatch({
            type: GET_APPOINTMENT_INVOICE_DETAILS_FAILED,
            payload: "GET APPOINTMENT INVOICE DETAILS FAILED"
        })
        messagePopup('', 'Get appointment details failed.')
    }
}