export const savePersonalInfoQuery = `
mutation saveUserdata($obj: UserdataInput!){
  saveUserdata(obj: $obj){
    id
    firstname
    lastname
    email
    phonenumber
    active
    address
    profileimagename
  }
} 
`;

export const getAppointmentInvoiceDetailsQuery=`query($where:JSON){
  allPayments(where:$where){
    Payments{
      appointmentid
      amount
      dateofpayment
      stripepaymentmethod
      orderid
      paymentappointmentidmaprel{
        Appointments{
          acceptstatus
          appointmentdoctoruseridmaprel{
            Userdata{
              firstname
              lastname
              
            }
          }
          appointmentslotsidmaprel{
            Doctorslots{
              slotsdate
              slotsstarttime
              slotsendtime
            }
          }
          
        }
      }
    }
  }
}`

export const getInvoiceDetailsQuery=`query($where:JSON){
  allPayments(where:$where){
    Payments{
      dateofpayment
      amount
      stripepaymentid
      stripepaymentmethod
      stripecustomerid
      paymentorderidmaprel{
        Orders{
          orderProductDetailsOrderIdMap{
            Orderproductdetails{
              orderproductdetailstestidrel{
                Tests{
                  testname
                  price
                }
              }
            }
          }
        }
      }
    }
  }
}`;

export const getPersonalInfoQuery = `
query allUserdata($obj: JSON){
  allUserdata(where: $obj){
    Userdata{
      id
      firstname
      lastname
      email
      phonenumber
      active
      address
      profileimagename
    }
  }
} 
`;

export const saveShippingAddressQuery = `
mutation saveShippingaddress($obj: ShippingaddressInput!){
  saveShippingaddress(obj: $obj){
    area
    buildingdetails
    city
    id
    landmark
    streetaddress
    userid
  }
} 
`;

export const getShippingAddressQuery = `
query allShippingaddresses($obj: JSON){
  allShippingaddresses(where: $obj){
    Shippingaddresses{
        area
        buildingdetails
        city
        id
        landmark
        streetaddress
        userid
    }
  }
} 
`;

export const saveBillingAddressQuery = `
mutation saveBillingaddress($obj: BillingaddressInput!){
  saveBillingaddress(obj: $obj){
    area
    buildingdetails
    city
    id
    landmark
    streetaddress
    userid
    sameasshipping
  }
} 
`;

export const getBillingAddressQuery = `
query allBillingaddresses($obj: JSON){
  allBillingaddresses(where: $obj){
    Billingaddresses{
        area
        buildingdetails
        city
        id
        landmark
        streetaddress
        userid
        sameasshipping
    }
  }
} 
`;