import React from 'react';
import { NavLink } from 'react-router-dom';
import "../../assets/css/changepassword.scss";
import passwordimgSvg from "../../assets/img/passwordimg.svg";
import { IconButton, InputAdornment, TextField } from '@material-ui/core';
import Visibility from '@material-ui/icons/Visibility';
import VisibilityOff from '@material-ui/icons/VisibilityOff';
import { connect } from 'react-redux';
import {changePasswordStart} from '../../Redux/actions/auth/changePassword'
import BackdropLoader from '../../Services/loader';
import messagePopup from '../../Services/messagePopupService';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            oldPassword: '',
            newPassword: '',
            confirmPassword: '',
            fieldErrors: {},
        }
    }

    handleInputChange = (e) => {
      const {name, value} = e.target;
      this.validateErrors(name,value);
      this.setState({[name]: value})
    }
    
    resetForm = () => {
      this.setState({
        oldPassword: '',
        newPassword: '',
        confirmPassword: ''
      })
    }
    changePasswordSubmit = (e) => {
       e.preventDefault();
       let tempErrMsg = this.resetPasswordValidation();
       if(!tempErrMsg) {
        const {oldPassword,newPassword} = this.state
        if(oldPassword !== newPassword) {
          this.props.changePasswordStart({oldPassword,newPassword,resetForm: this.resetForm})
        } else {
          messagePopup('','Current password and New password must be different','error')
        }
      } 
      // else {
      //   messagePopup('',tempErrMsg,'error')
      // }
    }

    handleCancelClick = (e) => {
      e.preventDefault();
      this.props.history.push('/')
    }

    validateErrors = (name,value) => {
      let {fieldErrors} = this.state;
      if(name === 'newPassword'){
         fieldErrors['newPassword'] = value ? value.trim().length > 6 ? "" : "Password should be greater than 6 characters" : 'New Password is required';
         fieldErrors['confirmPassword'] = this.state.confirmPassword !== value ? 'New password and confirm password must be same' : ''
      }
      if(name === 'confirmPassword'){
        fieldErrors['confirmPassword'] = this.state.newPassword !== value ? 'New password and confirm password must be same' : ''
      }
      if(name === 'oldPassword'){
          fieldErrors['oldPassword'] = value === '' ? 'Current Password is required' : '';
      }
      this.setState({fieldErrors})
    }
  
    resetPasswordValidation = () => {
      let tempfieldErrors = {};
      tempfieldErrors['newPassword'] = this.state.newPassword ? this.state.newPassword.trim().length > 6 ? "" : "Password should be greater than 6 characters" : 'New Password is required';
      tempfieldErrors['confirmPassword'] = this.state.newPassword !== this.state.confirmPassword ? 'New password and confirm password must be same' : ''
      tempfieldErrors['oldPassword'] = this.state.oldPassword === '' ? 'Current Password is required' : '';
      this.setState({fieldErrors: tempfieldErrors})
      return tempfieldErrors['oldPassword'] 
             ? tempfieldErrors['oldPassword'] 
             : tempfieldErrors['newPassword'] 
             ? tempfieldErrors['newPassword'] 
             : tempfieldErrors['confirmPassword'] 
             ? tempfieldErrors['confirmPassword'] 
             : '';
    }

    render()
    {
        return(
            <div  className= "pageWrap"  style={{marginTop:"85px"}}>
                <main className="changepasswordpage">
                    {/* <!-- breadcumb starts here -->	 */}
                    <div className="breadcumb">
                        <div className="container">
                            <ul>
                                <li><NavLink to="/">Home</NavLink></li>
                                <li><NavLink to="/">My Account</NavLink></li>
                            </ul>
                        </div>
                    </div>
                    <BackdropLoader open={this.props.changePassword.loading} />
                    {/* <!-- password change code starts here -->	 */}
                    <section className="password">
                        <div className="container">
                            <h1>Change Password</h1>
                            <div className="passwordwrap">
                                <div className="passfeildwrap">
                                    <form action="">
                                        <div className="form-group">
                                            <TextField 
                                              id="oldpassword" 
                                              label="Current Password" 
                                              variant="outlined" 
                                              className="text-field"
                                              autoComplete="off"
                                              name="oldPassword"
                                              value={this.state.oldPassword}
                                              onChange={this.handleInputChange}
                                              type={'password'}
                                              required
                                              error={this.state.fieldErrors['oldPassword'] ? true : false}
                                              helperText={this.state.fieldErrors['oldPassword'] ? this.state.fieldErrors['oldPassword'] : ''}
                                            />
                                        </div>
                                        <div className="form-group">
                                            <TextField 
                                              id="newpassword" 
                                              label="New Password" 
                                              variant="outlined" 
                                              className="text-field"
                                              autoComplete="off"
                                              name="newPassword"
                                              value={this.state.newPassword}
                                              onChange={this.handleInputChange}
                                              type={'password'}
                                              required
                                              error={this.state.fieldErrors['newPassword'] ? true : false}
                                              helperText={this.state.fieldErrors['newPassword'] ? this.state.fieldErrors['newPassword'] : ''}
                                            />
                                        </div>
                                        
                                        <div className="form-group">
                                            <TextField 
                                              id="newpasswordagain" 
                                              label="Confirm Password" 
                                              variant="outlined" 
                                              className="text-field"
                                              autoComplete="off"
                                              name="confirmPassword"
                                              value={this.state.confirmPassword}
                                              onChange={this.handleInputChange}
                                              type={'password'}
                                              error={this.state.fieldErrors['confirmPassword'] ? true : false}
                                              helperText={this.state.fieldErrors['confirmPassword'] ? this.state.fieldErrors['confirmPassword'] : ''}
                                            />
                                        </div>
                                        <button className="btncancle" onClick={this.handleCancelClick}>Cancel</button>
                                        <button className="btnsubmit" type="submit" onClick={this.changePasswordSubmit}>Change Password</button>
                                    </form>
                                </div>
                                <div className="passimgwrap">
                                    <div className="passimg">
                                        <img src={passwordimgSvg} alt="passwordimg" />
                                    </div>
                                </div>
                            </div>
                        </div> 
                    </section> 
                </main>
            </div>
        )
    }
} // changePasswordStart

const mapStateToProps = (state) => {
  return { 
      changePassword: state.auth.changePassword
  }
};
const mapDispatchToProps = (dispatch) => {
  return {
    changePasswordStart: (data) => dispatch(changePasswordStart(data))
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(ChangePassword);