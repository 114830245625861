export const getTestListQuery = `
query allTests($obj: JSON){
  allTests(where: $obj){
    Tests{
        description
        id
        price
        testname
        active
    }
  }
} 
`
export const getTestResultsQuery = `query($where:JSON){
  allReporttestresults(where:$where){
    Reporttestresults{
      testid
      testparametervalue
      createdon
    }
  }
}`
export const getTestNameQuery = `query{
  allTests{
    Tests{
      id
      testname
    }
  }
}`
export const getTestParameterQuery = `query($where:JSON){
  allTestparameters(where:$where){
    Testparameters{
      testid
      lowvalue
      optimumvalue
      highvalue
      measure
      testparametertestidmaprel{
        Tests{
          testname
        }
      }
    }
  }
}`