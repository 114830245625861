import { GET_USER_INFO_START, GET_USER_INFO_SUCCESS, GET_USER_INFO_FAILED } from '../actions/types';

const initialData = {
   loading: false,
   data: null,
   error: null
}


export default function(state = initialData, action) {
    switch(action.type) {
        case GET_USER_INFO_START:
            return {
                ...state,
                loading: true
            };
        case GET_USER_INFO_SUCCESS: 
            return {
                ...state,
                loading: false,
                error: null,
                data: action.payload
            }
        case GET_USER_INFO_FAILED: 
            return {
                ...state,
                loading: false,
                error: action.payload,
                data: null
            }
        default: 
            return {
                ...state
            }
    }
}