import { CANCEL_CHAT_START, GET_CHAT_FAILED, GET_CHAT_START, GET_CHAT_SUCCESS, SAVE_CHAT_START, UPDATE_CHAT_FAILED, UPDATE_CHAT_START } from "../actions/types";

const INIT_STATE = {
    loading: false,
    data: null,
    error: null
};
export default function chatReducer(state = INIT_STATE, action) {
    switch (action.type) {
        case GET_CHAT_START:
            return {
                ...state,
                loading: true,
                data: null,
            }
        case SAVE_CHAT_START:
            return {
                ...state,
                loading: true,
                // data: null
            }
        case UPDATE_CHAT_START:
            return {
                ...state,
                loading: true,
            }
        case CANCEL_CHAT_START:
            return {
                ...state,
                loading: true,
            }
        case UPDATE_CHAT_FAILED:
            return {
                ...state,
                loading: false,
                error: action.payload
            }
        case GET_CHAT_SUCCESS:
            return {
                ...state,
                loading: false,
                data: action.payload,
                error: null
            }
        case GET_CHAT_FAILED:
            return {
                ...state,
                loading: false,
                data: null,
                error: action.payload
            }
        default: return { ...state }
    }
}
