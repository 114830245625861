import { fetchMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { SAVE_CONTACT_US_FAILED, SAVE_CONTACT_US_START, SAVE_CONTACT_US_SUCCESS } from '../types';
import history from '../../../history'
import { getCartData, removecartLocal, setAuthToken, setUserInfo } from '../../../Services/authService';

export const contactusStart = (data) => async (dispatch) => {
    dispatch({
        type: SAVE_CONTACT_US_START
    })
    try {
        const response = await fetchMethod(`/api/Userdata/userQuery`,data,'post',false);
        
        if(response && response.status === 200){
          if(response.data && response.data.response && response.data.response.status === 200) {
            messagePopup('', response.data.response.msg, 'success');
            dispatch({
                type: SAVE_CONTACT_US_SUCCESS,
                payload: response.data.response.msg
            })
          }
        } else {
          messagePopup('', 'Contact Us failed', 'error');
          dispatch({
            type: SAVE_CONTACT_US_FAILED,
            payload: 'Contact Us failed'
          })
        }
      } catch (error) {
        messagePopup('', 'Contact Us failed', 'error');
        dispatch({
            type: SAVE_CONTACT_US_FAILED,
            payload: 'Contact Us failed'
        })
      }
    
};