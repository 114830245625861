import React, { Component } from "react";
import { NavLink } from "react-router-dom";
import "../../assets/css/product-listing.scss";
import leafImg from "../../assets/img/leaf.svg";
import { Link } from "@material-ui/core";
import { connect } from "react-redux";
import BackdropLoader from "../../Services/loader";
import productImg from "../../assets/img/kit.png";
import { getcategoryBasedTestList } from "../../Redux/actions/categorybasedTest/categorybasedTest";
import Login from "../../../src/Common/Login/login"
import { getAuthToken, getUserInfo } from "../../Services/authService";
import {
  addShoppingCartData,
  addUserShoppingCartData,
  getUserShoppingCartData,
} from "../../Redux/actions/Shopping-Cart/actions";
import {
  getAllShoppingCartsQuery,
  saveShoppingCartDataQuery,
} from "../Shopping-Cart/ShoppingCartQuery";
import Tooltip from "@material-ui/core/Tooltip";
import messagePopup from "../../Services/messagePopupService";

class Men extends Component {
  constructor(props) {
    super(props);
    this.state = {};
  }

  componentDidMount = () => {
    this.props.getcategoryBasedTestList({ category: 'Mens' });
  };

  handleProductClick = (data) => {
    this.props.history.push(`/test_details/${data.testId}?from=men`);
  };

  handleAddToCart = (data) => {
    if (getAuthToken()) {
      if (!this.props.cartDetails?.data.map(val => val.id).includes(data.testId)) {
        const variables = {
          testid: data.testId,
          quantity: "1",
          userid: getUserInfo().id,
        };
        messagePopup('', 'Item added Successfully', 'success')
        this.props.addUserShoppingCartData({
          query: saveShoppingCartDataQuery,
          variables,
          cuurentcartCount: this.props.cartDetails.cartCount,
        });
      }
      else {
        messagePopup('', 'test added already', 'error')
      }
    } else {
      this.props.addToCart({
        ...data,
        quantity: 1,
      });
    }
  };

  scrollDown = () => {
    window.scrollTo({
      top: 0
    });
  };

  render() {
    return (
      <div className="pageWrap" style={{ marginTop: "85px" }}>
        <BackdropLoader open={this.props.categoryBasedTests.loading} />
        <section className="productlist" id="product">
          <img className="leafimage" src={leafImg} alt="" />
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="breadcumb">
                  <ul>
                    <li>
                      <NavLink to="/">Home</NavLink>
                    </li>
                    <li>
                      <NavLink to="/men_health_tests">Men's health test</NavLink>
                    </li>
                  </ul>
                </div>
              </div>
            </div>

            <div className="row">
              <div className="col-lg-3 col-md-12">
                <div className="innerpagenav ">
                  <div className="sidesticky ">
                    <p>Get your health Tests</p>
                    <ul>
                      <li>
                        <NavLink to="/shop_all_test">Shop All Tests</NavLink>
                      </li>
                      <li>
                        <NavLink to="/wellness_tests">Wellness Tests</NavLink>
                      </li>
                      <li>
                        <NavLink to="/women_health_tests">
                          Women's Health Tests
                        </NavLink>
                      </li>
                      <li>
                        <NavLink to="/men_health_tests">
                          Men's Health Tests
                        </NavLink>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>

              <div className="col-lg-9 col-md-12">
                <div className="productlisting">
                  <h1>Men's Health Test</h1>
                  <div className="productitems">
                    {this.props.categoryBasedTests.data &&
                      this.props.categoryBasedTests.data.map((item, index) => (
                        <div className="productbox" key={index}>
                          <div className="productimg" onClick={this.scrollDown}>
                            <Link
                              onClick={(evt) => this.handleProductClick(item)}
                            >
                              {item.filepath ?
                                <img src={item.filepath} alt="kit name" /> :
                                <img src={productImg} alt="kit name" />}
                            </Link>
                          </div>
                          <Tooltip title={item.testName} placement="bottom">
                            <div className="productname" onClick={this.scrollDown}>
                              <Link
                                onClick={(evt) => this.handleProductClick(item)}
                              >
                                {item.testName}
                              </Link>
                            </div>
                          </Tooltip>  
                          <div className="productprice">AED {item.price}</div>
                          <Tooltip title={item.description} placement="bottom">
                            <div className="productdetail">
                              {item.description}
                            </div>
                          </Tooltip>
                          {getAuthToken() ?
                            <button onClick={() => this.handleAddToCart(item)}>Add to cart </button> :
                            <Login btnType="login" >
                              <button onClick={() => this.handleAddToCart(item)}>Add to cart </button>
                            </Login>
                          }
                        </div>
                      ))}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    categoryBasedTests: state.categoryBasedTests,
    cartDetails: state.cartDetails
  };
};
const mapDispatchToProps = (dispatch) => {
  return {
    getcategoryBasedTestList: (data) => dispatch(getcategoryBasedTestList(data)),
    addUserShoppingCartData: (data) => dispatch(addUserShoppingCartData(data)),
    addToCart: (data) => dispatch(addShoppingCartData(data)),
    getUserShoppingCartData: (data) => dispatch(getUserShoppingCartData(data)),
  };
};

export default connect(mapStateToProps, mapDispatchToProps)(Men);
