export function logout() {
  localStorage.clear();
  document.cookie = "authToken=; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;";
}

export function setAuthToken(token) {
  let now = new Date();
  let minutes = 30;
  now.setTime(now.getTime() + (minutes * 60 * 1000));
  document.cookie=`authToken=${token};expires=${now.toUTCString()};`
  // localStorage.setItem("authToken", token);
}

export function getAuthToken() {
  // return localStorage.getItem("authToken");
  return getCookie("authToken");
}

export function getUserInfo() {
  return JSON.parse(localStorage.getItem("user"));
}

export function setUserInfo(data) {
  localStorage.setItem("user", JSON.stringify(data));
}

export function getCartData() {
  return JSON.parse(localStorage.getItem("cartData"));
}

export function setCartData(data) {
  localStorage.setItem("cartData", JSON.stringify(data));
}

export function removecartLocal () {
  localStorage.removeItem('cartData')
}

function getCookie(cname) {
  let name = cname + "=";
  let decodedCookie = decodeURIComponent(document.cookie);
  let ca = decodedCookie.split(';');
  for(let i = 0; i <ca.length; i++) {
    let c = ca[i];
    while (c.charAt(0) == ' ') {
      c = c.substring(1);
    }
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
}

export default {
  logout,
  setAuthToken,
  getAuthToken,
  setUserInfo,
  getUserInfo,
  getCartData,
  setCartData,
  removecartLocal
};
