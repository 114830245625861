import React, { Component } from 'react';
import itemminusSvg from "../../assets/img/itemminus.svg";
import itemplusPng from "../../assets/img/itemplus.png";
import trashIconSvg from "../../assets/img/trash-icon.svg";


const btnStyle = {
    border: 'none',
    backgroundColor: '#fff'
}

const delBtnStyle = {
  marginTop: '-8px',
  border: 'none',
  backgroundColor: '#fff'
}

class CartData extends Component {
    state = {
      open: false,
      items: 0
    };

    handleAddItems = (data) => {
      // this.props.handleAddItems(data)
    }
    
    handleDecreaseItems = (data) => {
      if(this.props.data.quantity > 1) {
        // this.props.handleDecreaseItems(data)
      }
    }

    render() {
        const { id } = this.props.todo;
      return (
       <div>
             <div className="column">
                <div className="quantityprice">
                    {/* <div className="quantiyimg"><button onClick={() => this.handleDecreaseItems(this.props.data)} style={btnStyle}><img src={itemminusSvg} alt=""/></button></div> */}
                    {/* <div className="quantiy">{this.props.data.quantity}</div> */}
                    <button className="quantiyimg" onClick={() => this.props.handleDel(this.props.data)} style={delBtnStyle}><img src={trashIconSvg} alt="trash-icon" style={{width: '22px',height: '22px'}}/></button>
                    {/* <div className="quantiyimg"><button onClick={() => this.handleAddItems(this.props.data)} style={btnStyle}><img src={itemplusPng} alt=""/></button></div> */}
                </div>

                {/* <div className="remove"> 
                    <button onClick={() => this.props.handleDel(this.props.data)} style={btnStyle}><img src={trashIconSvg} alt="trash-icon"/></button>
                </div> */}
            </div>
         </div>
       );
     }
   }


   export default CartData;
