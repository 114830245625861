import React from 'react';
import onlineIcon from '../../../assets/imgs/onlineIcon.png';
import closeIcon from '../../../assets/imgs/closeIcon.png';

import './infoBar.css';
import { Link } from 'react-router-dom';

const InfoBar = ({ room,chatHandleClose,docName }) => (
  <div className="infoBar">
    <div className="leftInnerContainer">
      <img className="onlineIcon" src={onlineIcon} alt="online icon" />
      <h4>{docName}</h4>
    </div>
    <div className="rightInnerContainer">
      <Link to="#" onClick={() => chatHandleClose()}><img src={closeIcon} alt="close icon" /></Link>
    </div>
  </div>
);

export default InfoBar;