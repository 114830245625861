import { unixTimestampToDate } from '../../../Services/dateTimeFormatter';
import { fetchGraphMethod } from '../../../Services/httpService';
import messagePopup from '../../../Services/messagePopupService';
import { GET_APPOINTMENT_HISTORY_FAILED, GET_APPOINTMENT_HISTORY_START, GET_APPOINTMENT_HISTORY_SUCCESS, GET_ORDER_HISTORY_FAILED, GET_ORDER_HISTORY_START, GET_ORDER_HISTORY_SUCCESS } from '../types';

function formatDate(UNIX_timestamp) {
    var a = new Date(UNIX_timestamp);
    var months = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    var year = a.getFullYear();
    var month = months[a.getMonth()];
    var date = a.getDate();
    return (date + ' ' + month + ' ' + year)
}
export const getOrderHistory = (data) => async dispatch => {
    try {
        dispatch({
            type: GET_ORDER_HISTORY_START
        })
        const response = await fetchGraphMethod(data.query, data.variables, true);
        if (response && response.data && response.status === 200) {
            const allOrders = response.data.data.allPayments.Payments ? response.data.data.allPayments.Payments : [];
            let finalOrderData = [];

            for (const item of allOrders) {
                if (item.paymentorderidmaprel.Orders.length > 0) {
                    const slotDetails = item.paymentorderidmaprel.Orders[0].orderdeliverslotidmaprel.Doctorslots[0] ? item.paymentorderidmaprel.Orders[0].orderdeliverslotidmaprel.Doctorslots[0] : [];
                    let slotStartTimeInUtc = slotDetails != "" ? (slotDetails.slotsdate).split('T')[0] + "T" + slotDetails.slotsstarttime + '.000Z' : ""
                    let slotStartTime = slotDetails != "" ? new Date(slotStartTimeInUtc).toLocaleString('en-GB').split(',')[1] : ""
                    let slotEndTimeInUtc = slotDetails != "" ? (slotDetails.slotsdate).split('T')[0] + "T" + slotDetails.slotsendtime + '.000Z' : ""
                    let slotEndTime = slotDetails != "" ? new Date(slotEndTimeInUtc).toLocaleString('en-GB').split(',')[1] : ""
                    let tempObj = {};
                    tempObj['userid'] = item.paymentorderidmaprel.Orders[0].userid;
                    tempObj['orderstatus'] = item.paymentorderidmaprel.Orders[0].orderstatus;
                    tempObj['orderdate'] = item.paymentorderidmaprel.Orders[0].orderdate ? unixTimestampToDate(item.paymentorderidmaprel.Orders[0].orderdate) : '';
                    tempObj['orderid'] = item.paymentorderidmaprel.Orders[0].id;
                    tempObj['slotDate'] = slotDetails != "" ? (slotDetails.slotsdate).split('T')[0] : "";
                    tempObj['slotStartDate'] = slotStartTime;
                    tempObj['slotEndDate'] = slotEndTime;
                    tempObj['quantity'] = item.paymentorderidmaprel.Orders[0].orderProductDetailsOrderIdMap.Orderproductdetails[0].quantity
                    tempObj['nurseName'] = item.paymentorderidmaprel.Orders[0].nursename;
                    tempObj['deliveryStatus'] = item.paymentorderidmaprel.Orders[0].testkitdelivertouser == 1 ? "In Progress" : (item.paymentorderidmaprel.Orders[0].testkitdelivertouser == 2 ? "Delivered" : item.paymentorderidmaprel.Orders[0].testkitdelivertouser == 3 ? "Completed" : "");
                    for (const orderdata of item.paymentorderidmaprel.Orders[0].orderProductDetailsOrderIdMap.Orderproductdetails) {

                        const testDetails = orderdata.orderproductdetailstestidrel.Tests[0] ? orderdata.orderproductdetailstestidrel.Tests[0] : null;
                        tempObj['description'] = testDetails ? testDetails.description : '';
                        tempObj['testname'] = testDetails ? testDetails.testname : '';
                        tempObj['price'] = testDetails ? testDetails.price : '';
                        tempObj['testid'] = testDetails ? testDetails.id : '';
                        finalOrderData = [{ ...tempObj }, ...finalOrderData]
                    }


                }
                else {
                    const appointmentData = item.paymentappointmentidmaprel.Appointments
                    for (const data of appointmentData) {
                        let tempObj = {};
                        tempObj["orderid"] = data.id
                        tempObj["orderdate"] = formatDate(data.createdon)
                        tempObj["price"] = data.paymentAppointmentIdMap.Payments[0].amount
                        tempObj["slotDate"] = data.appointmentslotsidmaprel.Doctorslots[0].slotsdate
                        tempObj["slotStartDate"] = data.appointmentslotsidmaprel.Doctorslots[0].slotsstarttime
                        tempObj["slotEndDate"] = data.appointmentslotsidmaprel.Doctorslots[0].slotsendtime
                        tempObj["nurseName"] = `${data.appointmentdoctoruseridmaprel.Userdata[0].firstname} ${data.appointmentdoctoruseridmaprel.Userdata[0].lastname}`
                        tempObj["deliveryStatus"] = data.acceptstatus
                        tempObj["itemName"] = "Appointment"
                        finalOrderData = [{ ...tempObj }, ...finalOrderData]
                    }
                }
            }
            for (const val of finalOrderData) {
            }
            dispatch({
                type: GET_ORDER_HISTORY_SUCCESS,
                payload: finalOrderData
            })
        } else {
            messagePopup('', 'Get Order History Failed', 'error');
            dispatch({
                type: GET_ORDER_HISTORY_FAILED,
                payload: 'Get Order History Failed'
            })
        }
    } catch (error) {
        messagePopup('', 'Get Order History Failed', 'error');
        dispatch({
            type: GET_ORDER_HISTORY_FAILED,
            payload: 'Get Order History Failed'
        })
    }
}