export const getUserInfoQuery = `
query allUserdata($obj: JSON){
  allUserdata(where: $obj){
    Userdata{
      id
      firstname
      lastname
      email
      phonenumber
      active
      address
      profileimagename
    }
  }
} 
`