import React from "react";
import MessageRow from "./MessageRow";
import "./inputChatMessages.css";

const GroupMessages = ({ groupMessages, date }) => {
  return (
    <div>
      <div style={{ textAlign: "center" }}>{date}</div>
      {React.Children.toArray(
        groupMessages &&
          groupMessages.map((message) => (
            <MessageRow message={message} messages={groupMessages} />
          ))
      )}
    </div>
  );
};

export default GroupMessages;
