import { TextField } from '@material-ui/core';
import React, { Component } from 'react';
import '../../assets/css/myprofile.scss';
import plusIcon from '../../assets/img/plue-wht.svg';


class MyProfile extends Component {
    state = {}
    render(){
        return(
            <div>
                <main className="myprofile">
        <section className="profilewrap">
            <div className="container">
                <div className="row">
                    <div className="col-12">
                        <div className="heading"><h1>My Profile</h1></div>
                        <div className="profilefield">
                            <form action="">
                                <div className="form-row">                            
                                    <div className="form-group col-md-4 col-sm-12">
                                            <TextField 
                                              id="fname" 
                                              label="First Name" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="text" className="form-control" placeholder="First Name" id="fname" /> */}
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                            <TextField 
                                              id="lname" 
                                              label="Last Name" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="text" className="form-control" placeholder="Last Name" id="lname" /> */}
                                    </div>
                                </div>
                                <div className="form-row">                            
                                    <div className="form-group col-md-4 col-sm-12">
                                            <TextField 
                                              id="email" 
                                              label="Email Address" 
                                              placeholder="admin@gmail.com"
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="email" className="form-control" placeholder="admin@gmail.com" id="email" /> */}
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                            <TextField 
                                              id="phone" 
                                              label="Phone Number" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="tel" className="form-control" placeholder="Phone Number" id="phone" /> */}
                                    </div>
                                    <div className="form-group col-md-4 col-sm-12">
                                            <TextField 
                                              id="license" 
                                              label="License number" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="text" className="form-control" placeholder="License number" id="licence" /> */}
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 col-sm-12">
                                            <TextField 
                                              id="education" 
                                              label="Education" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="text" className="form-control" placeholder="Education" id="education" /> */}
                                    </div>
                                    <div className="form-group col-md-2 col-sm-12">
                                            <TextField 
                                              id="year" 
                                              label="Year" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="year" className="form-control" placeholder="Year" id="year" /> */}
                                    </div>

                                    <div className="form-group col-md-1 col-sm-12 text-center">                                
                                        <button type="button" className="btnadd"> <img src={plusIcon} alt="plue-wht-icon" /> </button>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="form-group col-md-4 col-sm-12">
                                            <TextField 
                                              id="medical" 
                                              label="Medical Center" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="text" className="form-control" placeholder="Medical Center" id="medical" /> */}
                                    </div>
                                    <div className="form-group col-md-2 col-sm-12">
                                            <TextField 
                                              id="fromyear" 
                                              label="From Year" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="year" className="form-control" placeholder="From Year" id="fromyear" /> */}
                                    </div>
                                    <div className="form-group col-md-2 col-sm-12">
                                            <TextField 
                                              id="toyear" 
                                              label="To Year" 
                                              variant="outlined" 
                                              className="text-field"
                                            />
                                        {/* <input type="year" className="form-control" placeholder="To Year" id="toyear" /> */}
                                    </div>
                                    <div className="form-group col-md-1 col-sm-12 text-center">                                
                                        <button type="button" className="btnadd"> <img src={plusIcon} alt="plue-wht-icon" /> </button>
                                    </div>
                                </div>
                                <div className="form-row">
                                    <div className="actionbtn">
                                        <button className="btnsubmit" type="submit">Update</button>
                                        <button className="btncancle">Cancle</button>                                        
                                    </div>
                                </div>                                
                            </form>
                        </div> 
                    </div> 
                </div> 
            </div> 
        </section> 
    </main>
            </div>
        )
    }
}


export default MyProfile;